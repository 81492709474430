import { Button, Grid } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import uiText from "../../context/translation.json";
import styles from "./GlAccount2.module.css";
import { editableColumns, nonEditableColumns } from "./aadeFieldGlAccount";
import glAccountTranslations from "./glAccountTranslations.json";

const tryTranslation = (value, lang) => {
  return value in glAccountTranslations ? glAccountTranslations[value][lang] : value;
};

const editableColumnHash = editableColumns.reduce((acc, obj) => ({ ...acc, [obj.valueFromProperty]: obj }), {});
const nonEditableColumnHash = nonEditableColumns.reduce((acc, obj) => ({ ...acc, [obj.valueFromProperty]: obj }), {});

export function GlAccountForm({ initialFields, onCloseFormWithoutSaving, onSave, uiLang }) {
  const [formFields, setFormFields] = useState(
    Object.keys(initialFields).reduce(
      (acc, key) => ({
        ...acc,
        ...((key in editableColumnHash || key in nonEditableColumnHash) && { [key]: initialFields[key] ?? "" }),
      }),
      {}
    )
  );

  const handleFieldChange = (field, newValue) => {
    setFormFields((prev) => ({ ...prev, [field]: newValue }));
  };

  return (
    <>
      <DialogTitle key={`DialogTitle-${formFields.id}`} id="alert-dialog-title">
        {uiText.gl_account_edit[uiLang]} {formFields.erp_id}, (id: {formFields.id})
      </DialogTitle>
      <DialogContent key={`DialogContent-${formFields.id}`}>
        <DialogContentText key={`DialogContentText-${formFields.id}`} id="alert-dialog-description"></DialogContentText>
        <div
          key={`thisdiv-${formFields.id}`}
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid key={`container-grid-${formFields.id}`} container spacing={2} alignItems="center">
            <Grid key={`item-grid-${formFields.id}`} item>
              {Object.keys(formFields)
                .filter((fieldName) => fieldName !== "popupForm")
                .map((fieldName) => (
                  <>
                    {fieldName in editableColumnHash && Array.isArray(editableColumnHash[fieldName].menu) && (
                      <FormControl
                        key={`FormControl-${formFields.id}-${fieldName}`}
                        variant="filled"
                        sx={{ width: 200 }}
                        disabled={!(fieldName in editableColumnHash)}
                      >
                        <InputLabel key={`InputLabel-${formFields.id}-${fieldName}`} id="invoice-type-label">
                          {tryTranslation(fieldName, uiLang)}
                        </InputLabel>
                        <Select
                          key={`Select-${formFields.id}-${fieldName}`}
                          labelId={`Select-${formFields.id}-${fieldName}`}
                          id={`Select-${formFields.id}-${fieldName}`}
                          value={formFields[fieldName]}
                          onChange={(event) => {
                            handleFieldChange(fieldName, event.target.value);
                          }}
                          className={styles.formstyle}
                        >
                          {editableColumnHash[fieldName].menu.map((it) => (
                            <MenuItem key={`MenuItem1-${formFields.id}-${fieldName}-${it.value}`} value={it.value}>
                              {it.display}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {fieldName in editableColumnHash && editableColumnHash[fieldName].menu === undefined && (
                      <TextField
                        size="medium"
                        style={{ padding: "5px 5px", width: 200 }}
                        key={`TextField-${formFields.id}-${fieldName}`}
                        label={tryTranslation(fieldName, uiLang)}
                        variant="filled"
                        value={formFields[fieldName]}
                        onChange={(event) => {
                          handleFieldChange(fieldName, event.target.value);
                        }}
                      />
                    )}
                    {!(fieldName in editableColumnHash) && (
                      <TextField
                        disabled
                        size="medium"
                        style={{ padding: "5px 5px", width: 200 }}
                        key={`TextField-${formFields.id}-${fieldName}`}
                        label={tryTranslation(fieldName, uiLang)}
                        variant="filled"
                        value={formFields[fieldName]}
                      />
                    )}
                  </>
                ))}
            </Grid>
          </Grid>
          <br />
        </div>
      </DialogContent>
      <DialogActions>
        <Button key={`CancelButton-${formFields.id}`} onClick={onCloseFormWithoutSaving}>
          {uiText.cancel[uiLang]}
        </Button>
        <Button key={`SaveButton-${formFields.id}`} onClick={() => onSave(formFields)}>
          {uiText.save[uiLang]}
        </Button>
      </DialogActions>
    </>
  );
}
