import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableHead, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';



// for styling https://stackoverflow.com/questions/75481613/how-to-make-mui-table-with-sticky-header-and-sticky-column
export default function TestTable({
  columns,
  rows,
  order,
  orderBy,
  onRequestSort,
  page,
  rowsPerPage,
}) {

  console.log(rows.length > 0 && columns.length > 0 ? rows[0][columns[0].id] : null )
  // omg closure, high-order function and factory in the wild
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  console.log(rows)
  return (
    <div >
      <TableContainer sx={{ maxHeight: 880 }} component={Paper}>
        <Table stickyHeader sx={{ minWidth: 200 }} aria-label="sticky header">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, rowIndex) => (
              <TableRow key={row?.month ?? row?.id ?? '-1'}>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      width: '350px',
                      maxWidth: '350px',
                      backgroundColor: 'whitesmoke'
                    }}
                    align="right"
                    component="th"
                    scope="row"
                  >
                    {typeof(row[column.id]) === 'string' ? row[column.id] : 'object'}

                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
}
