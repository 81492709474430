import { IconButton, Typography } from "@mui/material"
import { FaHome } from "react-icons/fa"
import { MdKeyboardArrowRight } from "react-icons/md"
import uiText from '../../context/translation.json'
import { Link } from "react-router-dom";
import styles from './Breadcrump.module.css'

const Breadcrump = ({ category, status, uiLang }) => {
  return (
    <>
      <div className={`${styles['reports-breadcrumb-items']}`}>
        <IconButton component={Link} to="/">
          <FaHome style={{ color: "#243e71" }} />{" "}
        </IconButton>
      </div>
      <div className={`${styles['reports-breadcrumb-items']}`}>
        <MdKeyboardArrowRight size={24} />
      </div>
      <div className={`${styles['reports-breadcrumb-items']}`}>
        <Typography
          style={{
            color: "#243e71",
            wordWrap: "normal",
            textTransform: "none",
            textDecoration: "none",
          }}
          component={Link}
          to="."
        >
          {uiText[category][uiLang]}
        </Typography>
      </div>
      <div className={`${styles['reports-breadcrumb-items']}`}>
        <MdKeyboardArrowRight size={24} />
      </div>
      <div className={`${styles['reports-breadcrumb-items']}`}>
        <Typography>
          {uiText[status][uiLang]}
        </Typography>
      </div>
    </>
  )
}

export default Breadcrump