import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Menu,
} from "@mui/material";
import Button from "@mui/material/Button";
import styles from "./Login.module.css";
import { getAzureUrl } from "../../auth/authAPI";
import {
  setAccessToken,
  setUserData,
  setUserLang,
  setCredentials,
  setAzureAuth,
} from "../../auth/utils";
import { SiMicrosoftazure } from "react-icons/si";
import uiText from "../../context/translation.json";
import logo from "../../images/mylogo.png";
// import { useHistory } from "react-router-dom";
import axios from "axios";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

const Login = ({ setToken, uiLang, setUiLang, ...props }) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("login_failed");
  const [anchorLanguage, setAnchorLanguage] = useState(null);
  const [dateOffset, setDateOffset] = useState(0);
  // const history = useHistory();
  const handleLanguage = (lang) => {
    setAnchorLanguage(null);
    setUserLang(lang);
    setUiLang(lang);
  };
  useEffect(() => {
    setUserLang("el");
    setUiLang("el");
  }, []);
  const openLanguage = (event) => {
    setAnchorLanguage(event.currentTarget);
  };
  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };

  const handleAzureAuth = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowError(false);
    // get url
    try {
      const azureUrl = await getAzureUrl();

      // TODO: set azure auth flag, because Login is set outside the router
      setAzureAuth();

      // redirect user to the azure url
      window.location.href = azureUrl.data;
    } catch (error) {
      setLoginErrorMessage("login_unavailable");
      setShowError(true);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setShowError(false);

    const credentials = new URLSearchParams();
    credentials.append("username", username);
    credentials.append("password", password);
    credentials.append("grant_type", "password");
    const uninterceptedAxiosInstance = axios.create();
    const url = window.BACKEND_BASE_URL + "/authorization/access_token";
    uninterceptedAxiosInstance
      .post(url, credentials, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((response) => {
        setCredentials({
          username: username,
          password: password,
          grant_type: "password",
        });
        const user = {
          ...response.data.user,
          lang: uiLang,
        };
        user[`organization_groups`] = response.data.organization_groups;
        setAccessToken(response.data.access_token);

        setUserData(user);
        setToken(response.data.access_token);
        setLoading(false);
        // history.push("/income/all");
        window.location.href = "/dashboard";
      })
      .catch((error) => {
        setLoginErrorMessage("login_failed");
        setShowError(true);
        setLoading(false);
      });
  };
  const keypressed = (e) => {
    if (e.code === "Enter") handleSubmit(e);
  };
  return (
    <div id={styles.loginContainer}>
      <Grid
        style={{ width: "100%", height: "100%" }}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={8} style={{ position: "relative" }}>
          <Card style={{ position: "relative" }}>
            <Box
              width={"100%"}
              height={170}
              style={{ backgroundColor: "#14223f" }}
            >
              <img
                alt={'MyDataEase Logo'}
                src={logo}
                style={{ margin: 45, marginTop: 30, width: 390, height: "auto", objectFit: "cover" }}
              />
            </Box>
            {/* <FormControl variant="standard"> */}
            <Typography
              onClick={openLanguage}
              style={{
                cursor: "pointer",
                color: "white",
                position: "absolute",
                right: 20,
                top: 15,
              }}
            >
              {uiLang}
              {Boolean(anchorLanguage) ? (
                <ArrowDropDown
                  style={{ marginBottom: -5, transform: "rotate(180deg)" }}
                />
              ) : (
                <ArrowDropDown style={{ marginBottom: -5 }} />
              )}
            </Typography>
            <Menu
              id="menu-appbar"
              anchorEl={anchorLanguage}
              anchororigin={{
                vertical: "top",
                horizontal: "right",
              }}
              MenuListProps={{ onMouseLeave: handleCloseLanguage }}
              disableScrollLock
              // keepMounted
              // transformOrigin={{
              //   vertical: "bottom",
              //   horizontal: "right",
              // }}
              open={Boolean(anchorLanguage)}
              onClose={handleCloseLanguage}
            >
              <MenuItem onClick={() => handleLanguage("en")} value={"en"}>
                en
              </MenuItem>
              <MenuItem onClick={() => handleLanguage("el")} value={"el"}>
                el
              </MenuItem>
            </Menu>
            {/* </FormControl> */}
            {/* <Select
              size="small"
              style={{ position: "absolute", right: 20, top: 15 }}
              labelId="languageSelect-label"
              id="languageSelect"
              value={uiLang}
              onChange={(e) => setUiLang(e.target.value)}
              autoWidth
              sx={{ color: "#14223f", backgroundColor: "#e4edf6" }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    // bgcolor: "",
                    "& .MuiMenuItem-root": {
                      color: "#243e71",
                    },
                  },
                },
              }}
            >
              <MenuItem value={"en"}>en</MenuItem>
              <MenuItem value={"el"}>el</MenuItem>
            </Select> */}

            <form className={styles.loginCard}>
              <div className={styles.formGroup}>
                <TextField
                  id="outlined-basic"
                  style={{ marginTop: 20, width: "100%", background: "#fff" }}
                  label={uiText.login_username[uiLang]}
                  placeholder="Username"
                  autoComplete="username or e-mail"
                  disabled={loading}
                  variant="outlined"
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setShowError(false);
                  }}
                />
              </div>

              <div
                className={styles.formGroup}
                style={{ marginBottom: "35px" }}
              >
                <TextField
                  id="outlined-basic-pwd"
                  style={{ width: "100%", background: "#fff" }}
                  label={uiText.login_password[uiLang]}
                  placeholder="Password"
                  autoComplete="current-password"
                  disabled={loading}
                  type="password"
                  variant="outlined"
                  onKeyPress={(e) => keypressed(e)}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setShowError(false);
                  }}
                />
                {showError && (
                  <Typography
                    style={{ marginTop: 20, color: "red", textAlign: "center" }}
                  >
                    {uiText[loginErrorMessage][uiLang]}
                  </Typography>
                )}
              </div>

              {/* <FormGroup className={styles.formGroup}>
                <FormControlLabel
                  disabled={loading}
                  control={<Checkbox defaultChecked />}
                  label={uiText.login_inactivity[uiLang]}
                />
              </FormGroup> */}

              <div className={styles.actionRow}>
                <Button
                  className={styles.submitBtn}
                  // style={{ background: "rgb(53 89 158)", color: "#fff" }}
                  loading={loading.toString()}
                  // loadingPosition="start"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  <LoginOutlinedIcon style={{ marginRight: "10px" }} />
                  {uiText.login_login[uiLang]}
                </Button>
                <div>
                  <Button
                    className={styles.submitBtn}
                    style={{ marginTop: "10px" }}
                    loading={loading.toString()}
                    // loadingPosition="start"
                    variant="contained"
                    onClick={handleAzureAuth}
                  >
                    <SiMicrosoftazure style={{ marginRight: "10px" }} />
                    {uiText.login_azure[uiLang]}
                  </Button>
                </div>
              </div>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
