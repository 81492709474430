
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Link
} from "react-router-dom";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";


import { FaMoneyBillAlt } from "react-icons/fa";
import { GiExpense } from "react-icons/gi";
import { ImDrawer2 } from "react-icons/im";

import { FiSettings } from "react-icons/fi";


import Tooltip from '@mui/material/Tooltip';
import { useState } from "react";

const SideList = ({
  classesSx, uiText, uiLang,
  menuIcon, showMenuLabels,
  incomeRoute, expensesRoute, othersRoute, settingsRoutes, globalSomethingLoading }) => {
  const [showIncome, setShowIncome] = useState(false);
  const [showExpenses, setShowExpenses] = useState(false);
  const [showRest, setShowRest] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  return (
    <>
      <Box
        style={{
          height: "200%",
          // minHeight: 1000,
          position: "fixed",
          overflow: "auto",
          minWidth: showMenuLabels ? 200 : 65,
          maxWidth: showMenuLabels ? "none" : 65,
          backgroundColor: "#243e71",
          top: "60px",
          left: 0,
          zIndex: 10,
          paddingLeft: 5,
          paddingRight: 10,
        }}
      >


        <List dense disablePadding disabled={globalSomethingLoading}
        // className={classesSx.listaSx}
        >
          <ListItem
            onClick={() => {
              if (showIncome) {
                setShowIncome(false);
              } else {
                setShowRest(false);
                setShowIncome(true);
                setShowExpenses(false);
                setShowSettings(false);
              }
            }}
            disabled={globalSomethingLoading}
            style={{ paddingLeft: 0 }}
          // className={classesSx.SideMenuSubheadersSx}
          >
            <ListItemIcon>
              <FaMoneyBillAlt
                style={{
                  color: "white",
                  fontSize: 32,
                  marginLeft: 10,
                  marginTop: 10,
                }}
              />
            </ListItemIcon>
            {showMenuLabels && (
              <Typography
                style={{ color: "white", fontSize: 24, marginTop: 10 }}
              >
                {uiText.income[uiLang]}
              </Typography>
            )}
          </ListItem>
          {showIncome &&
            incomeRoute.map((route) => {
              return (
                <Tooltip title={route.label} placement="right" key={route.label}>
                  <div>
                    <ListItem
                      component={Link}
                      to={route.link}
                      button
                      disabled={globalSomethingLoading}
                      key={route.link}
                      style={{
                        color: "white",
                        fontSize: 32,
                        maxWidth: 180,
                        marginTop: showMenuLabels ? 0 : 5,
                        marginBottom: showMenuLabels ? 0 : 5,
                        paddingLeft: 0
                      }}
                    // className={classesSx.SideMenuLinksSx}
                    >
                      <ListItemIcon>{menuIcon(route)}</ListItemIcon>

                      {showMenuLabels && (
                        <ListItemText
                          style={{ color: "white", whiteSpace: "normal" }}
                        >
                          {route.label}
                        </ListItemText>
                      )}
                    </ListItem>
                  </div>
                </Tooltip>
              );
            })}
        </List>
        <Divider
          style={{
            backgroundColor: "white",
            height: 2,
            marginBottom: 8,
            marginTop: 8,
            paddingLeft: 0
          }}
        />
        <List dense disablePadding disabled={globalSomethingLoading}
        // className={classesSx.listaSx}
        >
          <ListItem
            onClick={() => {
              if (showExpenses) {
                setShowExpenses(false);
              } else {
                setShowRest(false);
                setShowIncome(false);
                setShowExpenses(true);
                setShowSettings(false);
              }
            }}
            button
            disabled={globalSomethingLoading}
            style={{ paddingLeft: 0 }}
          // className={classes.SideMenuSubheadersSx}
          >
            <ListItemIcon>
              <GiExpense
                style={{ color: "white", fontSize: 32, marginLeft: 10, paddingLeft: 0 }}
              />
            </ListItemIcon>
            {showMenuLabels && (
              <Typography style={{ color: "white", fontSize: 24 }}>
                {uiText.expenses[uiLang]}
              </Typography>
            )}
          </ListItem>
          {showExpenses &&
            expensesRoute.map((route, index) => (
              <Tooltip title={route.label} placement="right" key={route.label}>
                <div>
                  <ListItem
                    component={Link}
                    to={route.link}
                    button
                    disabled={globalSomethingLoading}
                    key={route.link}
                    style={{
                      color: "white",
                      fontSize: 32,
                      maxWidth: 180,
                      marginTop: showMenuLabels ? 0 : 5,
                      marginBottom: showMenuLabels ? 0 : 5,
                      paddingLeft: 0
                    }}
                  // className={classesSx.SideMenuLinksSx}
                  >
                    <ListItemIcon>{menuIcon(route)}</ListItemIcon>
                    {showMenuLabels && (
                      <ListItemText
                        style={{
                          color: "white",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        {route.label}
                      </ListItemText>
                    )}
                  </ListItem>
                </div>
              </Tooltip>
            ))}
        </List>
        <Divider
          style={{
            backgroundColor: "white",
            height: 2,
            marginBottom: 8,
            marginTop: 8,
          }}
        />
        <List dense disablePadding disabled={globalSomethingLoading}
        // className={classesSx.listaSx}
        >
          <ListItem
            onClick={() => {
              if (showRest) {
                setShowRest(false);
              } else {
                setShowRest(true);
                setShowIncome(false);
                setShowExpenses(false);
                setShowSettings(false);
              }
            }}
            button
            disabled={globalSomethingLoading}
            style={{ marginTop: -10, paddingLeft: 0 }}
          // className={classesSx.SideMenuSubheadersSx}
          >
            <ListItemIcon>
              <ImDrawer2
                style={{
                  color: "white",
                  fontSize: 32,
                  marginLeft: 10,
                  marginTop: showMenuLabels ? 0 : 5,
                  marginBottom: showMenuLabels ? 0 : 5,
                  // marginTop: -20,
                }}
              />
            </ListItemIcon>
            {showMenuLabels && (
              <Typography
                style={{ paddingTop: 10, color: "white", fontSize: 24 }}
              >
                {uiText.other[uiLang]}
              </Typography>
            )}
          </ListItem>
          {showRest &&
            othersRoute.map((route, index) => (
              <Tooltip title={route.label} placement="right" key={route.label}>
                <div>
                  <ListItem
                    component={Link}
                    to={route.link}
                    button
                    key={route.link}
                    style={{
                      color: "white",
                      fontSize: 32,
                      marginTop: showMenuLabels ? 0 : 5,
                      marginBottom: showMenuLabels ? 0 : 5,
                      paddingLeft: 0
                    }}
                    disabled={globalSomethingLoading}
                  // className={classesSx.SideMenuLinksSx}
                  >
                    <ListItemIcon>{menuIcon(route)}</ListItemIcon>
                    {showMenuLabels && (
                      <ListItemText style={{ color: "white" }}>
                        {route.label}
                      </ListItemText>
                    )}
                  </ListItem>
                </div>
              </Tooltip>
            ))
          }
        </List>
        <Divider
          style={{
            backgroundColor: "white",
            height: 2,
            marginBottom: 15,
            marginTop: 8,
          }}
        />
        <List dense disablePadding disabled={globalSomethingLoading}
        // style={classesSx.listaSx}
        >
          <ListItem
            onClick={() => {
              if (showSettings) {
                setShowSettings(false);
              } else {
                setShowSettings(true);
                setShowRest(false);
                setShowIncome(false);
                setShowExpenses(false);
              }
            }}
            button
            disabled={globalSomethingLoading}
            style={{ marginTop: -10, paddingLeft: 0 }}
          // className={{ ...classesSx.SideMenuSubheadersSx }}
          >
            <ListItemIcon>
              <FiSettings
                style={{
                  color: "white",
                  fontSize: 32,
                  marginLeft: 10,
                  marginTop: showMenuLabels ? 0 : 5,
                  marginBottom: showMenuLabels ? 0 : 5,
                  // marginTop: -20,
                  paddingLeft: 0
                }}
              />
            </ListItemIcon>
            {showMenuLabels && (
              <Typography
                style={{ paddingTop: 10, color: "white", fontSize: 24 }}
              >
                {uiText.settings[uiLang]}
              </Typography>
            )}
          </ListItem>
          {showSettings &&
            settingsRoutes.map((route, index) => (
              <Tooltip title={route.label} placement="right" key={route.label}>
                <div>
                  <ListItem
                    component={Link}
                    to={route.link}
                    button
                    key={route.link}
                    style={{
                      color: "white",
                      fontSize: 32,
                      marginTop: showMenuLabels ? 0 : 5,
                      marginBottom: showMenuLabels ? 0 : 5,
                      paddingLeft: 0
                    }}
                    disabled={globalSomethingLoading}
                  // className={classesSx.SideMenuLinksSx}
                  >
                    <ListItemIcon>{menuIcon(route)}</ListItemIcon>
                    {showMenuLabels && (
                      <ListItemText style={{ color: "white" }}>
                        {route.label}
                      </ListItemText>
                    )}
                  </ListItem>
                </div>
              </Tooltip>
            ))}
        </List>
      </Box >
    </>
  );
};

export default SideList