import { Checkbox, FormControl, ListItemText, MenuItem, Select, Typography } from "@mui/material"



const MultiSelectMDE = ({
  value,
  disabled,
  onChange,
  label,
  menuItems,
}) => {
  return (
    <div style={{ margin: "0 0.5rem" }}>
      <Typography style={{ color: "#243e71" }}>
        {label}
      </Typography>
      <FormControl variant="standard" sx={{ width: 250 }}>
        <Select
          labelId={label}
          id={label}
          multiple={true}
          value={value}
          disabled={disabled}
          onChange={onChange}
          label={label}
          autoWidth
          renderValue={(selected) => menuItems.filter(item => selected.includes(item.key)).map(item => item.label).join(', ')}
        >
          {menuItems.map(item => (
            <MenuItem disabled={disabled} key={item.key} value={item.value}>
              <Checkbox disabled={disabled} indeterminate={false} checked={value.indexOf(item.value) > -1} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelectMDE