import TableMDE from "../../components/TableMDE/TableMDE"
import uiText from "../../context/translation.json";
import uiTextClassificationType from "../../context/aade_classification_type.json";
import uiTextClassificationCategory from "../../context/aade_classification_category.json";
import { Typography, IconButton, Button, Grid, Tooltip, Popover } from "@mui/material";
import styles from './Report.module.css';
import { useParams } from "react-router-dom";
import { MdFilterAlt } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { useCallback, useEffect, useMemo, useState } from "react";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { Restore } from "@mui/icons-material";
import { getReportData } from "./ReportAPI";
import FilterPopover from "../../components/FilterPopover/FilterPopover";
import { calculateTotals, filterRow, } from "./ReportUtils";
import { getComparator, } from "../../components/TableMDE/TableMDEUtils";
import Breadcrump from "../../components/Breadcrump/Breadcrump";
import SelectMDE from "../../components/SelectMDE/SelectMDE";
import MultiSelectMDE from "../../components/MultiSelectMDE/MultiSelectMDE";
import { exportAsXLSX } from './ReportUtils';


const erpColumns = [
  'count_erp',
  'net_erp',
  'vat_erp',
  'withheld_erp',
  'fees_erp',
  'stamp_duty_erp',
  'other_taxes_erp',
  'deductions_erp',
]

const aadeColumns = [
  'count_aade',
  'net_aade',
  'vat_aade',
  'withheld_aade',
  'fees_aade',
  'stamp_duty_aade',
  'other_taxes_aade',
  'deductions_aade',
]


const differenceColumns = [
  'count_difference',
  'net_difference',
  'vat_difference',
  'withheld_difference',
  'fees_difference',
  'stamp_duty_difference',
  'other_taxes_difference',
  'deductions_difference',
]




const floatColumns = [
  'net_erp',
  'net_aade',
  'net_difference',
  'vat_erp',
  'vat_aade',
  'vat_difference',
  'withheld_erp',
  'withheld_aade',
  'withheld_difference',
  'fees_erp',
  'fees_aade',
  'fees_difference',
  'stamp_duty_erp',
  'stamp_duty_aade',
  'stamp_duty_difference',
  'other_taxes_erp',
  'other_taxes_aade',
  'other_taxes_difference',
  'deductions_erp',
  'deductions_aade',
  'deductions_difference',
];

const integerColumns = [
  'count_erp',
  'count_aade',
  'count_difference',
];

const columnForceStyle = [
  'count_difference',
  'net_difference',
  'vat_difference',
  'withheld_difference',
  'fees_difference',
  'stamp_duty_difference',
  'other_taxes_difference',
  'deductions_difference',
];

const styleToForce = { backgroundColor: 'whitesmoke' };

/* 
key: column.id, value: hash to get stuff from. 
The keys of the hash must be table cell values.
The values of the hash should be the tooltip to display depending on language.
*/
const customTooltipColumns = {
  aade_type: uiTextClassificationType,
  aade_category: uiTextClassificationCategory,
  aade_vat_type: uiTextClassificationType,
}

const Report = ({
  company,
  companies,
  onCompanyChange,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
  defaultStartingDate,
  defaultEndingDate,
  uiLang,
}) => {
  const [groupBy, setGroupBy] = useState('month');
  const [whereSent, setWhereSent] = useState('sentOrNotSent');
  const [whereFlags, setWhereFlags] = useState(['NONE', 'SELF_PRICED', 'NON_LIABLE']);
  const [reportData, setReportData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({ id: '', }); // dummy initialization
  const { category } = useParams();

  const formatDate = useCallback((dateObj) => {
    const tzoffset = dateObj instanceof Date ? dateObj.getTimezoneOffset() * 60000 : (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(dateObj - tzoffset));
    return localISOTime?.toISOString().substring(0, 10);
  }, [])

  const fetchReportData = useCallback(async (category, company, startingDate, endingDate, groupBy, whereSent, whereFlags) => {
    setLoadingDocs(true);
    setReportData([]);
    setReportColumns([]);
    try {
      const { data } = await getReportData(category?.toUpperCase(), company, startingDate, endingDate, groupBy, whereSent, whereFlags)
      if (data.length === 0) {
        setReportData([]);
        setReportColumns([]);
      } else {
        /*
          - assume all objects have the same properties
          - add extra property for link to partner
          - add extra property for id to month
        */
        const stateData = data.map(item => ({
          ...(groupBy === 'month' && { id: item.month }),
          ...(groupBy === 'partner' && { link: `/${category}/all?vat_number_keys=${item.vat_number_key}` }),
          ...(groupBy === 'classification_type' && { 
            id: item.aade_type, 
            link: `/${category}/details?aade_types=${item.aade_type}` 
          }),
          ...(groupBy === 'classification_type_and_category' && { 
            id: `${item.aade_type}_${item.aade_category}`,
            link: `/${category}/details?aade_types=${item.aade_type}&aade_categories=${item.aade_category}`
          }),
          ...(groupBy === 'classification_vat_type' && { 
            id: item.aade_vat_type, 
            link: `/${category}/details?aade_vat_types=${item.aade_vat_type}`
           }),
          ...item
        }))
        setReportData(stateData);
        /*
          - id is used as key to account for duplicate vat_number_key
          - add extra property for link to partner
        */
        setReportColumns(Object.keys(stateData[0]).filter(key => key !== 'id'));
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDocs(false);
      setOrder('asc');
      setOrderBy('');
      setAnchorEl(null);
      setFilters({
        ...(groupBy === 'month' && { id: '' }),
        ...(groupBy === 'partner' && { id: '', vat_number_key: '', name: '', address: '', number: '', postal_code: '', city: '', country: '', }),
        ...(groupBy === 'classification_type' && { aade_type: '' }),
        ...(groupBy === 'classification_type_and_category' && { aade_type: '', aade_category: '' }),
        ...(groupBy === 'classification_vat_type' && { aade_vat_type: '' }),
      })
    }
  }, [])

  useEffect(() => {
    if (company !== null && startingDate instanceof Date && endingDate instanceof Date && startingDate <= endingDate) {
      fetchReportData(category, company, formatDate(startingDate), formatDate(endingDate), groupBy, whereSent, whereFlags)
    }
  }, [category, company, startingDate, endingDate, groupBy, whereSent, whereFlags, fetchReportData, formatDate]);

  const handleChangeCompany = (event) => {
    onCompanyChange(event.target.value);
  };

  const handleChangeGroupBy = (event) => {
    setGroupBy(event.target.value);
  };

  const handleChangeWhereSent = (event) => {
    setWhereSent(event.target.value);
  };

  const handleChangeWhereFlags = (event) => {
    const value = event.target.value;
    setWhereFlags(typeof value === 'string' ? value.split(',') : value);
  };

  const handleResetDates = () => {
    setStartingDate(defaultStartingDate);
    setEndingDate(defaultEndingDate);
  };

  const handleOpenFilterPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = useCallback((event, newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    const toggledOrder = isAsc ? 'desc' : 'asc';
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
  }, [order, orderBy]);

  // process columns
  const columns = reportColumns.map((columnName) => ({ id: columnName, label: uiText[`report_column_${columnName}`][uiLang], minWidth: 100, maxWidth: 350, align: 'right', }))

  // filter
  const filteredRows = useMemo(() => {
    return groupBy === 'month' || loadingDocs ? reportData : reportData?.filter((row) => (filterRow(row, filters)));
  }, [reportData, filters, groupBy,]);

  // sort
  const sortedRows = useMemo(() => {
    return filteredRows?.slice()?.sort(getComparator(order, orderBy))
  }, [order, orderBy, filteredRows,]);

  // totals for filtered, sorted data
  const total = useMemo(() => {
    return calculateTotals(
      filteredRows,
      [
        ...erpColumns,
        ...(['month', 'partner'].includes(groupBy) ? aadeColumns : []),
        ...(['month', 'partner'].includes(groupBy) ? differenceColumns : [])
      ],
      {
        month: 'month',
        partner: 'vat_number_key',
        classification_type: 'aade_type',
        classification_type_and_category: 'aade_type',
        classification_vat_type: 'aade_vat_type',
      }[groupBy]
    );
  }, [filteredRows, groupBy])

  return (
    <>
      <div className={`${styles['reports-breadcrumb-container']}`}>
        <Breadcrump category={category} status={'REPORT'} uiLang={uiLang} />
        <div className={`${styles['reports-date-reset-button-container']}`}>
          <Button onClick={handleResetDates} disabled={loadingDocs}>
            <Typography
              style={{
                color: "#243e71",
                wordWrap: "normal",
                textTransform: "none",
              }}
            >
              {uiText.ui_reset_dates[uiLang]}
            </Typography>
            <IconButton>
              <Restore />
            </IconButton>
          </Button>
        </div>
        <div className={`${styles['reports-date-csv-download-container']}`}>
          <Button style={{ color: '#243e71' }} onClick={() => exportAsXLSX(columns, sortedRows, `${category} ${groupBy}`, 'MyDataEaseReportExport.xlsx')} disabled={loadingDocs}>
            <SiMicrosoftexcel fontSize="40px" />
          </Button>
        </div>
      </div>
      <div className={`${styles['reports-top-row-container']}`}>
        <SelectMDE
          value={company}
          disabled={loadingDocs}
          onChange={handleChangeCompany}
          label={uiText.ui_company[uiLang]}
          menuItems={companies.map((comp) => ({ key: comp.value, value: comp.value, label: comp.text }))}
        />
        <Grid
          item
          container
          spacing={2}
          display={"flex"}
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          xs={'auto'}
        >
          <DateRangePicker
            startingDate={startingDate}
            endingDate={endingDate}
            setStartingDate={setStartingDate}
            setEndingDate={setEndingDate}
            startLabel={uiText.ui_from[uiLang]}
            endLabel={uiText.ui_to[uiLang]}
            disabled={loadingDocs}
          />
        </Grid>
        <SelectMDE
          value={groupBy}
          disabled={loadingDocs}
          onChange={handleChangeGroupBy}
          label={uiText.report_group_by[uiLang]}
          menuItems={[
            { key: 'month', value: 'month', label: uiText.report_group_by_month[uiLang] },
            { key: 'partner', value: 'partner', label: uiText.report_group_by_VAT_number[uiLang] },
            { key: 'classification_type', value: 'classification_type', label: uiText.report_group_by_classification_type[uiLang] },
            {
              key: 'classification_type_and_category',
              value: 'classification_type_and_category',
              label: uiText.report_group_by_classification_type_and_category[uiLang]
            },
            ...(category === 'expenses' ? [{ key: 'classification_vat_type', value: 'classification_vat_type', label: uiText.report_group_by_classification_vat_type[uiLang] }] : []),
          ]}
        />
        <SelectMDE
          value={whereSent}
          disabled={loadingDocs}
          onChange={handleChangeWhereSent}
          label={uiText.report_where_sent_type[uiLang]}
          menuItems={[
            { key: 'sentOrNotSent', value: 'sentOrNotSent', label: uiText.report_where_sent_or_not_sent[uiLang] },
            { key: 'sent', value: 'sent', label: uiText.report_where_sent[uiLang] },
            { key: 'notSent', value: 'notSent', label: uiText.report_where_not_sent[uiLang] },
          ]}
        />
        <MultiSelectMDE
          value={whereFlags}
          disabled={loadingDocs}
          onChange={handleChangeWhereFlags}
          label={uiText.report_where_flag_type[uiLang]}
          menuItems={[
            { key: 'NONE', value: 'NONE', label: uiText.report_where_None[uiLang] },
            { key: 'SELF_PRICED', value: 'SELF_PRICED', label: uiText.report_where_self_pricing[uiLang] },
            { key: 'NON_LIABLE', value: 'NON_LIABLE', label: uiText.report_where_non_liable[uiLang] },
          ]}
        />

        {groupBy !== 'month' && <>
          <Tooltip title={`${uiText.filters[uiLang]}`}>
            <IconButton aria-describedby={anchorEl ? 'simple-popover' : undefined} variant="text" onClick={handleOpenFilterPopover}>
              <MdFilterAlt />
            </IconButton>
          </Tooltip>
          <Popover
            id={anchorEl ? 'simple-popover' : undefined}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseFilterPopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <FilterPopover
              filtersLabels={Object.fromEntries(Object.keys(filters).map(k => [k, uiText[`report_column_${k}`][uiLang]]))}
              filters={filters}
              setFilters={setFilters}
              disabled={loadingDocs}
              uiLang={uiLang}
            />
          </Popover>
        </>}

      </div>
      <TableMDE
        columns={columns}
        rows={sortedRows}
        total={total}
        floatColumns={floatColumns}
        integerColumns={integerColumns}
        columnForceStyle={columnForceStyle}
        styleToForce={styleToForce}
        customTooltipColumns={customTooltipColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        uiLang={uiLang}
      />
    </>
  )
}

export default Report