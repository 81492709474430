import { post } from "../../api";

export const getDashboardData = (
    endpoint,
    organization_id,
    date_from = '',
    date_to = '',
    document_category = '',
) => {
    const filters = {
        organization_id: organization_id,
        ...(date_from && { date_from: date_from }),
        ...(date_to && { date_to: date_to }),
        ...(document_category && { document_category }),
    };
    const body = {
    };
    const queryParams = Object.keys(filters).reduce((acc, key) => acc + `&${key}=${filters[key]}`, "");
    return post(`reports/${endpoint}?${queryParams}`, body);
}

