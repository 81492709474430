import AccountCircle from "@mui/icons-material/AccountCircle";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import AnnouncementOutlinedIcon from "@mui/icons-material/AnnouncementOutlined";
import AppShortcutOutlinedIcon from "@mui/icons-material/AppShortcutOutlined";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import CachedIcon from "@mui/icons-material/Cached";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LightModeIcon from "@mui/icons-material/LightMode";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import {
  Box,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { disable, enable } from "darkreader";
import { useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { VscLink } from "react-icons/vsc";
import { getUserData } from "../../auth/utils";
import logo from "../../images/mylogo.png";
import { getRecentTasks } from "../../views/documents/documentAPI";
import { SyncDropDownDialog } from "../SyncDropDownDialog";

const enableDarkMode = () => {
  enable({
    brightness: 100,
    contrast: 90,
    sepia: 3,
  });
};

const disableDarkMode = () => {
  disable();
};

const TopMenu = ({
  toggleDrawer,
  handleClose,
  handleLogout,
  uiText,
  anchorEl,
  uiLang,
  onVscLink,
  onWebhookOutlinedIcon,
  handleMenu,
  handleLanguage,
  onAiOutlineCloudDownload,
  anchorLanguage,
  handleCloseLanguage,
  openLanguage,
  onCachedIcon,
  token,
}) => {
  const user = getUserData();
  const [loading, setLoading] = useState(false);
  const userAuthArray = user?.job_actions ?? [];
  const userCanMatch = userAuthArray.includes("MATCH_DOCS");
  const anouncementsUrl =
    uiLang === "en"
      ? "https://mydataease.gr/?page_id=2607&lang=en"
      : "https://mydataease.gr/?page_id=180";
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [tasksAnchorEl, setTasksAnchorEl] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isClicked, setIsClicked] = useState(false);

  const toggleDarkMode = () => {
    setLoading(true);
    if (isDarkMode) {
      disableDarkMode();
    } else {
      enableDarkMode();
    }
    setIsDarkMode(!isDarkMode);
    setLoading(false);
  };

  const handleOpenTasksPopover = async (event) => {
    setTasksAnchorEl(event.currentTarget);
    setLoading(true);
    try {
      const response = await getRecentTasks();
      setTasks(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleCloseTasksPopover = () => {
    setTasksAnchorEl(null);
  };
  const toggleWindow = () => {
    setIsClicked(!isClicked);
  };

  return (
    <AppBar
      className="AppBar"
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar>
        <Grid item container alignItems="center" justifyContent="space-between">
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={(e) => {
              toggleDrawer(e);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ textAlign: "center", flexGrow: 1 }}
          >
            <Tooltip title={uiText.dashdoard_visit[uiLang]} placement="bottom">
              <img
                alt={"MyDataEase Logo"}
                src={logo}
                width={145}
                style={{
                  marginTop: 7,
                  padding: 1,
                  objectFit: "cover",
                  cursor: "pointer",
                }}
                onClick={() => (window.location.href = "/dashboard")}
              />
            </Tooltip>
          </Typography>
          {token && (
            <>
              <Tooltip title={uiText.darkMode[uiLang]} placement="bottom">
                <Switch
                  sx={{
                    width: 62,
                    height: 44,
                    "& .MuiSwitch-track": {
                      bgcolor: "#d9d9d9",
                    },
                  }}
                  disabled={loading}
                  checked={isDarkMode}
                  onChange={toggleDarkMode}
                  name="darkModeSwitch"
                  icon={
                    <LightModeIcon
                      sx={{ fontSize: "1.3rem", pt: 0.7, ml: 0.5 }}
                    />
                  }
                  checkedIcon={
                    <DarkModeIcon sx={{ fontSize: "1.3rem", pt: 0.7 }} />
                  }
                />
              </Tooltip>
              <Tooltip title={uiText.language[uiLang]} placement="bottom">
                <Typography
                  onClick={openLanguage}
                  style={{
                    cursor: "pointer",
                    color: "white",
                  }}
                >
                  {uiLang}
                  {Boolean(anchorLanguage) ? (
                    <ArrowDropDown
                      style={{
                        marginBottom: -5,
                        transform: "rotate(180deg)",
                      }}
                    />
                  ) : (
                    <ArrowDropDown style={{ marginBottom: -5 }} />
                  )}
                </Typography>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorLanguage}
                anchororigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{ onMouseLeave: handleCloseLanguage }}
                disableScrollLock
                open={Boolean(anchorLanguage)}
                onClose={handleCloseLanguage}
              >
                <MenuItem onClick={() => handleLanguage("en")} value={"en"}>
                  en
                </MenuItem>
                <MenuItem onClick={() => handleLanguage("el")} value={"el"}>
                  el
                </MenuItem>
              </Menu>
              <Tooltip
                title={uiText.refreshErpTooltip[uiLang]}
                placement="bottom"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ position: "relative", left: "25px" }}>
                    <CachedIcon
                      style={{
                        fontSize: "42px",
                        padding: "5px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      aria-label="refresh"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={onCachedIcon}
                      color="inherit"
                    />
                  </div>
                  <div style={{ position: "relative", left: "7px" }}>
                    <ArrowDropDown
                      style={{
                        fontSize: "42px", // Reduce the font size if necessary
                        padding: "5px", // Adjust the padding as needed
                        cursor: "pointer",
                        color: "white",
                      }}
                      aria-label="refresh"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={toggleWindow}
                      color="inherit"
                    />
                    {isClicked && (
                      <SyncDropDownDialog
                        onClose={toggleWindow}
                        uiLang={uiLang}
                      />
                    )}
                  </div>
                </div>
              </Tooltip>
              <Tooltip
                title={uiText.refreshAADETooltip[uiLang]}
                placement="bottom"
              >
                <div>
                  <AiOutlineCloudDownload
                    style={{
                      fontSize: "42px",
                      padding: "5px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    aria-label="refresh"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={onAiOutlineCloudDownload}
                    color="inherit"
                  />
                </div>
              </Tooltip>
              {userCanMatch && (
                <Tooltip
                  title={uiText.matchAADETooltip[uiLang]}
                  placement="bottom"
                >
                  <div>
                    <VscLink
                      style={{
                        fontSize: "42px",
                        padding: "5px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      aria-label="refresh"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={onVscLink}
                      color="inherit"
                    />
                  </div>
                </Tooltip>
              )}
              {userCanMatch && (
                <Tooltip
                  title={uiText.updateERPTooltip[uiLang]}
                  placement="bottom"
                >
                  <div>
                    <WebhookOutlinedIcon
                      style={{
                        fontSize: "36px",
                        padding: "5px",
                        cursor: "pointer",
                        color: "white",
                      }}
                      aria-label="refresh"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={onWebhookOutlinedIcon}
                      color="inherit"
                    />
                  </div>
                </Tooltip>
              )}
              <Tooltip
                title={uiText.recentTasksTooltip[uiLang]}
                placement="bottom"
              >
                <>
                  <IconButton color="inherit" onClick={handleOpenTasksPopover}>
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    open={Boolean(tasksAnchorEl)}
                    anchorEl={tasksAnchorEl}
                    onClose={handleCloseTasksPopover}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    sx={{ mt: 5 }}
                  >
                    <Box sx={{ width: "350px" }}>
                      {loading ? (
                        <Box sx={{ mb: 8 }}>
                          <LinearProgress color="inherit" />
                        </Box>
                      ) : tasks.length > 0 ? (
                        <List>
                          {tasks.map((task) => (
                            <ListItem key={task.task_id}>
                              <ListItemIcon>
                                {task.status === "SUCCESS" ? (
                                  <CheckCircleOutlineIcon color="success" />
                                ) : (
                                  <ErrorOutlineIcon color="disabled" />
                                )}
                              </ListItemIcon>
                              <ListItemText
                                primary={uiText[task.task_name][uiLang]}
                                secondary={task.date_done}
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Box sx={{ p: 2, textAlign: "center" }}>
                          <Typography>
                            {uiText.noRecentTasksTooltip[uiLang]}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Popover>
                </>
              </Tooltip>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle style={{ fontSize: "42px" }} />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchororigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{ onMouseLeave: handleClose }}
                disableScrollLock
                // keepMounted
                // transformOrigin={{
                //   vertical: "bottom",
                //   horizontal: "right",
                // }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem className="menuItemLogout" onClick={handleLogout}>
                  <LogoutOutlinedIcon style={{ marginRight: "10px" }} />
                  {uiText.login_leave[uiLang]}
                </MenuItem>
                <Divider />
                <MenuItem
                  component="a"
                  href={anouncementsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AnnouncementOutlinedIcon style={{ marginRight: "10px" }} />
                  {uiText.anouncements[uiLang]}
                </MenuItem>
                <MenuItem disabled={true}>
                  <PermIdentityOutlinedIcon style={{ marginRight: "10px" }} />
                  {user.username}
                </MenuItem>
                <MenuItem disabled={true}>
                  <AlternateEmailOutlinedIcon style={{ marginRight: "10px" }} />
                  {user.email}
                </MenuItem>
                <MenuItem disabled={true}>
                  <AppShortcutOutlinedIcon style={{ marginRight: "10px" }} />
                  {uiText.build_version[uiLang]}: {user.build_version}
                </MenuItem>
              </Menu>
            </>
            // </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
