import { post } from "../../api";

export const getReportData = (
  category,
  company_id,
  startingDate,
  endingDate,
  groupBy,
  whereSent,
  whereFlags,
) => {

  // query params
  const filters = {
    organization_id: company_id,
    document_category: category,
    ...(startingDate && { date_from: startingDate + "T00:00:00" }),
    ...(endingDate && { date_to: endingDate + "T00:00:00" }),
    ...(whereSent !== 'sentOrNotSent' && { is_sent: whereSent === 'sent' }),
    skip: 0,
    limit: 0
  };

  // body
  const body = {
    ...(whereFlags.length > 0 && { document_flags: whereFlags }),
    ...([
      'classification_type',
      'classification_type_and_category',
      'classification_vat_type'
    ].includes(groupBy) && {
      group_by: [
        ...(groupBy === 'classification_type' ? ['BY_AADE_TYPE'] : []),
        ...(groupBy === 'classification_type_and_category' ? ['BY_AADE_TYPE', 'BY_AADE_CATEGORY'] : []),
        ...(groupBy === 'classification_vat_type' ? ['BY_AADE_VAT_TYPE'] : []),
      ]
    })
  };

  const queryParams = Object.keys(filters).reduce((acc, key) => acc + `&${key}=${filters[key]}`, "");

  const endpoint = {
    month: 'monthly_summary',
    partner: 'partner_summary',
    classification_type: 'classification_summary',
    classification_type_and_category: 'classification_summary',
    classification_vat_type: 'classification_summary',
  }[groupBy];

  return post(`reports/${endpoint}?${queryParams}`, body);
}
