import XLSX from 'xlsx';

const fitToColumn = data => {    
  const columnWidths = [];    
  for (const property in data[0]) {      
    columnWidths.push({        
      wch: Math.max(         
        property ? property.toString().length : 0,         
        ...data.map(obj => obj[property] ? obj[property].toString().length : 0)
      )      
    });   
  }   
  return columnWidths; 
};

export const makeXLSX = (columns, rows, sheetName, fileName) => {
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.utils.sheet_add_aoa(worksheet, [columns], { origin: "A1" });
  worksheet['!cols'] = fitToColumn(rows);
  XLSX.writeFile(workbook, fileName, { compression: true });
}