import { post } from "../../api";

export const getTestDocumentData = (
  category,
  company_id,
  startingDate,
  endingDate,
  filters,
  skip,
  limit,
) => {

  // query params
  const moreFilters = {
    ...filters,
    organization_id: company_id,
    document_category: category,
    ...(startingDate && { date_from: startingDate + "T00:00:00" }),
    ...(endingDate && { date_to: endingDate + "T00:00:00" }),
    skip: skip,
    limit: limit
  };

  const queryParams = Object.keys(moreFilters).reduce((acc, key) => acc + `&${key}=${moreFilters[key]}`, "");

  return post(`documents?${queryParams}`, {});
}