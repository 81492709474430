import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getUserData } from "./auth/utils";
import DocumentDetails from "./views/documents/DocumentDetails";
import Documents from "./views/documents/Documents";
import GLAccount2 from "./views/settings/GlAccount2.js";
import Match from "./views/settings/Match";
// import { useParams } from "react-router-dom";
import Dashboard from "./views/dashboard/dashboard";
import DocumentLines from "./views/documentLines/DocumentLines.js";
import Report from "./views/report/Report";
import TestDocuments from "./views/testDocuments/TestDocuments";
// import Login from "./views/login/Login";
import AzureRedirect from "./views/azureRedirect/AzureRedirect";
var organization_id = "";

const TotalView = (props) => {
  const {
    showMenuLabels,
    drawerWidth,
    incomeRoute,
    expensesRoute,
    othersRoute,
    uiLang,
    handleLogout,
    isActive,
    setIsActive,
    appLocale,
    globalSomethingLoading,
    setGlobalSomethingLoading,
  } = props;
  const user = getUserData();
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [selectedStates, setSelectedStates] = useState({
    income: {
      all: [],
      reports: [],
      deficiencies: [],
      to_send: [],
      to_cancel: [],
      sent: [],
      to_correct: [],
      cancelled: [],
      retrieved_by_aade: [],
      issuer_deviation: [],
      issuer_omitment: [],
      counterpart_rejection: [],
      counterpart_deviation: [],
      ignored: [],
    },
    expenses: {
      all: [],
      reports: [],
      deficiencies: [],
      no_mark_yet: [],
      retrieved_by_aade: [],
      no_greek_supplier: [],
      to_send: [],
      to_cancel: [],
      sent: [],
      to_correct: [],
      cancelled: [],
      ignored: [],
    },
    other: {
      all: [],
    },
  });
  const [filters, setFilters] = useState({ organization_id: "" });

  // date objects
  const [startingDate, setStartingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [defaultStartingDate, setDefaultStartingDate] = useState(null);
  const [defaultEndingDate, setDefaultEndingDate] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (user) {
      let newCompanyOffset;
      const organizations = user.organization_groups.map((option) => {
        if (option.is_default_organization) {
          setCompany(option.organization_id);
          organization_id = option.organization_id;
          newCompanyOffset = option.display_offset;
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          //const todayDate = today.toISOString().substring(0, 10);
          let before = new Date();

          before.setDate(before.getDate() - newCompanyOffset);
          before.setHours(0, 0, 0, 0);
          //const beforeDate = before.toISOString().substring(0, 10);
          // console.log(today instanceof Date, before instanceof Date)
          setStartingDate(before);
          setEndingDate(today);
          setDefaultStartingDate(before);
          setDefaultEndingDate(today);
        }
        // console.log(option.configuration_schema)
        return {
          text: option.name,
          value: option.organization_id,
          glaccounts_view_only:
            option.configuration_schema &&
            "gl_account_configuration" in option.configuration_schema
              ? option.configuration_schema["gl_account_configuration"]
              : true,
          offset: option.display_offset,
        };
      });
      setCompanies(organizations);
    }
  }, [user?.username]);

  useEffect(() => {
    setSelectedStates({
      income: {
        all: [],
        reports: [],
        deficiencies: [],
        to_send: [],
        to_cancel: [],
        sent: [],
        to_correct: [],
        cancelled: [],
        retrieved_by_aade: [],
        issuer_deviation: [],
        issuer_omitment: [],
        counterpart_rejection: [],
        counterpart_deviation: [],
        ignored: [],
      },
      expenses: {
        all: [],
        reports: [],
        deficiencies: [],
        no_mark_yet: [],
        retrieved_by_aade: [],
        no_greek_supplier: [],
        to_send: [],
        to_cancel: [],
        sent: [],
        to_correct: [],
        cancelled: [],
        ignored: [],
      },
      other: {
        all: [],
      },
    });
    setFilters({ organization_id: "" });
  }, [company]);

  const onChangeCompany = (comapnyId) => {
    // console.log(comapnyId);
    let newCompanyOffset;
    companies.forEach((comp) => {
      if (comp.value === comapnyId) newCompanyOffset = comp.offset;
    });
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    //const todayDate = today.toISOString().substring(0, 10);
    let before = new Date();
    before.setDate(before.getDate() - newCompanyOffset);
    before.setHours(0, 0, 0, 0);
    //const beforeDate = before.toISOString().substring(0, 10);
    setStartingDate(before);
    setEndingDate(today);
    setDefaultStartingDate(before);
    setDefaultEndingDate(today);
    setCompany(comapnyId);
    organization_id = comapnyId;
  };
  //To revisit
  // useEffect(() => {
  //     setFilters({ organization_id: "" });
  // }, [category, status])

  return (
    <div>
      <Routes>
        <Route
          path="/dashboard"
          element={
            <Dashboard
              appLocale={appLocale}
              company={company}
              companies={companies}
              uiLang={uiLang}
              onCompanyChange={onChangeCompany}
            />
          }
        />
        <Route
          path="/"
          element={
            <Dashboard
              appLocale={appLocale}
              company={company}
              companies={companies}
              uiLang={uiLang}
              onCompanyChange={onChangeCompany}
            />
          }
        />
        <Route path="/azure" element={<AzureRedirect />} />
        <Route
          path="/settings/gl_accounts"
          element={
            <GLAccount2
              company={company}
              companies={companies}
              uiLang={uiLang}
              showMenuLabels={showMenuLabels}
              drawerWidth={drawerWidth}
              onCompanyChange={onChangeCompany}
            />
          }
        />
        <Route
          path="/expenses/no_mark_yet"
          element={
            <Match
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              company={company}
              companies={companies}
              uiLang={uiLang}
              drawerWidth={drawerWidth}
              onCompanyChange={onChangeCompany}
            />
          }
        />
        <Route
          path="/income/no_mark_yet_is_self_pricing"
          element={
            <Match
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              company={company}
              companies={companies}
              uiLang={uiLang}
              drawerWidth={drawerWidth}
              onCompanyChange={onChangeCompany}
            />
          }
        />
        <Route
          path="/:category/report"
          element={
            <Report
              company={company}
              companies={companies}
              onCompanyChange={onChangeCompany}
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              uiLang={uiLang}
            />
          }
        />
        <Route
          path="/:category/details"
          element={
            <DocumentLines
              company={company}
              companies={companies}
              onCompanyChange={onChangeCompany}
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              uiLang={uiLang}
            />
          }
        />
        <Route
          path="/:category/:status/:id"
          element={
            <DocumentDetails
              uiLang={uiLang}
              appLocale={appLocale}
              company={company}
            />
          }
        />
        <Route
          path="/:category/:status"
          element={
            <Documents
              appLocale={appLocale}
              isActive={isActive}
              setIsActive={setIsActive}
              handleLogout={handleLogout}
              uiLang={uiLang}
              othersRoute={othersRoute}
              expensesRoute={expensesRoute}
              incomeRoute={incomeRoute}
              showMenuLabels={showMenuLabels}
              drawerWidth={drawerWidth}
              selectedDocumentsParam={selectedStates}
              onSelectedDocumentUpdate={setSelectedStates}
              company={company}
              companies={companies}
              onCompanyChange={onChangeCompany}
              filters={filters}
              onFilterChange={setFilters}
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              category={category}
              setCategory={setCategory}
              status={status}
              setStatus={setStatus}
              globalSomethingLoading={globalSomethingLoading}
              setGlobalSomethingLoading={setGlobalSomethingLoading}
            />
          }
        />
        <Route
          path="/test_documents"
          element={
            <TestDocuments
              company={company}
              companies={companies}
              onCompanyChange={onChangeCompany}
              startingDate={startingDate}
              endingDate={endingDate}
              setStartingDate={setStartingDate}
              setEndingDate={setEndingDate}
              defaultStartingDate={defaultStartingDate}
              defaultEndingDate={defaultEndingDate}
              uiLang={uiLang}
            />
          }
        />
      </Routes>
    </div>
  );
};
export { organization_id };
export default TotalView;
