export const documentCategoryType = [
  //TODO: Add type description
  { value: "INCOME", display: "INCOME" },
  { value: "EXPENSES", display: "EXPENSES" },
  { value: "OTHER", display: "OTHER" },
];

export const detailCategoryType = [
  //TODO: Add type description
  { value: "AMOUNT", display: "AMOUNT" },
  { value: "WITHHELD", display: "WITHHELD" },
  { value: "FEE", display: "FEE" },
  { value: "STAMP_DUTY", display: "STAMP_DUTY" },
  { value: "OTHER_TAX", display: "OTHER_TAX" },
  { value: "DEDUCTION", display: "DEDUCTION" },
];

export const recordCategoryType = [
  //TODO: Add type description
  { value: "AMOUNT", display: "AMOUNT" },
  { value: "VAT", display: "VAT" },
  { value: "VAT_NON_DEDUCTIBLE", display: "VAT_NON_DEDUCTIBLE" },
  { value: "WITHHELD", display: "WITHHELD" },
  { value: "FEE", display: "FEE" },
  { value: "STAMP_DUTY", display: "STAMP_DUTY" },
  { value: "OTHER_TAX", display: "OTHER_TAX" },
  { value: "DEDUCTION", display: "DEDUCTION" },
  { value: "GIFT_CERTIFICATE", display: "GIFT_CERTIFICATE" },
  { value: "COUNTERPART", display: "COUNTERPART" },
];

export const invoiceTypes = [
  //TODO: Add type description
  { value: "1.1", display: "1.1" },
  { value: "1.2", display: "1.2" },
  { value: "1.3", display: "1.3" },
  { value: "1.4", display: "1.4" },
  { value: "1.5", display: "1.5" },
  { value: "1.6", display: "1.6" },
  { value: "2.1", display: "2.1" },
  { value: "2.2", display: "2.2" },
  { value: "2.3", display: "2.3" },
  { value: "2.4", display: "2.4" },
  { value: "3.1", display: "3.1" },
  { value: "3.2", display: "3.2" },
  { value: "5.1", display: "5.1" },
  { value: "5.2", display: "5.2" },
  { value: "6.1", display: "6.1" },
  { value: "6.2", display: "6.2" },
  { value: "7.1", display: "7.1" },
  { value: "8.1", display: "8.1" },
  { value: "8.2", display: "8.2" },
  { value: "8.4", display: "8.4" },
  { value: "8.5", display: "8.5" },
  { value: "8.6", display: "8.6" },
  { value: "9.3", display: "9.3" },
  { value: "11.1", display: "11.1" },
  { value: "11.2", display: "11.2" },
  { value: "11.3", display: "11.3" },
  { value: "11.4", display: "11.4" },
  { value: "11.5", display: "11.5" },
  { value: "12.1", display: "12.1" },
  { value: "13.1", display: "13.1" },
  { value: "13.2", display: "13.2" },
  { value: "13.3", display: "13.3" },
  { value: "13.4", display: "13.4" },
  { value: "13.30", display: "13.30" },
  { value: "13.31", display: "13.31" },
  { value: "14.1", display: "14.1" },
  { value: "14.2", display: "14.2" },
  { value: "14.3", display: "14.3" },
  { value: "14.4", display: "14.4" },
  { value: "14.5", display: "14.5" },
  { value: "14.30", display: "14.30" },
  { value: "14.31", display: "14.31" },
  { value: "15.1", display: "15.1" },
  { value: "16.1", display: "16.1" },
  { value: "17.1", display: "17.1" },
  { value: "17.2", display: "17.2" },
  { value: "17.3", display: "17.3" },
  { value: "17.4", display: "17.4" },
  { value: "17.5", display: "17.5" },
  { value: "17.6", display: "17.6" },
];

export const vatCategory = [...Array(8).keys()].map((n) => ({
  value: (n + 1).toString(),
  display: (n + 1).toString(),
}));

export const vatExemptionCategory = [...Array(31).keys()].map((n) => ({
  value: (n + 1).toString(),
  display: (n + 1).toString(),
}));

export const incomeClassificationCategory = [
  { value: "category1_1", display: "category1_1" },
  { value: "category1_2", display: "category1_2" },
  { value: "category1_3", display: "category1_3" },
  { value: "category1_4", display: "category1_4" },
  { value: "category1_5", display: "category1_5" },
  { value: "category1_6", display: "category1_6" },
  { value: "category1_7", display: "category1_7" },
  { value: "category1_8", display: "category1_8" },
  { value: "category1_9", display: "category1_9" },
  { value: "category1_10", display: "category1_10" },
  { value: "category1_95", display: "category1_95" },
  { value: "category3", display: "category3" },
];

export const incomeClassificationValueType = [
  //TODO: Add type description
  { value: "E3_561_001", display: "E3_561_001" },
  { value: "E3_561_002", display: "E3_561_002" },
  { value: "E3_561_003", display: "E3_561_003" },
  { value: "E3_561_004", display: "E3_561_004" },
  { value: "E3_561_005", display: "E3_561_005" },
  { value: "E3_561_006", display: "E3_561_006" },
  { value: "E3_561_007", display: "E3_561_007" },
  { value: "E3_562", display: "E3_562" },
  { value: "E3_563", display: "E3_563" },
  { value: "E3_564", display: "E3_564" },
  { value: "E3_565", display: "E3_565" },
  { value: "E3_566", display: "E3_566" },
  { value: "E3_567", display: "E3_567" },
  { value: "E3_568", display: "E3_568" },
  { value: "E3_570", display: "E3_570" },
  { value: "E3_595", display: "E3_595" },
  { value: "E3_596", display: "E3_596" },
  { value: "E3_597", display: "E3_597" },
  { value: "E3_880_001", display: "E3_880_001" },
  { value: "E3_880_002", display: "E3_880_002" },
  { value: "E3_880_003", display: "E3_880_003" },
  { value: "E3_880_004", display: "E3_880_004" },
  { value: "E3_881_001", display: "E3_881_001" },
  { value: "E3_881_002", display: "E3_881_002" },
  { value: "E3_881_003", display: "E3_881_003" },
  { value: "E3_881_004", display: "E3_881_004" },
  { value: "E3_598_001", display: "E3_598_001" },
  { value: "E3_598_003", display: "E3_598_003" },
  { value: "E3_106", display: "E3_106" },
  { value: "E3_205", display: "E3_205" },
  { value: "E3_210", display: "E3_210" },
  { value: "E3_305", display: "E3_305" },
  { value: "E3_310", display: "E3_310" },
  { value: "E3_318", display: "E3_318" },
];

export const expensesClassificationValueType = [
  { value: "E3_101", display: "E3_101" },
  { value: "E3_102_001", display: "E3_102_001" },
  { value: "E3_102_002", display: "E3_102_002" },
  { value: "E3_102_003", display: "E3_102_003" },
  { value: "E3_102_004", display: "E3_102_004" },
  { value: "E3_102_005", display: "E3_102_005" },
  { value: "E3_102_006", display: "E3_102_006" },
  { value: "E3_104", display: "E3_104" },
  { value: "E3_201", display: "E3_201" },
  { value: "E3_202_001", display: "E3_202_001" },
  { value: "E3_202_002", display: "E3_202_002" },
  { value: "E3_202_003", display: "E3_202_003" },
  { value: "E3_202_004", display: "E3_202_004" },
  { value: "E3_202_005", display: "E3_202_005" },
  { value: "E3_204", display: "E3_204" },
  { value: "E3_207", display: "E3_207" },
  { value: "E3_209", display: "E3_209" },
  { value: "E3_301", display: "E3_301" },
  { value: "E3_302_001", display: "E3_302_001" },
  { value: "E3_302_002", display: "E3_302_002" },
  { value: "E3_302_003", display: "E3_302_003" },
  { value: "E3_302_004", display: "E3_302_004" },
  { value: "E3_302_005", display: "E3_302_005" },
  { value: "E3_304", display: "E3_304" },
  { value: "E3_307", display: "E3_307" },
  { value: "E3_309", display: "E3_309" },
  { value: "E3_312", display: "E3_312" },
  { value: "E3_313_001", display: "E3_313_001" },
  { value: "E3_313_002", display: "E3_313_002" },
  { value: "E3_313_003", display: "E3_313_003" },
  { value: "E3_313_004", display: "E3_313_004" },
  { value: "E3_313_005", display: "E3_313_005" },
  { value: "E3_315", display: "E3_315" },
  { value: "E3_581_001", display: "E3_581_001" },
  { value: "E3_581_002", display: "E3_581_002" },
  { value: "E3_581_003", display: "E3_581_003" },
  { value: "E3_582", display: "E3_582" },
  { value: "E3_583", display: "E3_583" },
  { value: "E3_584", display: "E3_584" },
  { value: "E3_585_001", display: "E3_585_001" },
  { value: "E3_585_002", display: "E3_585_002" },
  { value: "E3_585_003", display: "E3_585_003" },
  { value: "E3_585_004", display: "E3_585_004" },
  { value: "E3_585_005", display: "E3_585_005" },
  { value: "E3_585_006", display: "E3_585_006" },
  { value: "E3_585_007", display: "E3_585_007" },
  { value: "E3_585_008", display: "E3_585_008" },
  { value: "E3_585_009", display: "E3_585_009" },
  { value: "E3_585_010", display: "E3_585_010" },
  { value: "E3_585_011", display: "E3_585_011" },
  { value: "E3_585_012", display: "E3_585_012" },
  { value: "E3_585_013", display: "E3_585_013" },
  { value: "E3_585_014", display: "E3_585_014" },
  { value: "E3_585_015", display: "E3_585_015" },
  { value: "E3_585_016", display: "E3_585_016" },
  { value: "E3_585_017", display: "E3_585_017" },
  { value: "E3_586", display: "E3_586" },
  { value: "E3_587", display: "E3_587" },
  { value: "E3_588", display: "E3_588" },
  { value: "E3_589", display: "E3_589" },
  { value: "E3_881_001", display: "E3_881_001" },
  { value: "E3_881_002", display: "E3_881_002" },
  { value: "E3_881_003", display: "E3_881_003" },
  { value: "E3_881_004", display: "E3_881_004" },
  { value: "E3_882_001", display: "E3_882_001" },
  { value: "E3_882_002", display: "E3_882_002" },
  { value: "E3_882_003", display: "E3_882_003" },
  { value: "E3_882_004", display: "E3_882_004" },
  { value: "E3_883_001", display: "E3_883_001" },
  { value: "E3_883_002", display: "E3_883_002" },
  { value: "E3_883_003", display: "E3_883_003" },
  { value: "E3_883_004", display: "E3_883_004" },
  { value: "E3_103", display: "E3_103" },
  { value: "E3_203", display: "E3_203" },
  { value: "E3_303", display: "E3_303" },
  { value: "E3_208", display: "E3_208" },
  { value: "E3_308", display: "E3_308" },
  { value: "E3_314", display: "E3_314" },
  { value: "E3_106", display: "E3_106" },
  { value: "E3_205", display: "E3_205" },
  { value: "E3_305", display: "E3_305" },
  { value: "E3_210", display: "E3_210" },
  { value: "E3_310", display: "E3_310" },
  { value: "E3_318", display: "E3_318" },
  { value: "E3_598_002", display: "E3_598_002" },
];

export const expensesClassificationCategory = [
  { value: "category2_1", display: "category2_1" },
  { value: "category2_2", display: "category2_2" },
  { value: "category2_3", display: "category2_3" },
  { value: "category2_4", display: "category2_4" },
  { value: "category2_5", display: "category2_5" },
  { value: "category2_6", display: "category2_6" },
  { value: "category2_7", display: "category2_7" },
  { value: "category2_8", display: "category2_8" },
  { value: "category2_9", display: "category2_9" },
  { value: "category2_10", display: "category2_10" },
  { value: "category2_11", display: "category2_11" },
  { value: "category2_12", display: "category2_12" },
  { value: "category2_13", display: "category2_13" },
  { value: "category2_14", display: "category2_14" },
  { value: "category2_95", display: "category2_95" },
];

export const expensesClassificationVatType = [
  { value: "VAT_361", display: "VAT_361" },
  { value: "VAT_362", display: "VAT_362" },
  { value: "VAT_363", display: "VAT_363" },
  { value: "VAT_364", display: "VAT_364" },
  { value: "VAT_365", display: "VAT_365" },
  { value: "VAT_366", display: "VAT_366" },
  { value: "NOT_VAT_295", display: "NOT_VAT_295" },
];

export const vatPercentOptions = [
  { value: 0.24, display: "24%" },
  { value: 0.13, display: "13%" },
  { value: 0.06, display: "06%" },
  { value: 0.17, display: "17%" },
  { value: 0.09, display: "9%" },
  { value: 0.04, display: "4%" },
  { value: 0.0, display: "0%" },
];

export const specialCategoryTypes = [
  //TODO: Add type description
  {
    value: "1",
    display_en: "1 Subsidies - Grants",
    display_el: "1 Επιδοτήσεις - Επιχορηγήσεις",
  },
  {
    value: "2",
    display_en: "2 Hotel Retail Revenue",
    display_el: "2 Έσοδα Λιανικής Ξενοδοχείων",
  },
  {
    value: "3",
    display_en: "3 Accounting Registration",
    display_el: "3 Λογιστική Εγγραφή",
  },
  {
    value: "4",
    display_en: "4 Ανευ Φόρου",
    display_el: "4 Tax Free",
  },
  {
    value: "5",
    display_en: "5 Complex domestic - international transactions",
    display_el: "5 Σύνθετες συναλλαγές ημεδαπής - αλλοδαπής",
  },
  {
    value: "6",
    display_en: "6 Beneficiaries of Article 3",
    display_el: "6 Δικαιούχοι του άρθρου 3",
  },
  {
    value: "7",
    display_en: "7 Purchase of agricultural goods and services",
    display_el: "7 Αγορά αγροτικών αγαθών υπηρεσιών",
  },
  // {
  //   value: "8",
  //   display_en: "8 Retail Revenues FEM AADE_1",
  //   display_el: "8 Έσοδα Λιανικών ΦΗΜ ΑΑΔΕ_1",
  // },
  // {
  //   value: "9",
  //   display_en: "9 Retail Revenues FEM AADE_2",
  //   display_el: "9 Έσοδα Λιανικών ΦΗΜ ΑΑΔΕ_2",
  // },
  {
    value: "10",
    display_en: "10 Retail Revenues FEM Business Deviation",
    display_el: "10 Έσοδα Λιανικών ΦΗΜ Επιχείρησης Απόκλιση",
  },
  {
    value: "11",
    display_en: "11 Heating benefit",
    display_el: "11 Επίδομα Θέρμανσης",
  },
  {
    value: "12",
    display_en: "12 Food sercices transactions",
    display_el: "12 Συναλλαγές εστίασης",
  },
  {
    value: "",
    display_en: "-",
    display_el: "-",
  },
];

export const nonEditableColumns = [
  { label: "id", valueFromProperty: "id" },
  { label: "gl_account_erp_id", valueFromProperty: "gl_account_erp_id" },
  { label: "item_erp_id", valueFromProperty: "item_erp_id" },
  { label: "quantity", valueFromProperty: "quantity" },
  { label: "item_description", valueFromProperty: "item_description" },
  { label: "vat_percent", valueFromProperty: "vat_percent" },
  { label: "vat_amount", valueFromProperty: "vat_amount" },
  { label: "net_value", valueFromProperty: "net_value" },
];

export const editableColumns = [
  {
    label: "aade_vat_category",
    valueFromProperty: "aade_vat_category",
    menu: [...vatCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_vat_exemption_category",
    valueFromProperty: "aade_vat_exemption_category",
    menu: [...vatExemptionCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_category",
    valueFromProperty: "aade_expenses_category",
    menu: [...expensesClassificationCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_type",
    valueFromProperty: "aade_expenses_type",
    menu: [...expensesClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_type",
    valueFromProperty: "aade_expenses_vat_type",
    menu: [...expensesClassificationVatType, { value: "", display: "-" }],
  },
  {
    label: "aade_income_category",
    valueFromProperty: "aade_income_category",
    menu: [...incomeClassificationCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_income_type",
    valueFromProperty: "aade_income_type",
    menu: [...incomeClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "is_discount_option",
    valueFromProperty: "is_discount_option",
    menu: [
      { value: true, display: "✓" },
      { value: "", display: "-" },
    ],
  },
];
