import { Button, TableFooter, TableHead, TableSortLabel, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { visuallyHidden } from '@mui/utils';
import { useCallback, useEffect, useState } from 'react';
import { MdListAlt } from 'react-icons/md';
import { Link } from 'react-router-dom';
import uiText from "../../context/translation.json";
import styles from './TableMDE.module.css';
import TableMDEPaginationActions from './TableMDEPaginationActions';


const floatFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  roundingMode: 'halfExpand'
});

const intFormatter = new Intl.NumberFormat("de-DE", {
  style: "decimal",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  roundingMode: 'halfExpand'
});


// for styling https://stackoverflow.com/questions/75481613/how-to-make-mui-table-with-sticky-header-and-sticky-column
export default function TableMDE({
  columns,
  rows,
  total,
  customTooltipColumns,
  floatColumns,
  integerColumns,
  columnForceStyle,
  styleToForce,
  order,
  orderBy,
  onRequestSort,
  onRequestPopupForm,
  uiLang,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, [])

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  // omg closure, high-order function and factory in the wild
  const createSortHandler = (newOrderBy) => (event) => {
    onRequestSort(event, newOrderBy);
  };

  useEffect(() => {
    setPage(0);
  }, [columns, rows, total])

  return (
    <div className={`${styles['table-container']}`}>
      <TableContainer sx={{ maxHeight: 880, width: 'fit-content', maxWidth: '90%' }} component={Paper}>
        <Table stickyHeader sx={{ minWidth: 200 }} aria-label="sticky header">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth, padding: '16px 4px', }}
                  className={`${index === 0 ? styles['table-super-sticky-header'] : ''}`}
                  sortDirection={orderBy === column.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, rowIndex) => (
              <TableRow key={row?.id ?? '-1'}>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}
                    className={`${index === 0 ? styles['table-sticky-first-column'] : ''}`}
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      padding: '4px 4px',
                      // width: '350px',
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      ...(columnForceStyle.includes(column.id) && styleToForce),
                      ...(!columnForceStyle.includes(column.id) && rowIndex % 2 === 0 && { backgroundColor: 'white' }),
                      ...(!columnForceStyle.includes(column.id) && rowIndex % 2 !== 0 && { backgroundColor: '#d1dce0' }),
                    }}
                    align="right"
                    component="th"
                    scope="row"
                  >
                    {!integerColumns.includes(column.id) && !floatColumns.includes(column.id) && column.id !== 'link' && column.id !== 'popupForm' &&
                      <Tooltip placement={'right'} title={column.id in customTooltipColumns ? customTooltipColumns[column.id]?.[row[column.id]]?.[uiLang] : row[column.id]}>
                        <span>{row[column.id]}</span>
                      </Tooltip>
                    }
                    {column.id === 'popupForm' && 
                      <Button
                        onClick={() => {
                          if (onRequestPopupForm !== null && onRequestPopupForm !== undefined) {
                            onRequestPopupForm(row?.id)
                          } else {
                            console.error("onRequestPopupForm onClick handler function was not provided as prop")
                          }
                        }} 
                        className={styles['table-buttons']}
                      > 
                        <MdListAlt fontSize="30px" id={row.id} />
                      </Button>
                    }
                    {column.id === 'link' && <Link to={row[column.id]} className={styles['table-buttons']}> <MdListAlt fontSize="30px" id={row.id} /></Link>}
                    {floatColumns.includes(column.id) && <span>{floatFormatter.format(row[column.id])}</span>}
                    {integerColumns.includes(column.id) && <span>{intFormatter.format(row[column.id])}</span>}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {Object.keys(total).length !== 0 &&
            <TableFooter>
              <TableRow>
                {columns?.map((column, index) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      maxWidth: column.maxWidth,
                      padding: '16px 4px',
                      ...(index === 0 && { zIndex: 99, left: 0 }),
                      fontWeight: 'bolder',
                      fontSize: '0.75rem'
                    }}
                    className={`${styles['table-sticky-last-row']}`}
                  >
                    {!integerColumns.includes(column.id) && !floatColumns.includes(column.id) && total[column.id]}
                    {floatColumns.includes(column.id) && floatFormatter.format(total[column.id])}
                    {integerColumns.includes(column.id) && intFormatter.format(total[column.id])}
                  </TableCell>
                ))}
              </TableRow>
            </TableFooter>}
        </Table>
      </TableContainer>
      <div className={`${styles['pagination-container']}`}>
        <TablePagination
          rowsPerPageOptions={[20, 50, 100]}
          colSpan={3}
          count={rows.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={uiText.ui_rows_per_page[uiLang]}
          page={page}
          SelectProps={{
            inputProps: {
              'aria-label': 'rows per page',
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TableMDEPaginationActions}
        />
      </div>
    </div>
  );
}
