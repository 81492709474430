import TableMDE from "../../components/TableMDE/TableMDE"
import uiText from "../../context/translation.json";
import DocumentLinesTranslation from "./DocumentLinesTranslations.json";
import uiTextClassificationType from "../../context/aade_classification_type.json";
import uiTextClassificationCategory from "../../context/aade_classification_category.json";
import { Typography, IconButton, Button, Grid, Tooltip, Popover } from "@mui/material";
import styles from './DocumentLines.module.css';
import { useParams, useLocation } from "react-router-dom";
import { MdFilterAlt } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { useCallback, useEffect, useMemo, useState } from "react";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { Restore } from "@mui/icons-material";
import { getDocumentLines } from "./DocumentLinesAPI";
import FilterPopover from "../../components/FilterPopover/FilterPopover";
import { getComparator, } from "../../components/TableMDE/TableMDEUtils";
import Breadcrump from "../../components/Breadcrump/Breadcrump";
import SelectMDE from "../../components/SelectMDE/SelectMDE";
import { exportAsXLSX, filterRow } from '../documentLines/DocumentLinesUtils';
import { databaseDatetimeToDate, formatDate } from "../../utils/formatDate";

/*
const allColumns = [
  'aade_expenses_category',
  'aade_expenses_type',
  'aade_expenses_vat_type',
  'aade_income_category',
  'aade_income_type',
  'aade_vat_category',
  'aade_vat_exemption_category',
  'address',
  'city',
  'country',
  'currency',
  'deductions_amount',
  'discount',
  'document_category',
  'document_date',
  'document_id',
  'document_status',
  'erp_id',
  'erp_line_number',
  'exchange_rate',
  'fees_amount',
  'gl_account_erp_id',
  'gl_account_name',
  'gross_value',
  'id',
  'item_description',
  'item_erp_id',
  'key',
  'net_value',
  'number',
  'organization_id',
  'origin_status',
  'origin_type',
  'other_taxes_amount',
  'partner_name',
  'postal_code',
  'quantity',
  'stamp_duty_amount',
  'vat_amount',
  'vat_number',
  'vat_percent',
  'withheld_amount',
]
*/

const columnsToExclude = [
  'document_id',
  'id',
  'organization_id',
  'number',
  'currency',
  'exchange_rate',
  'discount',
  'document_category',
]

const columnsToExcludeForIncome = [
  'aade_expenses_category',
  'aade_expenses_type',
  'aade_expenses_vat_type',
]

const columnsToExcludeForExpenses = [
  'aade_income_category',
  'aade_income_type',
]

const floatColumns = [
  'deductions_amount',
  'fees_amount',
  'gross_value',
  'net_value',
  'other_taxes_amount',
  'stamp_duty_amount',
  'vat_amount',
  'withheld_amount',
  'vat_percent',
];

const columnsToFilterBy = [
  'aade_vat_category',
  'aade_vat_exemption_category',
  'address',
  'city',
  'country',
  'document_date',
  'document_id',
  'document_status',
  'erp_id',
  'erp_line_number',
  'gl_account_erp_id',
  'gl_account_name',
  'id',
  'item_description',
  'item_erp_id',
  'key',
  'origin_status',
  'origin_type',
  'partner_name',
  'postal_code',
  'quantity',
  'vat_number',
];

const columnsToFilterByForIncome = [
  'aade_income_category',
  'aade_income_type',
]

const columnsToFilterByForExpenses = [
  'aade_expenses_category',
  'aade_expenses_type',
  'aade_expenses_vat_type',
]

const integerColumns = [];

const columnForceStyle = [];

const styleToForce = {};

/* 
key: column.id, value: hash to get stuff from. 
The keys of the hash must be table cell values(usually enumeration values).
The values of the hash should be the tooltip to display depending on language.
*/
const customTooltipColumns = {
  aade_expenses_category: uiTextClassificationCategory,
  aade_expenses_type: uiTextClassificationType,
  aade_expenses_vat_type: uiTextClassificationType,
  aade_income_category: uiTextClassificationCategory,
  aade_income_type: uiTextClassificationType,
}

/* 
key: column.id, value: hash to get stuff from. 
The keys of the hash must be table cell values(usually enumeration values).
The values of the hash should be the translation depending on language.
*/
const columnsToTranslate = {
  document_status: uiText,
  origin_status: DocumentLinesTranslation,
}

const DocumentLines = ({
  company,
  companies,
  onCompanyChange,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
  defaultStartingDate,
  defaultEndingDate,
  uiLang,
}) => {
  const [detailData, setDetailData] = useState([]);
  const [detailColumns, setDetailColumns] = useState([]);
  const { search: searchString } = useLocation();
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({ id: '', }); // dummy initialization
  const { category } = useParams();
  const searchParams = useMemo(() => (Object.fromEntries(new URLSearchParams(searchString))), [searchString]);

  // URL search parameters 
  /* 
  It's really important for these to be immutable objects (undefined, strings)
  or else if it's array, we got infinite loop because React will check in the useEffect for [] === [], 
  which is false and as a result it will keep rerendering.
  */
  const {
    vat_number_keys,
    document_flags,
    aade_types,
    aade_categories,
    aade_vat_types,
    document_ids,
  } = searchParams;

  const fetchDetailData = useCallback(async (
    category,
    company,
    startingDate,
    endingDate,
    vat_number_keys,
    document_flags,
    aade_types,
    aade_categories,
    aade_vat_types,
    document_ids,
  ) => {
    setLoadingDocs(true);
    setDetailData([]);
    setDetailColumns([]);
    try {
      const { data } = await getDocumentLines(
        category?.toUpperCase(),
        company,
        startingDate,
        endingDate,
        vat_number_keys,
        document_flags,
        aade_types,
        aade_categories,
        aade_vat_types,
        document_ids,
      )
      if (data.length === 0) {
        setDetailData([]);
        setDetailColumns([]);
      } else {
        /*
          - assume all objects have the same properties
          - add extra property for link
          - add extra property for id
        */
        const stateData = data.map(item => {
          const linkAttached =  {
            link: `/${item.document_category?.toLowerCase()}/all/${item.document_id}`,
            ...item
          }
          // Format date to ignore time. Keep yyyy-mm-dd, because sorting.
          linkAttached.document_date = databaseDatetimeToDate(linkAttached.document_date);
          return linkAttached
        })
        setDetailData(stateData);
        /*
          - id is used as key to account for duplicate vat_number_key
          - add extra property for link to partner
        */
        const totalColumnsToExclude = [
          ...columnsToExclude,
          ...(category === 'expenses' ? columnsToExcludeForExpenses : []),
          ...(category === 'income' ? columnsToExcludeForIncome : [])
        ]
        setDetailColumns(Object.keys(stateData[0]).filter(key => !totalColumnsToExclude.includes(key)));
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDocs(false);
      setOrder('asc');
      setOrderBy('');
      setAnchorEl(null);
      setFilters(Object.fromEntries([
        ...(category === 'expenses' ? columnsToFilterByForExpenses : []),
        ...(category === 'income' ? columnsToFilterByForIncome : []),
        ...columnsToFilterBy,
        
      ].map(column => ([column, '']))))
    }
  }, [])

  useEffect(() => {
    if (company !== null && startingDate instanceof Date && endingDate instanceof Date && startingDate <= endingDate) {
      fetchDetailData(
        category,
        company,
        formatDate(startingDate),
        formatDate(endingDate),
        vat_number_keys,
        document_flags,
        aade_types,
        aade_categories,
        aade_vat_types,
        document_ids,
      )
    }
  }, [
    category,
    company,
    startingDate,
    endingDate,
    fetchDetailData,
    vat_number_keys,
    document_flags,
    aade_types,
    aade_categories,
    aade_vat_types,
    document_ids,
  ]);

  const handleChangeCompany = (event) => {
    onCompanyChange(event.target.value);
  };

  const handleResetDates = () => {
    setStartingDate(defaultStartingDate);
    setEndingDate(defaultEndingDate);
  };

  const handleOpenFilterPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = useCallback((event, newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    const toggledOrder = isAsc ? 'desc' : 'asc';
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
  }, [order, orderBy]);

  // process columns
  const columns = detailColumns.map((columnName) => ({ id: columnName, label: DocumentLinesTranslation[columnName][uiLang], minWidth: 100, maxWidth: 350, align: 'right', }))

  // translate. Get good :)
  const translatedData = useMemo(() => {
    return detailData?.map((row) => (Object.fromEntries(Object.keys(row).map(column => [column, column in columnsToTranslate ? columnsToTranslate[column]?.[row[column]]?.[uiLang]: row[column]]))));
  }, [detailData, uiLang]);

  // filter
  const filteredRows = useMemo(() => {
    return translatedData?.filter((row) => (filterRow(row, filters)));
  }, [translatedData, filters,]);

  // sort
  const sortedRows = useMemo(() => {
    return filteredRows?.slice()?.sort(getComparator(order, orderBy))
  }, [order, orderBy, filteredRows,]);

  // totals for filtered, sorted data
  const total = {};

  return (
    <>
      <div className={`${styles['details-breadcrumb-container']}`}>
        <Breadcrump category={category} status={'DETAILS'} uiLang={uiLang} />
        <div className={`${styles['details-date-reset-button-container']}`}>
          <Button onClick={handleResetDates} disabled={loadingDocs}>
            <Typography
              style={{
                color: "#243e71",
                wordWrap: "normal",
                textTransform: "none",
              }}
            >
              {uiText.ui_reset_dates[uiLang]}
            </Typography>
            <IconButton>
              <Restore />
            </IconButton>
          </Button>
        </div>
        <div className={`${styles['details-date-csv-download-container']}`}>
          <Button style={{ color: '#243e71' }} onClick={() => exportAsXLSX(columns, sortedRows, `${category} details`, 'MyDataEaseDetailsExport.xlsx')} disabled={loadingDocs}>
            <SiMicrosoftexcel fontSize="40px" />
          </Button>
        </div>
      </div>
      <div className={`${styles['details-top-row-container']}`}>
        <SelectMDE
          value={company}
          disabled={loadingDocs}
          onChange={handleChangeCompany}
          label={uiText.ui_company[uiLang]}
          menuItems={companies.map((comp) => ({ key: comp.value, value: comp.value, label: comp.text }))}
        />
        <Grid
          item
          container
          spacing={2}
          display={"flex"}
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          xs={'auto'}
        >
          <DateRangePicker
            startingDate={startingDate}
            endingDate={endingDate}
            setStartingDate={setStartingDate}
            setEndingDate={setEndingDate}
            startLabel={uiText.ui_from[uiLang]}
            endLabel={uiText.ui_to[uiLang]}
            disabled={loadingDocs}
          />
        </Grid>
        <Tooltip title={`${uiText.filters[uiLang]}`}>
          <IconButton aria-describedby={anchorEl ? 'simple-popover' : undefined} variant="text" onClick={handleOpenFilterPopover}>
            <MdFilterAlt />
          </IconButton>
        </Tooltip>
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseFilterPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FilterPopover
            filtersLabels={Object.fromEntries(Object.keys(filters).map(k => [k, DocumentLinesTranslation[k][uiLang]]))}
            filters={filters}
            setFilters={setFilters}
            disabled={loadingDocs}
            uiLang={uiLang}
          />
        </Popover>


      </div>
      <TableMDE
        columns={columns}
        rows={sortedRows}
        total={total}
        floatColumns={floatColumns}
        integerColumns={integerColumns}
        columnForceStyle={columnForceStyle}
        styleToForce={styleToForce}
        customTooltipColumns={customTooltipColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        uiLang={uiLang}
      />
    </>
  )
}

export default DocumentLines