import { post } from "../../api";

export const getDocumentLines = (
  category,
  company_id,
  startingDate,
  endingDate,
  vat_number_keys,
  document_flags,
  aade_types,
  aade_categories,
  aade_vat_types,
  document_ids,
) => {

  // query params
  const filters = {
    organization_id: company_id,
    document_category: category,
    ...(startingDate && { date_from: startingDate + "T00:00:00" }),
    ...(endingDate && { date_to: endingDate + "T00:00:00" }),
    skip: 0,
    limit: 0
  };

  // body
  const body = {
    ...(vat_number_keys !== undefined && { vat_number_keys: [vat_number_keys] }),
    ...(document_flags !== undefined && { document_flags: [document_flags] }),
    ...(aade_types !== undefined && { aade_types: [aade_types] }),
    ...(aade_categories !== undefined && { aade_categories: [aade_categories] }),
    ...(aade_vat_types !== undefined && { aade_vat_types: [aade_vat_types] }),
    ...(document_ids !== undefined && { document_ids: [document_ids] }),
  };

  const queryParams = Object.keys(filters).reduce((acc, key) => acc + `&${key}=${filters[key]}`, "");

  return post(`document_details?${queryParams}`, body);
}
