import { FormControl, MenuItem, Select, Typography } from "@mui/material"

const SelectMDE = ({
  value,
  disabled,
  onChange,
  label,
  menuItems,
}) => {
  return (
    <div style={{ margin: "0 0.5rem" }}>
      <Typography style={{ color: "#243e71" }}>
        {label}
      </Typography>
      <FormControl variant="standard" sx={{ width: 250 }}>
        <Select
          labelId={label}
          id={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          label={label}
          autoWidth
        >
          {menuItems.map(item => (<MenuItem key={item.key} value={item.value}>{item.label}</MenuItem>))}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectMDE