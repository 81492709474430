import axios from 'axios';

axios.interceptors.request.use(
    function (config) {
        const token = sessionStorage.getItem("access_token")
        if (config.url.indexOf("authorization") > -1) {
            return config
        }
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config
    },
    (error) => {
        // console.log('interceptor error', error);
        Promise.reject(error)
    }
)

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("azure_auth");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("credentials");
            window.location.href = "/login"
            return Promise.reject(error)
        }

        return Promise.reject(error)
    }
)

const baseUrl = window.BACKEND_BASE_URL

export function get(url) {
    return axios.get(`${baseUrl}/${url}`);
}

export function post(url, payload, options = {}) {
    return axios.post(`${baseUrl}/${url}`, payload, options)
}

export function remove(url) {
    return axios.delete(`${baseUrl}/${url}`)
}

export function put(url, payload) {
    return axios.put(`${baseUrl}/${url}`, payload)
}

export function patch(url, payload) {
    return axios.patch(`${baseUrl}/${url}`, payload)
}