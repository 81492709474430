import {
  documentCategoryType,
  expensesClassificationCategory,
  expensesClassificationValueType,
  expensesClassificationVatType,
  incomeClassificationCategory,
  incomeClassificationValueType,
  invoiceTypes,
  recordCategoryType,
  vatCategory,
  vatExemptionCategory,
  vatPercentOptions,
} from "../documents/aadeFieldMenuOptions";

export const nonEditableColumns = [
  { label: "id", valueFromProperty: "id" },
  { label: "name", valueFromProperty: "name" },
  { label: "organization_id", valueFromProperty: "organization_id" },
  { label: "erp_id", valueFromProperty: "erp_id" },
  { label: "vat_amount", valueFromProperty: "vat_amount" },
  { label: "key", valueFromProperty: "key" },
];

export const editableColumns = [
  {
    label: "document_category",
    valueFromProperty: "document_category",
    menu: [...documentCategoryType, { value: "", display: "-" }],
  },
  {
    label: "record_category",
    valueFromProperty: "record_category",
    menu: [...recordCategoryType, { value: "", display: "-" }],
  },
  {
    label: "vat_percent",
    valueFromProperty: "vat_percent",
    menu: [...vatPercentOptions, { value: "", display: "-" }],
  },
  {
    label: "aade_invoice_type",
    valueFromProperty: "aade_invoice_type",
    menu: [...invoiceTypes, { value: "", display: "-" }],
  },
  {
    label: "aade_income_category",
    valueFromProperty: "aade_income_category",
    menu: [...incomeClassificationCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_income_type",
    valueFromProperty: "aade_income_type",
    menu: [...incomeClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_category",
    valueFromProperty: "aade_expenses_category",
    menu: [...expensesClassificationCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_type",
    valueFromProperty: "aade_expenses_type",
    menu: [...expensesClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_vat_category",
    valueFromProperty: "aade_vat_category",
    menu: [...vatCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_vat_exemption_category",
    valueFromProperty: "aade_vat_exemption_category",
    menu: [...vatExemptionCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_category",
    valueFromProperty: "aade_expenses_vat_category",
    menu: [...expensesClassificationCategory, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_type",
    valueFromProperty: "aade_expenses_vat_type",
    menu: [...expensesClassificationVatType, { value: "", display: "-" }],
  },
  {
    label: "aade_income_type_gr",
    valueFromProperty: "aade_income_type_gr",
    menu: [...incomeClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_type_gr",
    valueFromProperty: "aade_expenses_type_gr",
    menu: [...expensesClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_type_gr",
    valueFromProperty: "aade_expenses_vat_type_gr",
    menu: [...expensesClassificationVatType, { value: "", display: "-" }],
  },
  {
    label: "aade_income_type_eu",
    valueFromProperty: "aade_income_type_eu",
    menu: [...incomeClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_type_eu",
    valueFromProperty: "aade_expenses_type_eu",
    menu: [...expensesClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_type_eu",
    valueFromProperty: "aade_expenses_vat_type_eu",
    menu: [...expensesClassificationVatType, { value: "", display: "-" }],
  },
  {
    label: "aade_income_type_3rd",
    valueFromProperty: "aade_income_type_3rd",
    menu: [...incomeClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_type_3rd",
    valueFromProperty: "aade_expenses_type_3rd",
    menu: [...expensesClassificationValueType, { value: "", display: "-" }],
  },
  {
    label: "aade_expenses_vat_type_3rd",
    valueFromProperty: "aade_expenses_vat_type_3rd",
    menu: [...expensesClassificationVatType, { value: "", display: "-" }],
  },
  {
    label: "vat_gl_account",
    valueFromProperty: "vat_gl_account",
  },
];
