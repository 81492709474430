import { IconButton, Popover, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { MdFilterAlt } from "react-icons/md";
import FilterPopover from "../../components/FilterPopover/FilterPopover";
import SelectMDE from "../../components/SelectMDE/SelectMDE";
import TableMDE from "../../components/TableMDE/TableMDE";
import { filterRow, getComparator } from "../../components/TableMDE/TableMDEUtils";
import uiText from "../../context/translation.json";
import styles from "./GlAccount2.module.css";
import { GlAccountForm } from "./GlAccountForm";
import { getAccounts, patchAccount } from "./glAccountAPI";
import glAccountTranslations from "./glAccountTranslations.json";

const tryTranslation = (value, lang) => {
  return value in glAccountTranslations ? glAccountTranslations[value][lang] : value;
};

const columnsToDisplay = [
  "popupForm",
  "erp_id",
  "name",
  "document_category",
  "aade_invoice_type",
  "aade_income_category",
  "aade_income_type",
  "aade_expenses_category",
  "aade_expenses_type",
  "aade_vat_category",
  "aade_vat_exemption_category",
  "aade_expenses_vat_category",
  "aade_expenses_vat_type",
  "aade_income_type_gr",
  "aade_expenses_type_gr",
  "aade_expenses_vat_type_gr",
  "aade_income_type_eu",
  "aade_expenses_type_eu",
  "aade_expenses_vat_type_eu",
  "aade_income_type_3rd",
  "aade_expenses_type_3rd",
  "aade_expenses_vat_type_3rd",
  "detail_category_code",
  // "erp_database_name",
  // "erp_date_created",
  // "erp_date_updated",
  // "erp_table_name",
  "is_ignored",
  //"key",
  //"organization_id",
  "record_category",
  "vat_gl_account",
  "vat_percent",
];

const columnsToFilterBy = columnsToDisplay;

const total = {};
const floatColumns = [];
const integerColumns = [];
const columnForceStyle = [];
const styleToForce = {};
const customTooltipColumns = {};

const GLAccount2 = memo(({ company, companies, onCompanyChange, uiLang }) => {
  const [glAccounts, setGlAccounts] = useState([]);
  const [glAccountAreLoading, setGlAccountAreLoading] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState(Object.fromEntries(columnsToFilterBy.map((column) => [column, ""])));
  const [formDialogIsOpen, setFormDialogIsOpen] = useState(false);
  const [rowBeingEditedId, setRowBeingEditedId] = useState(null);

  const handlePopupFormOpen = (id) => {
    setFormDialogIsOpen(true);
    setRowBeingEditedId(id);
  };

  const handlePopupFormClose = () => {
    setFormDialogIsOpen(false);
    setRowBeingEditedId(null);
  };

  //Filter
  const handleOpenFilterPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };

  // sort
  const handleRequestSort = useCallback(
    (event, newOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
    },
    [order, orderBy]
  );

  const fetchGlAccountData = useCallback(async (organization_id) => {
    setGlAccountAreLoading(true);
    setGlAccounts([]);
    try {
      const { data } = await getAccounts({}, organization_id);
      if (data.length === 0) {
        setGlAccounts([]);
      } else {
        const stateData = data.map((item) => {
          const itemWithFormattedDate = {
            ...item,
            popupForm: item.id,
            erp_date_created: item.erp_date_created.split("T")[0],
            erp_date_updated: item.erp_date_updated.split("T")[0],
          };
          return itemWithFormattedDate;
        });
        setGlAccounts(stateData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setGlAccountAreLoading(false);
      setOrder("asc");
      setOrderBy("");
      setAnchorEl(null);
      setFilters(Object.fromEntries(columnsToFilterBy.map((column) => [column, ""])));
    }
  }, []);

  const handleSave = async (updatedRow) => {
    try {
      await patchAccount(updatedRow);
    } catch (error) {
      console.log(error);
    } finally {
      fetchGlAccountData(company);
      setFormDialogIsOpen(false);
      setRowBeingEditedId(null);
    }
  };

  //Fetch data from database
  useEffect(() => {
    if (company !== null) {
      fetchGlAccountData(company);
    }
  }, [company, fetchGlAccountData]);

  // filter
  const filteredRows = useMemo(() => {
    return glAccounts?.filter((row) => filterRow(row, filters));
  }, [glAccounts, filters]);

  // sort
  const sortedRows = useMemo(() => {
    return filteredRows?.slice()?.sort(getComparator(order, orderBy));
  }, [order, orderBy, filteredRows]);

  // process columns
  const columns = useMemo(() => {
    return columnsToDisplay.map((columnName) => ({
      id: columnName,
      label: tryTranslation(columnName, uiLang),
      minWidth: 100,
      maxWidth: 350,
      align: "right",
    }));
  }, [uiLang]);

  return (
    <>
      <div className={`${styles["details-top-row-container"]}`}>
        <SelectMDE
          value={company}
          disabled={glAccountAreLoading}
          onChange={(event) => onCompanyChange(event.target.value)}
          label={uiText.ui_company[uiLang]}
          menuItems={companies.map((comp) => ({ key: comp.value, value: comp.value, label: comp.text }))}
        />
        <Tooltip title={`${uiText.filters[uiLang]}`}>
          <IconButton
            aria-describedby={anchorEl ? "simple-popover" : undefined}
            variant="text"
            onClick={handleOpenFilterPopover}
          >
            <MdFilterAlt />
          </IconButton>
        </Tooltip>
        <Popover
          id={anchorEl ? "simple-popover" : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseFilterPopover}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <FilterPopover
            filtersLabels={Object.fromEntries(Object.keys(filters).map((k) => [k, tryTranslation(k, uiLang)]))}
            filters={filters}
            setFilters={setFilters}
            disabled={glAccountAreLoading}
            uiLang={uiLang}
          />
        </Popover>
      </div>
      <TableMDE
        columns={columns}
        rows={sortedRows}
        total={total}
        floatColumns={floatColumns}
        integerColumns={integerColumns}
        columnForceStyle={columnForceStyle}
        styleToForce={styleToForce}
        customTooltipColumns={customTooltipColumns}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        onRequestPopupForm={handlePopupFormOpen}
        uiLang={uiLang}
      />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={formDialogIsOpen}
        onClose={handlePopupFormClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {formDialogIsOpen && (
          <GlAccountForm
            key={rowBeingEditedId}
            initialFields={glAccounts.filter((account) => account.id === rowBeingEditedId)[0]}
            uiLang={uiLang}
            onCloseFormWithoutSaving={handlePopupFormClose}
            onSave={handleSave}
          />
        )}
      </Dialog>
    </>
  );
});
export default GLAccount2;
