import { Button, Card, CardActions, CardContent, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styles from './FilterPopover.module.css';
import uiText from "../../context/translation.json";

// TODO: make this one-liner or put it somewhere else
function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

/* 
Props:
  // name should be the same keys
  filtersLabels: {
    name: label
  }
  filters: {
    name: value
  }
  setFilters: React filter state setter
*/
const FilterPopover = ({ filtersLabels, filters, setFilters, disabled, uiLang }) => {
  const [filterFields, setFilterFields] = useState(null);
  
  useEffect(() => {
    if (filterFields === null) {
      setFilterFields(Object.fromEntries(Object.keys(filters).map(k => [k, filters[k]])));
    }
  }, [filters, filterFields])

  // while user is typing: delay. If filterFields equals filters(after above useEffect) return.
  useEffect(() => {
    if (filterFields === null || shallowEqual(filterFields, filters)) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      setFilters({ ...filterFields });
    }, 200);

    return () => clearTimeout(delayDebounceFn);
  }, [filterFields, setFilters])

  // handle typing
  const handleChangeFilterField = (event, column) => {
    setFilterFields((prev) => ({ ...prev, [column]: event.target.value }));
  }

  const handleResetFilterFields = () => {
    setFilterFields(Object.fromEntries(Object.keys(filtersLabels).map(k => [k, ''])));
  }

  return (
    <Card>
      <CardContent style={{maxHeight: '100%', overflow: 'auto'}}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {uiText.filters[uiLang]}
        </Typography>

        <div className={`${styles['filter-popover-field-container']}`}>
          {filterFields !== null && Object.keys(filterFields).map((column) => {
            return (
              <TextField
                sx={{
                  margin: '0.5rem 1rem',
                }}
                key={column}
                variant="outlined"
                label={filtersLabels[column]}
                value={filterFields[column]}
                id={column}
                disabled={disabled}
                onChange={(event) => { handleChangeFilterField(event, column) }}
                size="small"
                InputLabelProps={{
                  style: { color: '#243E71' },
                }}
              />
            )
          })}
        </div>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleResetFilterFields}>{uiText.reset_filters[uiLang]}</Button>
      </CardActions>
    </Card>
  )
}

export default FilterPopover