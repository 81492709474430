import { useState, useEffect, useCallback, } from "react";
import { Grid, Select, MenuItem, FormControl, Typography, IconButton, Button, Dialog, TextField, Alert, Snackbar, CircularProgress } from "@mui/material";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import uiText from "../../context/translation.json";
import { getAllTheDocuments } from "../documents/documentAPI";
import "./Match.css"
import { useLocation } from "react-router-dom";
import Card from '../../components/Card';
import DocumentDetails from "../documents/DocumentDetails";
import CloseIcon from "@mui/icons-material/Close";
import PostDocuments from './settingsRequests/match_requests'
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import Breadcrump from "../../components/Breadcrump/Breadcrump";
import { getUserData } from "../../auth/utils";
import { formatDate } from "../../utils/formatDate";

const expensesAlignmentOptions = [
  {value: 'full_connection', uiTextKey: 'fullConnection'},
  {value: 'partial_connection', uiTextKey: 'partialConnection'},
  {value: 'partial_connection2_95', uiTextKey: 'partialConnection2_95'},
  {value: 'missed_transmission', uiTextKey: 'missedTransmition'},
  {value: 'non_acceptable', uiTextKey: 'nonAcceptableTransaction'},
]

const incomeSelfPricingAlignmentOptions = [
  {value: 'full_connection', uiTextKey: 'fullConnection'},
  // {value: 'partial_connection', uiTextKey: 'partialConnection'},
  // {value: 'partial_connection2_95', uiTextKey: 'partialConnection2_95'},
  // {value: 'missed_transmission', uiTextKey: 'missedTransmition'},
  {value: 'non_acceptable', uiTextKey: 'nonAcceptableTransaction'},
]

const Match = (props) => {

  const [alignment, setAlignment] = useState('full_connection');
  const [companyDocumentsLoading, setCompanyDocumentsLoading] = useState(true);
  const [aadeDocumentsLoading, setAadeDocumentsLoading] = useState(true);
  const [postRequestLoading, setPostRequestLoading] = useState(false);
  const [companyDocuments, setCompanyDocuments] = useState([]);
  const [aadeDocuments, setAADEDocuments] = useState([]);
  const [detailedDocument, setDetailedDocument] = useState(null);
  const [filterFields, setFilterFields] = useState({
    vatNumber: '',
    partnerName: '',
    documentNumber: '',
    netAmount: '',
    vatAmount: '',
    totalAmount: '',
  })

  const [filters, setFilters] = useState({
    vatNumber: '',
    partnerName: '',
    documentNumber: '',
    netAmount: '',
    vatAmount: '',
    totalAmount: '',
  });

  const [open, setOpen] = useState(false);
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [companyCurrentPage, setCompanyCurrentPage] = useState(1);
  const [aadeCurrentPage, setAadeCurrentPage] = useState(1);
  const [alertOutcome, setAlertOutcome] = useState(null)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState(null);
  const {pathname: viewPathname }  = useLocation();
  const {1: category, 2: status} = viewPathname.split('/');
  
  const alignmentToHTTPParam = {
    full_connection: 'MANUAL',
    partial_connection: 'PARTIAL',
    missed_transmission: 'OMISSION',
    non_acceptable: 'REJECTION',
    partial_connection2_95: 'PARTIAL2_95',
  }
  
  const alignmentOptions = category === 'income' && status === 'no_mark_yet_is_self_pricing' ? incomeSelfPricingAlignmentOptions: expensesAlignmentOptions;

  const companySelectedDocuments = companyDocuments.filter(doc => doc.isSelected)
  const aadeSelectedDocuments = aadeDocuments.filter(doc => doc.isSelected)

  const user = getUserData();
  const userAuthArray = user?.job_actions ?? []
  // HTTP requests & effects

  const resetState = useCallback(() => {
    setCompanyCurrentPage(1);
    setAadeCurrentPage(1);
    /* setFilterFields({
      vatNumber: '',
      partnerName: '',
      documentNumber: '',
      netAmount: '',
      vatAmount: '',
      totalAmount: '',
    })
    setFilters({
      vatNumber: '',
      partnerName: '',
      documentNumber: '',
      netAmount: '',
      vatAmount: '',
      totalAmount: '',
    }) */
  }, [])

  const fetchDocs = useCallback(async (origin, fetch_document_category, fetch_is_self_pricing, document_status, company_id, startDate, endDate, function_to_set, loading_to_set) => {
    try {
      loading_to_set(true);

      function_to_set([]);

      const allPagesResponse = await getAllTheDocuments(
        {
          document_origin: origin,
          ...(origin === 'AADE' && { match_type: 'NONE' }),
          ...(origin === 'AADE' && { origin_status: 'ACTIVE' }),
          ...(fetch_is_self_pricing && {is_self_pricing: true})
        },
        document_status,
        undefined,
        [
          { "order_by_property": "document.partner_id",  "order_by_order": "ASC" },
          { "order_by_property": "document.document_date", "order_by_order": "ASC" },
          { "order_by_property": "document.id", "order_by_order": "ASC" },
        ],
        fetch_document_category,
        company_id,
        startDate,
        endDate,
      )

      if (allPagesResponse.data !== null && allPagesResponse.data.length !== 0) {
        const docsWithSelectedProp = allPagesResponse.data.map(doc => ({ isSelected: false, ...doc }))
        function_to_set(docsWithSelectedProp)
      } else {
        function_to_set([])
      }


    } catch (error) {
      function_to_set([])
    } finally {
      loading_to_set(false)
    }
  }, [])

  useEffect(() => {
    if (!(props.company !== null && props.startingDate instanceof Date && props.endingDate instanceof Date && props.startingDate <= props.endingDate)) {
      return
    }
    const startDateRequest = formatDate(props.startingDate)
    const endDateRequest = formatDate(props.endingDate)
    const fetch_document_category = category === 'income' ? 'INCOME': 'EXPENSES';
    const fetch_is_self_pricing = status === 'no_mark_yet_is_self_pricing';
    fetchDocs("ERP", fetch_document_category, fetch_is_self_pricing, ['PENDING_MARK'], props.company, startDateRequest, endDateRequest, setCompanyDocuments, setCompanyDocumentsLoading)
    fetchDocs("AADE", fetch_document_category, fetch_is_self_pricing, ['RETRIEVED_BY_AADE'], props.company, startDateRequest, endDateRequest, setAADEDocuments, setAadeDocumentsLoading)

    // reset all state
    resetState();
    setAlignment('full_connection');
  }, [props.company, category, status, props.startingDate, props.endingDate, fetchDocs, resetState]);

  const sendDocuments = async () => {
    try {
      setPostRequestLoading(true)
      const companyDocumentSelectedIds = companySelectedDocuments.map(doc => doc.id)
      const aadeDocumentSelectedIds = aadeSelectedDocuments.map(doc => doc.id)
      if (['full_connection', 'partial_connection', 'partial_connection2_95'].includes(alignment)) {
        // assert there is only one selected, probably not needed
        if (!(aadeDocumentSelectedIds.length === 1 && companyDocumentSelectedIds.length === 1)) {
          console.log('Something went wrong. Please Refresh the page')
        }
        await PostDocuments(alignmentToHTTPParam[alignment], companyDocumentSelectedIds[0], aadeDocumentSelectedIds[0], [])
        const msg = `no_match_yet_post_${alignment}_success_message`
        setAlertMessage(msg)
        setAlertOutcome('success')

      } else if (alignment === 'non_acceptable') {
        await PostDocuments(alignmentToHTTPParam[alignment], null, null, aadeDocumentSelectedIds)
        setAlertMessage('no_match_yet_post_non_acceptable_success_message')
        setAlertOutcome('success')
      } else {
        const response = await PostDocuments(alignmentToHTTPParam[alignment], null, null, companyDocumentSelectedIds)
        const msg = response.data?.message ?
          'no_match_yet_post_missed_transmission_warning_message' :
          'no_match_yet_post_missed_transmission_success_message'
        setAlertMessage(msg)
        const outcome = response.data.message ? 'warning' : 'success'
        setAlertOutcome(outcome)
      }

      // if it returns without error, refetch everything
      const startDateRequest = formatDate(props.startingDate)
      const endDateRequest = formatDate(props.endingDate)
      const fetch_document_category = category === 'income' ? 'INCOME': 'EXPENSES';
      const fetch_is_self_pricing = status === 'no_mark_yet_is_self_pricing';
      fetchDocs("ERP", fetch_document_category, fetch_is_self_pricing, ['PENDING_MARK'], props.company, startDateRequest, endDateRequest, setCompanyDocuments, setCompanyDocumentsLoading)
      fetchDocs("AADE", fetch_document_category, fetch_is_self_pricing, ['RETRIEVED_BY_AADE'], props.company, startDateRequest, endDateRequest, setAADEDocuments, setAadeDocumentsLoading)
  
      // reset all state
      resetState();

    } catch (error) {
      setAlertOutcome('error')
      setAlertMessage('no_match_yet_post_error_message')
    } finally {
      setPostRequestLoading(false);
      setAlertOpen(true)
    }
  }

  // delay filtering while user is typing
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters({ ...filterFields })
    }, 200)

    return () => clearTimeout(delayDebounceFn)
  }, [filterFields])

  // event handlers
  const handleOpenDialog = (id) => {
    setDetailedDocument(id)
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleChangePage = (value, origin, event) => {
    const pageSetter = origin === 'ERP' ? setCompanyCurrentPage : setAadeCurrentPage
    pageSetter(value)
  }

  const handleChangeAlignment = (event) => {
    const newAlignment = event.target.value
    setAlignment(newAlignment);

    // uncheck everything when alignment changes and return to page 1
    setCompanyDocuments(prev => prev.map(doc => ({ ...doc, isSelected: false })))
    setAADEDocuments(prev => prev.map(doc => ({ ...doc, isSelected: false })))
    setCompanyCurrentPage(1);
    setAadeCurrentPage(1);
  }

  const handleChangeCheckbox = (id, origin, event) => {
    // reset to page 1 on every check
    if (['full_connection', 'partial_connection', 'partial_connection2_95'].includes(alignment)) {
      const pageSetter = origin === 'ERP' ? setCompanyCurrentPage : setAadeCurrentPage
      pageSetter(1)
    }

    // company or aade
    const documentArray = origin === 'ERP' ? companyDocuments : aadeDocuments;
    const documentSetter = origin === 'ERP' ? setCompanyDocuments : setAADEDocuments;

    // get array index of document with id
    const idIndex = documentArray.findIndex((doc) => doc.id === id)

    // immutably set document array: change isSelected property for the document by id
    documentSetter(prev => {
      return [...prev.slice(0, idIndex), { ...prev[idIndex], isSelected: event.target.checked }, ...prev.slice(idIndex + 1)]
    })

  }

  const handleChangeFilterField = (event, column) => {
    setFilterFields((prev) => ({ ...prev, [column]: event.target.value }))
    setCompanyCurrentPage(1);
    setAadeCurrentPage(1);
  }

  const handleToggleFilter = () => setAreFiltersVisible(prev => !prev)

  const handleChangeCompany = (event) => props.onCompanyChange(event.target.value);

  // derived state
  const companyAtLeastOneSelected = companySelectedDocuments.length > 0
  const aadeAtLeastOneSelected = aadeSelectedDocuments.length > 0

  const sendIsDisabled = postRequestLoading ||
    !(
      (alignment === 'partial_connection' && companyAtLeastOneSelected && aadeAtLeastOneSelected) ||
      (alignment === 'full_connection' && companyAtLeastOneSelected && aadeAtLeastOneSelected) ||
      (alignment === 'partial_connection2_95' && companyAtLeastOneSelected && aadeAtLeastOneSelected) ||
      (alignment === 'missed_transmission' && companyAtLeastOneSelected) ||
      (alignment === 'non_acceptable' && aadeAtLeastOneSelected)
    )

  const sendButtonText = {
    full_connection: 'option_send_fully_connected',
    partial_connection: 'option_send_partially_connected',
    partial_connection2_95: 'option_send_partially_connected2_95',
    missed_transmission: 'option_send_missed_transmition',
    non_acceptable: 'option_send_non_acceptable_transaction',
  }

  const dataAreLoading = companyDocumentsLoading || aadeDocumentsLoading || postRequestLoading;
  const companyCheckboxesAreDisabled = alignment === 'non_acceptable' || dataAreLoading;
  const aadeCheckboxesAreDisabled = alignment === 'missed_transmission' || dataAreLoading;

  const userCanMatch = userAuthArray.includes('MATCH_DOCS');
  return (
    <>
      <div>
        <Dialog
          // fullScreen
          open={open}
          onClose={handleCloseDialog}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "93%",
                maxWidth: "2000px",  // Set your width here
              },
            },
          }}
        >
          <div style={{
            position: 'relative',
            top: '20px',
            right: '0px',
            display: 'flex',
            justifyContent: 'right'

          }}>
            <IconButton
              onClick={handleCloseDialog}
              aria-label="close"
              style={{ color: '#243e71', marginRight: '30px' }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ marginTop: '40px', paddingBottom: '50px' }}>
            <DocumentDetails company={props.company} uiLang={props.uiLang} id={detailedDocument} disableControls={true} />
          </div>

        </Dialog>
      </div>
      <div style={{ left: props.showMenuLabels ? 150 + props.drawerWidth : 150, paddingLeft: '60px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>
            <Grid
              item
              container
              display={"flex"}
              alignItems={"center"}
              position={"relative"}
              justifyContent={"space-between"}
              style={{ maxWidth: '300px', paddingBottom: '50px' }}
            >
              <Breadcrump 
                category={category} 
                status={status === 'no_mark_yet_is_self_pricing' ? 'ui_income_no_mark_yet_is_self_pricing' : 'ui_expenses_no_mark_yet'} 
                uiLang={props.uiLang}
              />
              <Grid
                item
                justifyContent={"center"}
                alignItems={"center"}
                direction="row"
                display={"flex"}
                style={{
                  position: "relative",
                }}
              >
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', alignContent: 'center', flexDirection: 'row', margin: '0 10px', justifyContent: 'space-evenly' }}>
            <div style={{ margin: '0 10px' }}>
              <Grid item container direction="column">
                <Typography style={{ color: "#243e71" }}>
                  {uiText['connectionType'][props.uiLang]}
                </Typography>
                <FormControl variant="standard" sx={{ width: 250, height: 50 }}>
                  <Select
                    labelId="demo-simple-select-label-connect"
                    id="demo-simple-select-connect"
                    value={alignment}
                    label={uiText['connectionType'][props.uiLang]}
                    onChange={handleChangeAlignment}
                    disabled={dataAreLoading}
                  >
                    {alignmentOptions.map(opt => <MenuItem value={opt.value}>{uiText[opt.uiTextKey][props.uiLang]}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </div>
            {userCanMatch && (
              <div style={{ margin: '0 10px' }}>
                <Button disabled={sendIsDisabled} variant="contained" className='ControlButton' style={{ width: 280, height: 60, backgroundColor: 'rgb(36, 62, 113)', padding: '10px' }} onClick={sendDocuments} >{postRequestLoading ? <CircularProgress /> : uiText[sendButtonText[alignment]][props.uiLang]}</Button>
              </div>)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            top: 100,
            alignItems: 'flex-start',
            width: '100%',
            paddingLeft: '10px'
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

            <Grid item container direction="column">
              <Typography style={{ color: "#243e71" }}>
                {uiText.ui_company[props.uiLang]}
              </Typography>
              <FormControl variant="standard" sx={{ width: 250 }}>
                <Select
                  labelId="demo-simple-select-label-company"
                  id="demo-simple-select"
                  value={props.company}
                  onChange={handleChangeCompany}
                  label={uiText.ui_company[props.uiLang]}
                  disabled={dataAreLoading}
                  autoWidth
                >
                  {props.companies.map((comp) => (
                    <MenuItem key={comp.value} value={comp.value}>
                      {comp.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              display={"flex"}
              direction={"row"}
              flexWrap={"nowrap"}
              alignItems={"center"}
              justifyContent={"space-around"}
              xs={6}
            >
              <DateRangePicker
                startingDate={props.startingDate}
                endingDate={props.endingDate}
                setStartingDate={props.setStartingDate}
                setEndingDate={props.setEndingDate}
                startLabel={uiText.ui_from[props.uiLang]}
                endLabel={uiText.ui_to[props.uiLang]}
                disabled={dataAreLoading}
              />
            </Grid>
          </div>
          <div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', marginRight: 20 }}>
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '30px' }}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', backgroundColor: 'rgba(36, 62, 113, 1)', width: '170px', height: '56px', cursor: 'pointer', borderRadius: '5px', padding: '3px' }}
                  onClick={() => {
                    if (!(dataAreLoading))
                      handleToggleFilter()
                  }}
                >
                  {!areFiltersVisible && <AiOutlineArrowDown style={{ fontSize: '25px', borderRadius: '90px', color: (dataAreLoading) ? 'gray' : 'white', marginTop: 'auto', marginBottom: 'auto' }} />}
                  {areFiltersVisible && <AiOutlineArrowUp style={{ fontSize: '25px', borderRadius: '90px', color: (dataAreLoading) ? 'gray' : 'white', marginTop: 'auto', marginBottom: 'auto' }} />}
                  <h4 style={{ marginTop: 'auto', marginBottom: 'auto', paddingRight: '30px', color: (dataAreLoading) ? 'gray' : 'white', fontWeight: 'normal' }}>{uiText['filters'][props.uiLang]}</h4>
                </div>
              </div>

              <div style={{ display: 'flex', opacity: !areFiltersVisible ? '0' : '1', transition: 'opacity 0.3s', marginTop: '15px', width: '100%', border: '1px solid rgba(36, 62, 113, .5)', borderRadius: '10px 10px 10px 10px', padding: '10px', maxWidth: '690px', justifyContent: 'center', alignContent: 'center', zIndex: 10, backgroundColor: 'white' }}>
                <div style={{ position: 'relative', opacity: !areFiltersVisible ? '0' : '1', transition: 'opacity 1s' }}>
                  <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '153px', height: '40px', borderRadius: '45px', border: '2px solid rgba(0, 0, 0,0.5)', backgroundColor: 'white', position: 'absolute', top: '-30px' }}></div>
                    <div style={{ width: '140px', height: '30px', borderRadius: '45px', top: '-25px', backgroundColor: 'rgba(36, 62, 113)', display: 'flex', justifyContent: 'center', position: 'absolute', alignContent: 'center', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)' }}>
                      <p style={{ color: 'white', fontSize: '20px', marginTop: '2px' }}>{uiText['filters'][props.uiLang]}</p>
                    </div>
                  </div>
                  <Grid container style={{ justifyContent: 'space-between', marginTop: '10px' }} rowSpacing={2}>
                    {Object.keys(filterFields).map((column) => {
                      return (
                        <Grid item xs={4} key={column}>
                          <TextField
                            variant="outlined"
                            label={uiText[column][props.uiLang]}
                            value={filterFields[column]}
                            id={column}
                            disabled={dataAreLoading || !areFiltersVisible}
                            onChange={(event) => { handleChangeFilterField(event, column) }}
                            size="small"
                            InputLabelProps={{
                              style: { color: '#243E71' },
                            }}
                          />
                        </Grid>
                      )
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: !areFiltersVisible ? '-30px' : '70px',
          alignContent: 'center',
          transition: "all .3s"
        }}>
          <Card
            allDocuments={companyDocuments}
            selectedDocuments={companySelectedDocuments}
            detailFunction={handleOpenDialog}
            clickFunction={handleChangeCheckbox}
            pageFunction={handleChangePage}
            currentPage={companyCurrentPage}
            alignment={alignment}
            filters={filters}
            disableButtons={companyCheckboxesAreDisabled}
            loading={companyDocumentsLoading}
            uiLang={props.uiLang}
            title={'ERP'}
          />
          <Card
            allDocuments={aadeDocuments}
            selectedDocuments={aadeSelectedDocuments}
            clickFunction={handleChangeCheckbox}
            detailFunction={handleOpenDialog}
            pageFunction={handleChangePage}
            currentPage={aadeCurrentPage}
            alignment={alignment}
            filters={filters}
            disableButtons={aadeCheckboxesAreDisabled}
            loading={aadeDocumentsLoading}
            uiLang={props.uiLang}
            title={'AADE'}
          />
        </div>
      </div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={3600}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          variant="filled"
          severity={alertOutcome}
          sx={{ width: "100%", fontSize: 20, fontFamily: "monospace" }}
        >
          {uiText[alertMessage ?? 'no_match_yet_post_partial_connection_success_message'][props.uiLang]}
        </Alert>

      </Snackbar>
    </>
  )
}

export default Match