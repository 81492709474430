import { Fragment, useEffect, useState } from "react";
import { Input } from "@mui/material";

const useStylesSx = {
  inputPlaceholderSx: {
    "&::placeholder": {
      fontSize: 14,
      color: "black",
      fontWeight: "normal",
      fontFamily: "Avenir-Roman",
      opacity: 1,
      textAlign: "center",
    },
  },
  inputStyleSx: {
    // border: "none",
    fontSize: 14,

    minWidth: 60,
    width: "100%",

    textAlign: "center",
    "& input": {
      color: "black",
      textAlign: "center",

    },
  },
  inputDisabledSx: {
    cursor: "not-allowed"
  }
};

export default function SearchInput(props) {
  const { type, onChange, index, reset, disabled } = props;
  const [value, setValue] = useState("");
  // const classesSx = useStylesSx();
  let preValue = value || ""
  const changeValue = (e) => {
    setValue(e.target.value);
    setTimeout(() => {
      if (preValue !== e.target.value) console.log("typing");
      else if (preValue === e.target.value) {
        // console.log("finished typing");
        onChange(e);
      }
    }, 700);
    preValue = e.target.value
  }

  useEffect(() => {
    setValue("");
  }, [reset]);
  return (
    <Fragment>
      <Input
        id={"input" + type + index}
        type={type}
        style={{
          border: "1px solid rgba(170, 184, 187, 0.8)",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          width: "100%",
        }}
        variant="standard"
        size={"small"}
        disabled={disabled}
        disableUnderline
        autoComplete="disabled"
        fullWidth
        // onKeyPress={handleKeypress}
        value={value}
        onChange={(e) => {
          changeValue(e)
          // setValue(e.target.value);
          // onChange(e);
        }}
        sx={{
          ...useStylesSx.inputStyleSx,
          '&.Mui-disabled': useStylesSx.inputDisabledSx,
          '::placeholder': useStylesSx.inputPlaceholderSx,
        }}
      // placeholder={placeholder}
      ></Input>
    </Fragment>
  );
}
