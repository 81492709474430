import { post } from "../../../api"


function PostDocuments(match_type, document_id = null , aade_id = null , documents = []) {
      if (document_id !== null && aade_id !== null)
            // post(`documents//manual_match?erp_document_id=${document_id}&aade_document_id=${aade_id}&match_type=${match_type}`)
            return post(`documents/manual_match?match_type=${match_type}&erp_document_id=${document_id}&aade_document_id=${aade_id}`, [])
      else
            return post(`documents/manual_match?match_type=${match_type}`, documents)
}


export { PostDocuments }

export default PostDocuments;