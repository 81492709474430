import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import FormControl from "@mui/material/FormControl";
import { Grid, TextField } from "@mui/material";
import { useState } from "react";
import enGB from "date-fns/locale/en-GB";

export default function DateRangePicker({ startingDate, endingDate, setStartingDate, setEndingDate, startLabel, endLabel, disabled }) {

  const [view, setView] = useState(null);

  const handleStartDateChange = (newValue) => {

    if (newValue instanceof Date && newValue.getYear() !== startingDate.getYear() && view === 'year') {
      return
    }
    if (newValue instanceof Date && newValue.getYear() >= 50) {
      setStartingDate(newValue);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue instanceof Date && newValue.getYear() !== endingDate.getYear() && view === 'year') {
      return
    }
    if (newValue instanceof Date && newValue.getYear() >= 50) {
      setEndingDate(newValue);
    }
  };

  return (
    <>
      <Grid item container direction="column">
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <FormControl variant="standard" sx={{ width: 155 }}>
            <DesktopDatePicker
              label={startLabel}
              value={startingDate}
              onViewChange={(view) => setView(view)}
              onChange={handleStartDateChange}
              inputFormat={"dd/MM/yyyy"}
              disabled={disabled}
              renderInput={(params) => <TextField {...params} error={startingDate instanceof Date && endingDate instanceof Date && startingDate > endingDate} />}
            />
          </FormControl>
        </LocalizationProvider>
      </Grid>

      <Grid item container direction="column">

        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <FormControl variant="standard" sx={{ width: 155 }}>

            <DesktopDatePicker
              label={endLabel}
              value={endingDate}
              onChange={handleEndDateChange}
              onViewChange={(view) => setView(view)}
              inputFormat={"dd/MM/yyyy"}
              disabled={disabled}
              renderInput={(params) => <TextField {...params} error={startingDate instanceof Date && endingDate instanceof Date && startingDate > endingDate} />}
            />
          </FormControl>
        </LocalizationProvider>

      </Grid>
    </>
  );
}
