export const formatDate = (dateObj) => {
  const tzoffset =
    dateObj instanceof Date
      ? dateObj.getTimezoneOffset() * 60000
      : new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(dateObj - tzoffset);
  return localISOTime?.toISOString().substring(0, 10);
};

export const formatDateTime = (dateObj) => {
  const tzoffset =
    dateObj instanceof Date
      ? dateObj.getTimezoneOffset() * 60000
      : new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(dateObj - tzoffset);
  return localISOTime?.toISOString();
};

export const databaseDatetimeToDate = (dateTimeString) => {
  const [date, _] = dateTimeString.split("T");
  return date;
};

export const formatDateToDatabaseDate = (dateObj) => {
  // Ensure dateObj is a valid Date object
  if (!(dateObj instanceof Date)) {
    return "";
  }

  // Extract individual components of the date
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(dateObj.getDate()).padStart(2, "0");

  // Construct the formatted date string
  const formattedDate = `${year}-${month}-${day} 00:00:00`;

  return formattedDate;
};
