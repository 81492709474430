import { Button, Grid, Skeleton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getUsersColumns } from "../../auth/authAPI";
import { getUserData } from "../../auth/utils";
import uiCustomTable from "../../context/custom_table.json";
import uiText from "../../context/translation.json";
import {
  getAllTheDocuments,
  getDocuments,
  getTotalReport,
  postAadeSelected,
  postColumnSettings,
} from "./documentAPI";
// import { getUsersProfile } from "../../auth/authAPI";
import FormControl from "@mui/material/FormControl";
import Pagination from "@mui/material/Pagination";
import { SiMicrosoftexcel } from "react-icons/si";
import CustomTable from "../../components/CustomTable";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import PopUpWindow from "../../components/PopUpWindow";
import ToggleSwitch from "../../components/ToggleSwitch";
import Tags from "../../context/document_tags.json";
import "./Document.css";

import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  FileUpload,
  Restore,
} from "@mui/icons-material";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import Snackbar from "@mui/material/Snackbar";

import Breadcrump from "../../components/Breadcrump/Breadcrump";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { makeXLSX } from "../../utils/makeXLSX";

const exportAsXLSX = (columns, rows, sheetName, fileName, lang) => {
  const floatColumns = [
    "exchange_rate",
    "net_value",
    "vat_amount",
    "withheld_amount",
    "fees_amount",
    "stamp_duty_amount",
    "other_taxes_amount",
    "deductions_amount",
    "gross_value",
  ];
  const translatedColumns = columns.map((column) => {
    const columnLabel =
      column.label in uiCustomTable
        ? uiCustomTable[column.label][lang]
        : column.label;
    return columnLabel;
  });

  const dataForXLSX = rows.map((document) => {
    const singleRowEntries = columns.map((column) => {
      const cols = column.label.split(".");
      let value =
        cols.length === 1
          ? document[column.label]
          : document[cols[0]] !== null
          ? document[cols[0]][cols[1]]
          : null;
      if (floatColumns.includes(column.label)) {
        value = Number(value?.replace(".", "")?.replace(",", "."));
        value =
          document.record_type === "CREDIT" && column.label !== "exchange_rate"
            ? -value
            : value;
      }
      return [column.label, value];
    });
    return Object.fromEntries(singleRowEntries);
  });
  makeXLSX(translatedColumns, dataForXLSX, sheetName, fileName);
};

// cringe string -> num
const stringNumberToNumber = (str) => {
  return Number(str.replaceAll(".", "").replaceAll(",", "."));
};

// cringe num -> string
const numberToStringNumber = (num) => {
  return num.toLocaleString("el-GR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const formatDate = (dateObj) => {
  const tzoffset =
    dateObj instanceof Date
      ? dateObj.getTimezoneOffset() * 60000
      : new Date().getTimezoneOffset() * 60000;
  const localISOTime = new Date(dateObj - tzoffset);
  return localISOTime?.toISOString().substring(0, 10);
};

const getSummary = (docArray) => {
  const summary = {
    stamp_duty_amount: 0,
    other_taxes_amount: 0,
    fees_amount: 0,
    withheld_amount: 0,
    deductions_amount: 0,
    total_documents: 0,
    final_net_amount: 0,
    net_amount: 0,
    vat_amount: 0,
    total_amount: 0,
  };

  docArray.forEach((doc) => {
    const multiplier = doc.record_type === "CREDIT" ? -1 : 1;
    // tax stuff
    summary.stamp_duty_amount +=
      multiplier * stringNumberToNumber(doc.stamp_duty_amount ?? "0,00");
    summary.other_taxes_amount +=
      multiplier * stringNumberToNumber(doc.other_taxes_amount ?? "0,00");
    summary.fees_amount +=
      multiplier * stringNumberToNumber(doc.fees_amount ?? "0,00");
    summary.withheld_amount +=
      multiplier * stringNumberToNumber(doc.withheld_amount ?? "0,00");
    summary.deductions_amount +=
      multiplier * stringNumberToNumber(doc.deductions_amount ?? "0,00");

    // final_net_amount
    summary.total_documents += 1;
    summary.final_net_amount +=
      multiplier *
      (stringNumberToNumber(doc.net_value ?? "0,00") +
        // - stringNumberToNumber(doc.withheld_amount ?? '0,00')
        stringNumberToNumber(doc.fees_amount ?? "0,00") +
        stringNumberToNumber(doc.stamp_duty_amount ?? "0,00") +
        stringNumberToNumber(doc.other_taxes_amount ?? "0,00"));
      // - stringNumberToNumber(doc.deductions_amount ?? '0,00')
    summary.net_amount +=
      multiplier * stringNumberToNumber(doc.net_value ?? "0,00");
    summary.vat_amount +=
      multiplier * stringNumberToNumber(doc.vat_amount ?? "0,00");
    summary.total_amount +=
      multiplier * stringNumberToNumber(doc.gross_value ?? "0,00");
  });

  // cringe num -> string
  for (const property in summary) {
    if (property !== "total_documents") {
      summary[property] = numberToStringNumber(summary[property]);
    }
  }

  return summary;
};

const useStylesSx = {
  synopsisButtonSx: {
    cursor: "pointer",
    width: 40,
    zIndex: 10,
    height: 200,
    position: "fixed",
    top: "60%",
    right: 0,
    backgroundColor: "#243e71",
    color: "white",
    textTransform: "none",
  },
};

/* 
This component is cursed. Don't even bother.
*/
const Documents = (props) => {
  // const classesSx = useStylesSx();
  const [documents, setDocuments] = useState([]);
  const [columns, setColumns] = useState([]);
  const { category, status } = useParams();
  const { search: searchString } = useLocation();
  const [totalPages, setTotalPage] = useState(0);
  const [totalReport, setTotalReport] = useState(null);
  const [selectedReport, setSelectedReport] = useState({
    total_documents: 0,
    net_amount: 0,
    vat_amount: 0,
    total_amount: 0,
    rest_sums_amount: 0,
  });
  const [changeReport, setChangeReport] = useState(false);
  const [NotificationState, setNotificationState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [allreadySelected, setAllreadySelected] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [searchTerms, setSearchTerms] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [statuses, setStatuses] = useState([]);

  const [dateHalt, setDateHalt] = useState(true);

  const [filtersActive, setFiltersActive] = useState(false);
  const [filteredDocs, setFilteredDocs] = useState([]);
  const searchParams = useMemo(
    () => Object.fromEntries(new URLSearchParams(searchString)),
    [searchString]
  );
  const vat_number_keys =
    Object.keys(searchParams).length > 0 && "vat_number_keys" in searchParams
      ? [searchParams.vat_number_keys]
      : undefined;

  const user = getUserData();
  const userAuthArray = user?.job_actions ?? [];

  useEffect(() => {
    if (status) {
      props.setIsActive(!props.isActive);
      setAllreadySelected(
        props.selectedDocumentsParam &&
          props.selectedDocumentsParam[category][status].map((cache_doc) => {
            return cache_doc.id;
          })
      );
      if (props.selectedDocumentsParam) {
        let newReport = {
          total_documents: 0,
          net_amount: 0,
          vat_amount: 0,
          total_amount: 0,
          rest_sums_amount: 0,
        };
        props.selectedDocumentsParam[category][status].forEach((doc) => {
          newReport.total_documents += 1;
          newReport.net_amount += Number(
            doc?.net_value.replace(".", "").replace(",", ".")
          );
          newReport.vat_amount += Number(
            doc?.vat_amount.replace(".", "").replace(",", ".")
          );
          newReport.total_amount += Number(
            doc?.gross_value.replace(".", "").replace(",", ".")
          );
          newReport.rest_sums_amount +=
            Number(doc?.other_taxes_amount.replace(".", "").replace(",", ".")) +
            Number(doc?.fees_amount.replace(".", "").replace(",", ".")) +
            Number(doc?.deductions_amount.replace(".", "").replace(",", ".")) +
            Number(doc?.stamp_duty_amount.replace(".", "").replace(",", "."));
          Number(doc?.withheld_amount.replace(".", "").replace(",", "."));
        });
        newReport.net_amount = newReport.net_amount.toLocaleString("el-GR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        newReport.vat_amount = newReport.vat_amount.toLocaleString("el-GR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        newReport.total_amount = newReport.total_amount.toLocaleString(
          "el-GR",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        newReport.rest_sums_amount = newReport.rest_sums_amount.toLocaleString(
          "el-GR",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        );
        setSelectedReport(newReport);
      }
    }
  }, [props.selectedDocumentsParam, category, status]);

  const startDateRequest = formatDate(props.startingDate);
  const endDateRequest = formatDate(props.endingDate);

  const getTotalReports = (withDates) => {
    props.setStatus(status);
    props.setCategory(category);
    // console.log(category);
    let bodyStatuses = [];
    // console.log(status);
    if (category === "income") {
      bodyStatuses = props.incomeRoute.filter((route) => {
        return route.link.includes(status);
      });
      // console.log(bodyStatuses[0].status);
      setStatuses(bodyStatuses[0].status);
    }
    if (category === "expenses") {
      bodyStatuses = props.expensesRoute.filter((route) => {
        return route.link.includes(status);
      });

      setStatuses(bodyStatuses[0].status);
    }
    if (category === "other") {
      bodyStatuses = props.othersRoute.filter((route) => {
        return route.link.includes(status);
      });

      setStatuses(bodyStatuses[0].status);
    }

    getTotalReport(
      props.filters,
      bodyStatuses[0].status,
      vat_number_keys,
      category,
      props.company,
      withDates ? startDateRequest : "",
      withDates ? endDateRequest : ""
    )
      .then((response) => {
        // console.log(response); //#
        let tpages = Math.floor(response.data?.total_count / props.rowsPerPage);
        tpages += response.data?.total_count % props.rowsPerPage !== 0 ? 1 : 0;
        setTotalPage(tpages);
        // console.log(tpages);
        let total = {
          total_documents: 0,
          net_amount: "",
          vat_amount: "",
          total_amount: "",
          rest_sums_amount: 0,
          total_documents_dates_from: "",
          total_documents_dates_to: "",
        };
        total.total_documents = response.data?.total_count;
        total.total_amount = response.data?.total_gross_value;
        total.net_amount = response.data?.total_net_value;
        total.vat_amount = response.data?.total_vat_amount;
        total.total_documents_dates_from = response.data?.total_date_from;
        total.total_documents_dates_to = response.data?.total_date_to;
        total.rest_sums_amount = (
          Number(
            response.data?.total_deductions_amount
              .replace(".", "")
              .replace(",", ".")
          ) +
          Number(
            response.data?.total_fees_amount.replace(".", "").replace(",", ".")
          ) +
          Number(
            response.data?.total_other_taxes_amount
              .replace(".", "")
              .replace(",", ".")
          ) +
          Number(
            response.data?.total_stamp_duty_amount
              .replace(".", "")
              .replace(",", ".")
          ) +
          Number(
            response.data?.total_withheld_amount
              .replace(".", "")
              .replace(",", ".")
          )
        ).toLocaleString("el-GR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        setTotalReport(total);
        setDateHalt(true);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      props.company !== null &&
      props.startingDate instanceof Date &&
      props.endingDate instanceof Date &&
      props.startingDate <= props.endingDate
    ) {
      getTotalReports(true);
      getAllDocuments(true);
    }
  }, [category, status, props.startingDate, props.endingDate, props.company]);

  const getAllDocuments = (withDates = false) => {
    props.setIsActive(!props.isActive);
    props.setStatus(status);
    props.setCategory(category);
    let bodyStatuses = [];
    if (category === "income") {
      bodyStatuses = props.incomeRoute.filter((route) => {
        return route.link.includes(status);
      });
      setStatuses(bodyStatuses[0].status);
    }
    if (category === "expenses") {
      bodyStatuses = props.expensesRoute.filter((route) => {
        return route.link.includes(status);
      });
      setStatuses(bodyStatuses[0].status);
    }
    if (category === "other") {
      bodyStatuses = props.othersRoute.filter((route) => {
        return route.link.includes(status);
      });
      setStatuses(bodyStatuses[0].status);
    }
    props.setCurrentPage(1);
    props.setRowsPerPage(20);
    setDocuments([]);
    setAllDocs([]);
    setLoadingTable(true);
    setLoadingDocs(true);
    // someone might say "hey you already have loading state. Why you need another?". Answer is I DON'T CARE.
    props.setGlobalSomethingLoading(true);
    getDocuments(
      props.filters,
      bodyStatuses[0].status,
      vat_number_keys,
      undefined,
      category,
      props.company,
      withDates ? startDateRequest : "",
      withDates ? endDateRequest : "",
      props.currentPage,
      props.rowsPerPage
    )
      .then((response) => {
        let documents = [];
        // console.log(response); //#
        response.data.forEach(function (document) {
          document.document_tags_initial = document.document_tags;
          document.document_flags_initial = document.document_flags;
          document.match_type_initial = document.match_type;
          document.document_tags =
            document.document_tags &&
            (document.document_tags in Tags
              ? Tags[document.document_tags][props.uiLang]
              : document.document_tags);
          let partners = document.partner;
          // document.invoice_credit =
          //   document.invoice_credit === "INVOICE"
          //     ? uiText.ui_invoice[props.uiLang]
          //     : uiText.ui_credit[props.uiLang];
          document.origin_status_initial = document.origin_status;
          document.origin_status = document.origin_status =
            document.origin_status === "ACTIVE"
              ? uiText.origin_status_active[props.uiLang]
              : uiText.origin_status_cancelled[props.uiLang];
          // if (document.aade_invoice_type) {
          //   document.aade_invoice_type_description =
          //     invoiceType[document.aade_invoice_type][props.uiLang];
          // }
          document.document_status_initial = document.document_status;
          document.document_status =
            uiText?.[document.document_status]?.[props.uiLang];
          //Gia allagh
          documents.push({ ...document, partners: partners });
        });
        setDocuments(documents);
        setAllDocs(documents);
        setFilteredDocs(documents);
        setLoadingTable(false);
        let allTheDocs = [];
        getAllTheDocuments(
          props.filters,
          bodyStatuses[0].status,
          vat_number_keys,
          undefined,
          category,
          props.company,
          withDates ? startDateRequest : "",
          withDates ? endDateRequest : ""
        )
          .then((response) => {
            const docs = response.data;
            let count = 0;
            let docums = [];
            docs.forEach(function (document) {
              count++;
              let partners = document.partner;
              document.document_tags_initial = document.document_tags;
              document.document_tags =
                document.document_tags &&
                (document.document_tags in Tags
                  ? Tags[document.document_tags][props.uiLang]
                  : document.document_tags);
              document.origin_status_initial = document.origin_status;
              document.origin_status = document.origin_status =
                document.origin_status === "ACTIVE"
                  ? uiText.origin_status_active[props.uiLang]
                  : uiText.origin_status_cancelled[props.uiLang];
              document.document_status_initial = document.document_status;
              document.document_status =
                uiText?.[document?.document_status]?.[props.uiLang];

              document.document_flags_initial = structuredClone(
                document.document_flags
              );
              document.match_type_initial = document.match_type;
              // console.log(document.match_type_initial)
              document.match_type =
                uiText[document.match_type_initial] != null
                  ? uiText[document.match_type_initial][props.uiLang]
                  : document.match_type_initial;

              for (let entry in document.document_flags_initial)
                if (uiText[document.document_flags[entry]] != null)
                  document.document_flags[entry] =
                    uiText[document.document_flags_initial[entry]][
                      props.uiLang
                    ];
              //Gia allagh
              docums.push({ ...document, partners: partners });
            });
            docums.forEach((doc) => allTheDocs.push(doc));
            setAllDocs(allTheDocs);
            setFilteredDocs(allTheDocs);
            setLoadingDocs(false);
            props.setGlobalSomethingLoading(false);
          })
          .catch((error) => {
            setAllDocs([]);
            setLoadingDocs(false);
            props.setGlobalSomethingLoading(false);
          });
      })
      .catch(() => {
        setDocuments([]);
        props.setCurrentPage(1);
        setTotalPage(1);
        setLoadingTable(false);
        setLoadingDocs(false);
        props.setGlobalSomethingLoading(false);
        setTotalReport({
          total_documents: "0",
          net_amount: "0,00",
          vat_amount: "0,00",
          total_amount: "0,00",
          rest_sums_amount: "0,00",
        });
      });
  };

  // CHANGE LANGUAGE REFRESH DOCS
  useEffect(() => {
    // setLoadingTable(true);
    let docums = [];
    let allTheDocs = [];
    allDocs.forEach(function (document) {
      document.document_tags =
        document.document_tags_initial &&
        (document.document_tags_initial in Tags
          ? Tags[document.document_tags_initial][props.uiLang]
          : document.document_tags_initial);
      let partners = document.partner;
      document.origin_status = document.origin_status =
        document.origin_status_initial === "ACTIVE"
          ? uiText.origin_status_active[props.uiLang]
          : uiText.origin_status_cancelled[props.uiLang];
      document.document_status =
        uiText?.[document.document_status_initial]?.[props.uiLang];
      document.match_type =
        uiText[document.match_type_initial] != null
          ? uiText[document.match_type_initial][props.uiLang]
          : document.match_type_initial;
      document.document_flags = structuredClone(
        document.document_flags_initial
      );
      for (let entry in document.document_flags_initial)
        if (uiText[document.document_flags_initial[entry]] != null)
          document.document_flags[entry] =
            uiText[document.document_flags_initial[entry]][props.uiLang];
      docums.push({ ...document, partners: partners });
    });
    docums.forEach((doc) => allTheDocs.push(doc));
    setAllDocs(allTheDocs);
    const start = (props.currentPage - 1) * props.rowsPerPage;
    const end = start + props.rowsPerPage;

    setDocuments(allTheDocs.slice(start, end));
    // setLoadingTable(false);
  }, [props.uiLang]);

  useEffect(() => {
    // setDateHalt(false);
    if (status !== undefined) {
      getUsersColumns(`${category}_${status}`).then((response) => {
        const idCol = {
          is_visible: false,
          label: "id",
          name: "id",
          order_index: 1,
          view: "income_to_send",
        };
        const cols = [idCol, ...response.data];
        setColumns(cols);
        setSearchTerms(() => {
          let terms = {};
          response.data.forEach((column) => (terms[column.label] = ""));

          // console.log(terms);
          return terms;
        });
      });
    } else {
      getUsersColumns(`${category}`).then((response) => {
        const idCol = {
          is_visible: false,
          label: "id",
          name: "id",
          order_index: 1,
          view: "income_to_send",
        };
        const cols = [idCol, ...response.data];
        setColumns(cols);
        setSearchTerms(() => {
          let terms = {};
          response.data.forEach((column) => (terms[column.label] = ""));
          return terms;
        });
      });
    }
  }, [category, status, props.company]);

  useEffect(() => {
    const start = (props.currentPage - 1) * props.rowsPerPage;
    const end = start + props.rowsPerPage;
    if (filteredDocs.length > 0) {
      setDocuments(filteredDocs.slice(start, end));
      let tpages = Math.floor((filteredDocs.length - 1) / props.rowsPerPage);
      tpages += (filteredDocs.length - 1) % props.rowsPerPage !== 0 ? 1 : 0;
      setTotalPage(tpages);
    } else if (filteredDocs.length == 0) {
      setDocuments([]);
      setTotalPage(1);
    }
  }, [props.currentPage, props.rowsPerPage, filteredDocs]);

  const refreshDocuments = () => {
    getTotalReports(true);
    getAllDocuments(true);
  };

  const handleFilterChange = (new_filters) => {
    let filteredDocs = [...allDocs];
    for (const filter in new_filters) {
      if (new_filters[filter] !== "" && new_filters[filter].length > 0) {
        props.setCurrentPage(1);
        setFiltersActive(true);
        filter.split(".").length > 1
          ? (filteredDocs = filteredDocs.filter((doc) => {
              const isExclusive = new_filters[filter].includes("!=");
              let term = isExclusive
                ? new_filters[filter].trim().slice(2)
                : new_filters[filter].trim();
              if (
                doc[filter.split(".")[0]] &&
                doc[filter.split(".")[0]][filter.split(".")[1]] &&
                ((!isExclusive &&
                  doc[filter.split(".")[0]][filter.split(".")[1]]
                    .toLowerCase()
                    .includes(term.trim().toLowerCase())) ||
                  (isExclusive &&
                    !doc[filter.split(".")[0]][filter.split(".")[1]]
                      .toLowerCase()
                      .includes(term.trim().toLowerCase())))
              ) {
                return doc;
              }
            }))
          : (filteredDocs = filteredDocs.filter((doc) => {
              const isExclusive = new_filters[filter].includes("!=");
              // text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
              let term = isExclusive
                ? new_filters[filter].trim().slice(2)
                : new_filters[filter].trim();
              // voodoo: https://stackoverflow.com/questions/23346506/javascript-normalize-accented-greek-characters
              const normalizedTerm = term
                .trim()
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "");
              const isGreek = props.uiLang === "el";

              if (
                doc[filter] &&
                typeof doc[filter] === "string" &&
                ((!isExclusive &&
                  doc[filter]
                    .toLowerCase()
                    .includes(term.trim().toLowerCase())) ||
                  (isExclusive &&
                    !doc[filter]
                      .toLowerCase()
                      .includes(term.trim().toLowerCase())))
              ) {
                return doc;
              }
              if (
                doc[filter] !== null &&
                typeof doc[filter] === "boolean" &&
                ((!isExclusive &&
                  ((isGreek && normalizedTerm === "ναι") ||
                    (!isGreek && normalizedTerm === "yes")) &&
                  doc[filter] === true) ||
                  (((isGreek && normalizedTerm === "οχι") ||
                    (!isGreek && normalizedTerm === "no")) &&
                    doc[filter] === false))
              ) {
                return doc;
              }
            }));
      }
    }

    setFilteredDocs(filteredDocs);
  };

  const onChangeColumnVisibility = useCallback(
    (changedColumn, action) => {
      postColumnSettings(changedColumn, `${category}_${status}`, action).then(
        (response) => {
          getUsersColumns(`${category}_${status}`).then((response) => {
            const idCol = {
              is_visible: false,
              label: "id",
              name: "id",
              order_index: 1,
              view: "income_to_send",
            };
            const cols = [idCol, ...response.data];
            setColumns(cols);
          });
        }
      );
    },
    [category, status]
  );
  const handleChangeCompany = (event) => {
    // console.log(event.target.value)
    props.onCompanyChange(event.target.value);
  };

  const selectAllDocs = () => {
    // console.log("select allDocs");
    let to_update = { ...props.selectedDocumentsParam };

    allDocs.forEach((doc) => {
      let does_contain = false;
      to_update[category][status].forEach((cache_doc) => {
        if (cache_doc.id === doc.id) does_contain = true;
      });
      if (!does_contain) to_update[category][status].push(doc);
    });
    props.onSelectedDocumentUpdate(to_update);
  };
  const cancelSelectAllDocs = () => {
    props.selectedDocumentsParam[category][status] = [];
    props.onSelectedDocumentUpdate({
      income: {
        all: [],
        deficiencies: [],
        to_send: [],
        to_cancel: [],
        sent: [],
        to_correct: [],
        cancelled: [],
        retrieved_by_aade: [],
        issuer_deviation: [],
        issuer_omitment: [],
        counterpart_rejection: [],
        counterpart_deviation: [],
        ignored: [],
      },
      expenses: {
        all: [],
        deficiencies: [],
        no_mark_yet: [],
        retrieved_by_aade: [],
        no_greek_supplier: [],
        to_send: [],
        to_cancel: [],
        sent: [],
        to_correct: [],
        cancelled: [],
        ignored: [],
      },
    });
  };
  const onDocumentSelect = useCallback((Arrayid, action) => {
    // console.log("docs onDocSelect");
    let to_update = { ...props.selectedDocumentsParam };
    if (action) {
      Arrayid.forEach((doc) => {
        let does_contain = false;
        to_update[category][status].forEach((cache_doc) => {
          if (cache_doc.id === doc.id) does_contain = true;
        });
        if (!does_contain) to_update[category][status].push(doc);
      });
    } else
      to_update[category][status] = to_update[category][status].filter(
        (cache_doc) => {
          let does_contain = true;
          Arrayid.forEach((doc) => {
            if (cache_doc.id === doc.id) does_contain = false;
          });
          return does_contain;
        }
      );

    props.onSelectedDocumentUpdate(to_update);
  });
  const resetDates = () => {
    setDateHalt(false);
    props.setStartingDate(props.defaultStartingDate);
    props.setEndingDate(props.defaultEndingDate);
  };
  const handleChangeReport = (check) => setChangeReport(check);

  const handlePageChange = (event) => {
    // console.log(props);
    props.setCurrentPage(event.target.innerText);
  };
  const goPreviousPage = () => {
    const nowPage = props.currentPage;
    if (nowPage > 1) props.setCurrentPage(parseInt(nowPage) - 1);
  };
  const goNextPage = () => {
    const nowPage = props.currentPage;
    if (nowPage < totalPages) props.setCurrentPage(parseInt(nowPage) + 1);
  };
  const handleRowsPerPageChange = (event) => {
    props.setRowsPerPage(event.target.value);
    props.setCurrentPage(1);
    // console.log(totalReport);
  };

  const onSendSelected = (event) => {
    // console.log(props.selectedDocumentsParam[category][status]);
    postAadeSelected(props.selectedDocumentsParam[category][status]).then(
      (response) => {
        props.selectedDocumentsParam[category][status] = [];
        props.onSelectedDocumentUpdate(props.selectedDocumentsParam);
        setNotificationState(true);
        setLoadingTable(true);
        setTimeout(() => {
          setNotificationState(false);
          refreshDocuments();
        }, 3000);
      }
    );
  };
  //postAadeSelected(props.selectedDocumentsParam[category][status]).then((response) => window.confirm('Are you sure you want to save this thing into the database?'));
  const onSendAll = () => {
    //  onDocumentSelect(allDocs);
    postAadeSelected(allDocs).then((response) => {
      setNotificationState(true);
      setLoadingTable(true);
      refreshDocuments();
      setTimeout(() => setNotificationState(false), 3000);
    });
  };
  const openSynopsis = (e) => {
    // console.log(e);
    setAnchorEl(e.currentTarget);
  };
  const closeSynopsis = () => {
    setAnchorEl(null);
  };

  // const filteredSummary = getSummary(filteredDocs)
  // const selectedSummary = getSummary(props.selectedDocumentsParam[category][status])
  const filteredSummary = useMemo(
    () => getSummary(filteredDocs),
    [filteredDocs]
  );
  const selectedSummary = useMemo(
    () => getSummary(props.selectedDocumentsParam[category][status]),
    [props.selectedDocumentsParam]
  );
  //postAadeAll(props.filters, statuses, category, props.company, props.startingDate, props.endingDate).then((response) => console.log(response)).catch((error) => console.log(error));

  const sendButtonIsVisible =
    status === "to_send" &&
    ((category === "expenses" &&
      userAuthArray.includes("TRANSMIT_AADE_EXPENSES")) ||
      (category === "income" &&
        userAuthArray.includes("TRANSMIT_AADE_INCOME")));

  return (
    <>
      <Box
        direction={"column"}
        sx={useStylesSx.synopsisButtonSx}
        // component={Button}
        onClick={(e) => {
          openSynopsis(e);
        }}
        aria-controls="simple-menu"
      >
        <FileUpload
          style={{
            fontSize: 30,
            marginBottom: 40,
            marginTop: 40,
            marginLeft: 5,
            transform: "rotate(-90deg)",
          }}
        />
        <Typography
          style={{
            transform: "rotate(-90deg",
          }}
        >
          {uiText.summary[props.uiLang]}
        </Typography>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeSynopsis}
        disableScrollLock
        // MenuListProps={{ onMouseLeave: closeSynopsis }}
        anchororigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // transformOrigin={{
        //   vertical: "top",
        //   horizontal: "center",
        // }}
      >
        <Grid container style={{ width: 600 }}>
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={12}
            // className="amountDetailsView"
            justifyContent={"center"}
          >
            <Typography style={{ fontSize: 24 }}>
              {uiText.summary[props.uiLang]}
            </Typography>
          </Grid>
          <Divider style={{ height: 3, backgroundColor: "#243e71" }} />
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_stamp_duty_amount[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.stamp_duty_amount
                  : selectedSummary.stamp_duty_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_total_documents[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.total_documents
                  : selectedSummary.total_documents)}
            </p>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_other_taxes_amount[props.uiLang]}</p>{" "}
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.other_taxes_amount
                  : selectedSummary.other_taxes_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_final_net_amount[props.uiLang]}</p>{" "}
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.final_net_amount
                  : selectedSummary.final_net_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_fees_amount[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.fees_amount
                  : selectedSummary.fees_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_net[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.net_amount
                  : selectedSummary.net_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_withheld_amount[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.withheld_amount
                  : selectedSummary.withheld_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_vat[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.vat_amount
                  : selectedSummary.vat_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_deductions_amount[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.deductions_amount
                  : selectedSummary.deductions_amount)}
            </p>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            lg={6}
            className="amountDetailsView"
          >
            <p>{uiText.summary_gross[props.uiLang]}</p>
            <p className="BoldFonts">
              {totalReport &&
                (!changeReport
                  ? filteredSummary.total_amount
                  : selectedSummary.total_amount)}
            </p>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <ToggleSwitch uiLang={props.uiLang} onToggle={handleChangeReport} />
          </Grid>
        </Grid>
      </Menu>

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        style={{
          // paddingRight: props.showMenuLabels ? 40 + props.drawerWidth : 40,
          position: "fixed",
          left: props.showMenuLabels ? 110 + props.drawerWidth : 110,
          top: 70,
        }}
      >
        <Grid
          item
          container
          display={"flex"}
          alignItems={"center"}
          position={"relative"}
          justifyContent={"space-between"}
        >
          <Breadcrump
            category={category}
            status={status.toUpperCase()}
            uiLang={props.uiLang}
          />
          <Grid
            item
            justifyContent={"center"}
            alignItems={"center"}
            // direction="row"
            display={"flex"}
            style={{
              paddingLeft: 100,
              position: "relative",
            }}
          >
            <Button onClick={resetDates} disabled={loadingDocs}>
              <Typography
                style={{
                  color: "#243e71",
                  wordWrap: "normal",
                  textTransform: "none",
                }}
              >
                {uiText.ui_reset_dates[props.uiLang]}
              </Typography>
              <IconButton>
                <Restore />
              </IconButton>
            </Button>
          </Grid>
          <Grid
            item
            style={{
              marginLeft: "auto",
              paddingRight: props.showMenuLabels
                ? 155 + props.drawerWidth
                : 155,
            }}
            display={"flex"}
            // direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item>
              <button
                className="CustomTableButtons"
                onClick={() =>
                  exportAsXLSX(
                    columns,
                    filteredDocs,
                    `${category} ${status}`,
                    "MyDataEaseExport.xlsx",
                    props.uiLang
                  )
                }
              >
                <SiMicrosoftexcel fontSize="40px" />
              </button>
            </Grid>
            <Grid item>
              {sendButtonIsVisible && (
                <PopUpWindow
                  selectAllDocs={selectAllDocs}
                  cancelSelectAllDocs={cancelSelectAllDocs}
                  uiLang={props.uiLang}
                  nameChosen={uiText.option_send_selected[props.uiLang]}
                  nameAll={uiText.option_send_all[props.uiLang]}
                  className="ControlButton"
                  selectedReport={selectedSummary}
                  func_to_executeSelected={() => {
                    onSendSelected();
                  }}
                  func_to_executeAll={onSendAll}
                  nowrap={true}
                  // IconName="GoFileSymlinkFile"
                  variant="contained"
                  hasSelectedDocs={
                    props.selectedDocumentsParam[category][status] &&
                    props.selectedDocumentsParam[category][status].length > 0
                  }
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        style={{
          paddingRight: props.showMenuLabels ? 155 + props.drawerWidth : 155,
          position: "fixed",
          left: props.showMenuLabels ? 110 + props.drawerWidth : 110,
          top: 140,
        }}
      >
        <Grid
          item
          container
          spacing={2}
          display={"flex"}
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          xs={4}
        >
          <Grid item container direction="column">
            <Typography style={{ color: "#243e71" }}>
              {uiText.ui_company[props.uiLang]}
            </Typography>
            <FormControl variant="standard" sx={{ width: 250 }}>
              <Select
                labelId="demo-simple-select-label-company"
                id="demo-simple-select"
                value={props.company}
                disabled={loadingDocs}
                onChange={handleChangeCompany}
                label={uiText.ui_company[props.uiLang]}
                autoWidth
              >
                {props.companies.map((comp) => (
                  <MenuItem key={comp.value} value={comp.value}>
                    {comp.text}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <DateRangePicker
            startingDate={props.startingDate}
            endingDate={props.endingDate}
            setStartingDate={props.setStartingDate}
            setEndingDate={props.setEndingDate}
            startLabel={uiText.ui_from[props.uiLang]}
            endLabel={uiText.ui_to[props.uiLang]}
            disabled={loadingDocs}
          />
        </Grid>

        {!loadingDocs && (
          <Grid
            item
            style={{ marginLeft: "auto" }}
            display={"flex"}
            // direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <IconButton>
              <ArrowBackIosOutlined onClick={goPreviousPage} />
            </IconButton>
            <Pagination
              size="small"
              page={Number(props.currentPage)}
              count={totalPages}
              color="primary"
              onClick={handlePageChange}
              hideNextButton
              hidePrevButton
              // boundaryCount={1}
              // siblingCount={1}
            />
            <IconButton>
              <ArrowForwardIosOutlined onClick={goNextPage} />
            </IconButton>
            <div style={{ marginLeft: 20 }}>
              <FormControl variant="standard" sx={{ minWidth: 80 }}>
                <Typography style={{ color: "#243e71" }}>
                  {uiText.series_p[props.uiLang]}
                </Typography>

                <Select
                  className="RowsPerPageSelect"
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={props.rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  autoWidth
                  label="Rows Per Page"
                >
                  <MenuItem value={10} className="RowsPerPageMenu" defaultValue>
                    10
                  </MenuItem>
                  <MenuItem value={20} className="RowsPerPageMenu">
                    20
                  </MenuItem>
                  <MenuItem value={50} className="RowsPerPageMenu">
                    50
                  </MenuItem>
                  <MenuItem value={100} className="RowsPerPageMenu">
                    100
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        display={"flex"}
        style={{
          position: "fixed",
          left: props.showMenuLabels ? 110 + props.drawerWidth : 110,
          top: 210,
        }}
      >
        <Grid
          item
          style={{
            zIndex: 9,
            // position: "absolute",
            backgroundColor: "transparent",
            // right: "100px",
            // top: "-30px",
          }}
        >
          <p>
            {uiText.ui_already_selected_prefix[props.uiLang]}
            <span className="BoldFonts">
              {allreadySelected && allreadySelected.length}
            </span>{" "}
            {uiText.ui_already_selected_suffix[props.uiLang]}
          </p>
        </Grid>
      </Grid>

      {loadingTable && (
        <Grid
          justifyContent={"flex-start"}
          alignItems={"center"}
          direction={"row"}
          container
          spacing={2}
          style={{
            position: "fixed",
            width: "100%",
            display: "flex",
            left: props.showMenuLabels ? 110 + props.drawerWidth : 110,
            top: "30%",
          }}
        >
          {[...Array(37).keys()].map((cell) => {
            return (
              <Grid item key={cell}>
                <Grid container spacing={2} direction="column">
                  {[...Array(37).keys()].map((cell, j) => {
                    return (
                      <Grid item key={cell.toString() + j + cell}>
                        <Skeleton
                          variant="rectangular"
                          width={100}
                          height={30 + 2 * j}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}
      {!loadingTable && (
        <CustomTable
          uiLang={props.uiLang}
          showSearch={true}
          disabled={loadingDocs}
          searchTerms={searchTerms}
          setSearchTerms={setSearchTerms}
          showMenuLabels={props.showMenuLabels}
          drawerWidth={props.drawerWidth}
          columns={columns.map((column) => {
            // console.log(column.label)
            // console.log(uiText[column.label][props.uiLang])
            let columnLabel;
            try {
              columnLabel = uiCustomTable[column.label][props.uiLang];
            } catch (e) {
              columnLabel = column.label;
            }
            return {
              name: column.label,
              // label: column.label,
              label: columnLabel,
              options: {
                width: "auto",
                filter: column.label === "document_date" ? false : true,
                sort: true,
                display: column.is_visible,
                filter_display: true,
              },
            };
          })}
          data={documents}
          onFilterChange={handleFilterChange}
          options={{
            checkbox_visible: true,
            details_visible: true,
            showColumnSetting: true,
          }}
          filters={props.filters}
          onColumnSettingChange={onChangeColumnVisibility}
          allreadySelected={allreadySelected}
          category={category}
          status={status}
          onDocumentSelect={onDocumentSelect}
        />
      )}

      <Snackbar
        open={NotificationState}
        autoHideDuration={3600}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotificationState(false);
          }}
          variant="filled"
          severity="success"
          sx={{ width: "100%", fontSize: 20, fontFamily: "monospace" }}
        >
          {uiText.ui_send_to_aade[props.uiLang]}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Documents;
