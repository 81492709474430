import './dashboard.css';
import React, { useState, useEffect, useMemo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIosOutlined from "@mui/icons-material/ArrowBackIosOutlined";
import ArrowForwardIosOutlined from "@mui/icons-material/ArrowForwardIosOutlined";
import IconButton from "@mui/material/IconButton";
import { getDashboardData } from './dashboardAPI';
import { formatDateTime } from "../../utils/formatDate";
import uiText from "../../context/translation.json";

const fetchDashboardData = (company, setIncomeData, setExpensesData, setLoading, startOfMonthDate, endOfMonthDate) => {
    Promise.all([
        getDashboardData('status_summary', company, startOfMonthDate, endOfMonthDate, 'INCOME'),
        getDashboardData('status_summary', company, startOfMonthDate, endOfMonthDate, 'EXPENSES'),
    ])
        .then((responses) => {
            setIncomeData(responses[0].data);
            setExpensesData(responses[1].data);
        })
        .catch((error) => {
        })
        .finally(() => {
            setLoading(false);
        });
};

function StyledCard({ data, uiLang }) {
    return (
        <Card sx={{ bgcolor: '#f5f5f5', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', m: 1, p: 0, width: 300 }}>
            <CardContent sx={{ p: 1, '&:last-child': { paddingBottom: '8px' } }}>
                <Typography variant="h6" color="#243e71" gutterBottom sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {uiText?.[data.document_status]?.[uiLang]}
                </Typography>
                <Typography variant="h5">
                    {data.count_erp}
                </Typography>
                <Typography>
                    {uiText["net_amount"][uiLang]}: {data.net_erp.toLocaleString(uiLang, { style: 'currency', currency: 'EUR' })}
                    <br />
                    {uiText["vat_amount"][uiLang]}: {data.vat_erp.toLocaleString(uiLang, { style: 'currency', currency: 'EUR' })}
                    <br />
                    {uiText["total_value"][uiLang]}: {data.gross_erp.toLocaleString(uiLang, { style: 'currency', currency: 'EUR' })}
                    <br />
                </Typography>
            </CardContent>
        </Card>
    );
}

function Dashboard(props) {
    const { appLocale, company, companies, uiLang, onCompanyChange } = props;
    const localeMap = { en: "en-US", el: "el-GR" };
    const [loading, setLoading] = useState(true);
    const [incomeData, setIncomeData] = useState(null);
    const [expensesData, setExpensesData] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const { startOfMonth, endOfMonth } = useMemo(() => {
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startDate.setHours(0, 0, 0, 0);
        // console.log(startDate);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        endDate.setHours(23, 59, 59, 999);
        // console.log(endDate);
        return { startOfMonth: startDate, endOfMonth: endDate };
    }, [currentDate]);
    const startOfMonthString = startOfMonth.toLocaleDateString(localeMap[uiLang], { day: '2-digit', month: 'short', year: '2-digit' })
    const endOfMonthString = endOfMonth.toLocaleDateString(localeMap[uiLang], { day: '2-digit', month: 'short', year: '2-digit' })

    const handleChangeCompany = (event) => {
        setLoading(true);
        onCompanyChange(event.target.value);
    };
    const changeMonth = (delta) => {
        setCurrentDate(prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() + delta, 1));
    };

    useEffect(() => {
        // console.log('rendering...')
        setLoading(true)
        if (company) {
            const startOfMonthDate = formatDateTime(startOfMonth);
            // console.log(startOfMonthDate);
            const endOfMonthDate = formatDateTime(endOfMonth);
            // console.log(endOfMonthDate);
            fetchDashboardData(company, setIncomeData, setExpensesData, setLoading, startOfMonthDate, endOfMonthDate);
        }
    }, [company, startOfMonth, endOfMonth]);

    return (
        <>
            {companies && (
                <Grid container alignItems="center" direction="row" sx={{ pl: 10, pb: 5 }}>
                    <Grid item >
                        <Typography sx={{ color: "#243e71" }}>
                            {uiText.ui_company[uiLang]}
                        </Typography>
                        <FormControl variant="standard" sx={{ width: 250 }}>
                            <Select
                                labelId="demo-simple-select-label-company"
                                id="demo-simple-select"
                                value={company || ''}
                                onChange={handleChangeCompany}
                                label={uiText.ui_company[uiLang]}
                                disabled={loading}
                                autoWidth
                            >
                                {companies.map((c) => (
                                    <MenuItem key={c.value} value={c.value}>
                                        {c.text}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ pl: 1 }}>
                        <IconButton onClick={() => changeMonth(-1)} disabled={loading}>
                            <ArrowBackIosOutlined sx={{ color: "#243e71" }} />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ alignSelf: 'center', padding: '0 10px' }}>
                            {startOfMonth.toLocaleDateString(uiLang, { month: 'long', year: 'numeric' })}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => changeMonth(1)} disabled={loading}>
                            <ArrowForwardIosOutlined sx={{ color: "#243e71" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            )}
            <Grid container direction="row" sx={{ pl: 10 }}>
                <Grid  >
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#243e71",
                            pt: 1,
                            pb: 1,
                        }}>
                        {uiText["dashdoard_category_prefix"][uiLang]} {uiText["income"][uiLang]} {uiText["dashdoard_category_sufix"][uiLang]}
                        {' '}
                        <span style={{ color: 'black', fontSize: '0.6em' }}>{startOfMonthString} - {endOfMonthString}</span>
                    </Typography>
                </Grid>
                <Grid container direction="row" spacing={1} >
                    {loading ? (
                        Array.from(new Array(7)).map((_, index) => (
                            <Grid item key={'incomeDataSkeleton_' + index} sx={{ p: 1 }}>
                                <Skeleton variant="rectangular" width={300} height={118} />
                                <Skeleton variant="text" width={280} height={20} />
                                <Skeleton variant="text" width={280} height={40} />
                            </Grid>
                        ))) : (
                        incomeData && incomeData.map((item, index) => (
                            <Grid
                                key={'incomeData_' + index}>
                                <StyledCard data={item} uiLang={uiLang} />
                            </Grid>
                        ))
                    )}
                </Grid>
                <Divider light sx={{ p: 2 }} />
                <Grid container direction="row" >
                    <Typography
                        variant="h4"
                        sx={{
                            color: "#243e71",
                            pt: 1,
                            pb: 1,
                        }}>
                        {uiText["dashdoard_category_prefix"][uiLang]} {uiText["expenses"][uiLang]} {uiText["dashdoard_category_sufix"][uiLang]}
                        {' '}
                        <span style={{ color: 'black', fontSize: '0.6em' }}>{startOfMonthString} - {endOfMonthString}</span>
                    </Typography>
                </Grid>
                <Grid container direction="row" spacing={1} >
                    {loading ? (
                        Array.from(new Array(7)).map((_, index) => (
                            <Grid item key={'expensesDataSkeleton_' + index} sx={{ p: 1 }}>
                                <Skeleton variant="rectangular" width={300} height={118} />
                                <Skeleton variant="text" width={280} height={20} />
                                <Skeleton variant="text" width={280} height={40} />
                            </Grid>
                        ))) : (
                        expensesData && expensesData.map((item, index) => (
                            <Grid
                                key={'expensesData_' + index}>
                                <StyledCard data={item} uiLang={uiLang} />
                            </Grid>
                        ))
                    )}
                </Grid>
            </Grid>
        </>
    );
}
export default Dashboard;
