import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import { Button, Grid, IconButton } from "@mui/material";

import { BsBoxArrowInRight } from "react-icons/bs";
import { BiPaperPlane } from "react-icons/bi";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import uiText from "../context/translation.json"



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs(props) {
  const [open, setOpen] = useState(false);
  // const [isButtonDisabled, SetButtonState] =useState();
  // const [showButtons, setShowButtons] = useState(false);
  const [buttonSendAction, setButtonSendAction] = useState("");
  const [anchorEl, setAnchorEl] = useState(null)
  // React.useEffect(() => {
  //   SetButtonState(props.disabled);
  // });
  const handleClickOpenChosen = () => {
    setButtonSendAction("selected");
    setOpen(true);
  };
  const handleClickOpenAll = (e) => {
    props.selectAllDocs();
    setButtonSendAction("all");
    setOpen(true)
  };
  const handleClose = () => {
    props.cancelSelectAllDocs()
    setOpen(false);
  };

  const executeJobAndClose = () => {
    if (buttonSendAction === "selected") props.func_to_executeSelected();
    if (buttonSendAction === "all") props.func_to_executeAll();
    handleClose();
  };
  const toggleButtons = (e) => {
    e.stopPropagation()
    anchorEl ? setAnchorEl(null) : setAnchorEl(menuButtonRef.current);
    // setShowButtons(!showButtons);
  };
  const hideButtons = () => {
    setAnchorEl(null);
    // setShowButtons(false);
  };
  const menuButtonRef = React.useRef()
  return (
    <div>
      {/* {isButtonDisabled != true ? ( */}
      <ClickAwayListener onClickAway={hideButtons}>
        <Grid container direction="column">
          <Button
            ref={menuButtonRef}
            onClick={
              props.hasSelectedDocs ? handleClickOpenChosen : handleClickOpenAll
            }
            style={{
              width: 280,
              position: "relative",
              height: 50,
              backgroundColor: "#243e71",
            }}
            // onMouseOver={(event)=>{toggleButtons(event)}}
            variant="contained"
            // disabled={!isButtonDisabled}
            className={props.className}
          >
            {props.hasSelectedDocs ? props.nameChosen : props.nameAll}
            <IconButton
              className="CustomIconButton"
              style={{ position: "absolute", right: -10 }}
            >
              <ArrowDropDownIcon
                className="CustomIconButton"
                onClick={(event) => {
                  toggleButtons(event);
                }}
              ></ArrowDropDownIcon>
            </IconButton>
          </Button>
          <Popper
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={hideButtons}
            disableScrollLock
            anchororigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          // transformOrigin={{
          //   vertical: "botttom",
          //   horizontal: "center",
          // }}
          >
            <Grid container direction={"column"}>
              <Button
                style={{
                  width: 280,
                  position: "relative",
                  height: 50,
                  backgroundColor: "#243e71",
                  zIndex: 13,
                }}
                className={props.className}
                nowrap
                onClick={
                  props.hasSelectedDocs
                    ? handleClickOpenAll
                    : handleClickOpenChosen
                }
                variant={"contained"}
                disabled={!props.hasSelectedDocs}
              >
                <BsBoxArrowInRight className="CustomIconButton" />
                {props.hasSelectedDocs ? props.nameAll : props.nameChosen}
              </Button>
            </Grid>
          </Popper>
        </Grid>
      </ClickAwayListener>
      {/* ) : (
          <Button
            className={props.className}
            nowrap
            onClick={handleClickOpen}
            variant={props.variant}
          >
            {props.IconName == "GoFileSymlinkFile" ? (
              <GoFileSymlinkFile className="CustomIconButton" />
            ) : (
              <BsBoxArrowInRight className="CustomIconButton" />
            )}
            {props.name}
          </Button>
        )} */}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <Typography gutterBottom>
            <Grid container spacing={0} item lg={12}>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_stamp_duty_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.stamp_duty_amount}{" "}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_total_documents[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.total_documents}{" "}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_other_taxes_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.other_taxes_amount}{" "}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_final_net_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.final_net_amount}{" "}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_fees_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.fees_amount}{" "}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_net[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.net_amount}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_withheld_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.withheld_amount}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_vat[props.uiLang]}</p>{" "}
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.vat_amount}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_deductions_amount[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.deductions_amount}
                </p>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                className="amountDetailsView"
              >
                <p>{uiText.summary_gross[props.uiLang]}</p>
                <p className="BoldFonts">
                  {props.selectedReport && props.selectedReport.total_amount}
                </p>
              </Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              gap: "10px",
            }}
          >
            <Button
              style={{
                position: "relative",
                height: 50,
                backgroundColor: "#243e71",
                boxShadow: "2px 3px 2px black",
              }}
              variant="contained"
              onClick={executeJobAndClose}
              className={props.className}
            >
              <BiPaperPlane className="CustomIconButton" />
              {uiText.option_send[props.uiLang]}
            </Button>
            <Button
              variant="contained"
              style={{
                position: "relative",
                height: 50,
                backgroundColor: "#243e71",
                boxShadow: "2px 3px 2px black",
              }}
              onClick={handleClose}
              className={props.className}
            >
              <AiOutlineClose className="CustomIconButton" />
              {uiText.option_cancel[props.uiLang]}
            </Button>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
