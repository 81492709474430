import { makeXLSX } from "../../utils/makeXLSX";


// calculate sums, only for columnsToInclude
export const calculateTotals = (data, columnsToInclude, firstColumnName) => {

  if (data.length === 0) {
    return [];
  }

  const initialAccumulator = Object.fromEntries(Object.keys(data[0]).map(col => (columnsToInclude.includes(col) ? [col, 0] : [col, '-'])))

  const total = data.reduce((acc, row) => {
    const newAcc = {};
    for (const property in acc) {
      newAcc[property] = acc[property];
      if (columnsToInclude.includes(property)) {
        newAcc[property] += row[property];
      }
    }
    return newAcc;
  }, initialAccumulator)

  total[firstColumnName] = 'Total';
  return total;
}


/* 
  Row is a flat object with key-value pairs.
  Filter is a flat object with key-value pairs. 
  Filter keys must be a subset of row keys.
  Empty filters are empty strings.
  All values are strings or null.
*/
export const filterRow = (row, filters) => {
  try {
    return Object.keys(row).map((key) => {
      if (!(key in filters)) {
        return true;
      }
      return row[key] !== null ? row[key]?.toString()?.toUpperCase().includes(filters[key]?.toUpperCase()) : "".includes(filters[key]?.toUpperCase());
    }).every(item => item)
  } catch (error) {
    return true
  }
}

export const exportAsXLSX = (columns, rows, sheetName, fileName) => {
  const translatedColumns = columns.filter(column => column.id !== 'link').map((column) => (column.label) );
  const dataForXLSX = rows.map(document => (Object.fromEntries(columns.filter(column => column.id !== 'link').map(column => ([column.id, document[column.id]])))))
  makeXLSX(translatedColumns, dataForXLSX, sheetName, fileName);
}