export const isUserLoggedIn = () => {
  return (
    sessionStorage.getItem("user") && sessionStorage.getItem("access_token")
  );
};

export const hasUserVisibility = (routeRoles) => {
  if (!routeRoles) {
    return true;
  }

  if (!isUserLoggedIn()) {
    return false;
  }

  const user = JSON.parse(sessionStorage.getItem("user"));
  const userRoles = user.roles;

  if (!userRoles.length) {
    return false;
  }

  return Boolean(
    userRoles.find((userRole) => {
      return Boolean(routeRoles.find((role) => role === userRole.roleName));
    })
  );
};

export const getUserData = () => JSON.parse(sessionStorage.getItem("user"));

export const setUserData = (user) =>
  sessionStorage.setItem("user", JSON.stringify(user));

export const getUserCredentials = () =>
  (JSON.parse(sessionStorage.getItem("credentials")));
export const getUserLang = () => (sessionStorage.getItem("lang"));

export const setUserLang = (uiLang) =>
  sessionStorage.setItem("lang", uiLang);

export const setAccessToken = (token) =>
  sessionStorage.setItem("access_token", token);

export const setAzureAuth = () => {
  sessionStorage.setItem("azure_auth", true);
}

export const getAzureAuth = () => {
  return sessionStorage.getItem("azure_auth");
}

export const removeAzureAuth = () => {
  sessionStorage.removeItem("azure_auth");
}

export const setCredentials = (credentials) =>
  sessionStorage.setItem("credentials", JSON.stringify(credentials));
// export const getAccessToken = () => {
//   const token = sessionStorage.get("access_token");
//   const userToken = JSON.parse(token);
//   return userToken?.token;
// };

export const getAccessToken = () => {
  return sessionStorage.getItem("access_token");
};

export const removeAccessToken = () =>
  sessionStorage.removeItem("access_token");

export const removeCredentials = () =>
  sessionStorage.removeItem("credentials");

export const removeUser = () => sessionStorage.removeItem("user");
