// sorting stuff
export const descendingComparator = (a, b, orderBy) => {
  const nullUndefined = [null, undefined];

  if (nullUndefined.includes(a[orderBy]) && nullUndefined.includes(b[orderBy])) {
    return 0;
  }
  if (nullUndefined.includes(a[orderBy])) {
    return 1;
  }
  if (nullUndefined.includes(b[orderBy])) {
    return -1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

/* 
  Row is a flat object with key-value pairs.
  Filter is a flat object with key-value pairs. 
  Filter keys must be a subset of row keys.
  Empty filters are empty strings.
  All values are strings or null.
*/
export const filterRow = (row, filters) => {
  try {
    return Object.keys(row)
      .map((key) => {
        if (!(key in filters)) {
          return true;
        }
        return row[key] !== null
          ? row[key]?.toString()?.toUpperCase().includes(filters[key]?.toUpperCase())
          : "".includes(filters[key]?.toUpperCase());
      })
      .every((item) => item);
  } catch (error) {
    return true;
  }
};
