import "./ToggleSwitch.css";
import uiText from "../context/translation.json"



const ToggleSwitch = ({ onToggle,uiLang }) => {

    const onSwitchChange = (event) => {
        onToggle(event.target.checked)
    }
    return (
        <div className="container" >
            <h4>{uiText.summary_total[uiLang]}</h4>
            <input type="checkbox" name="toggle" id="toggle" onChange={onSwitchChange} />
            <label htmlFor="toggle" className="toggleLabel" ></label>
            <h4>{uiText.summary_selected[uiLang]}</h4>
        </div>
    );
};

export default ToggleSwitch;