import { post, get } from "../api";

export const loginUser = (credentials) => {
  return post("authorization/access_token", credentials, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });
};

export const getUsersProfile = () => {
  return get("users/profile");
};

export const getCurrentUser = () => {
  return get("users/current");
};

export function getUsersColumns(view) {
  return get(`users/columns?view=${view}`);
}

export const getAzureUrl = async () => {
  return get('azure/login_url');
}