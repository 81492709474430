/*
  Component to test stuff about pagination, sorting, filtering
*/

import { useCallback, useEffect, useState } from "react";
import { getTestDocumentData } from "./TestDocumentAPI";
import Breadcrump from "../../components/Breadcrump/Breadcrump";
import { IconButton, Typography, Button, Tooltip, Popover, Grid } from "@mui/material";
import { Restore } from "@mui/icons-material";
import SelectMDE from "../../components/SelectMDE/SelectMDE";
import { MdFilterAlt } from "react-icons/md";
import FilterPopover from "../../components/FilterPopover/FilterPopover";
import TestTable from "./TestTable";
import styles from './TestDocument.module.css';
import uiText from '../../context/translation.json'
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";

const TestDocuments = ({
  company,
  companies,
  onCompanyChange,
  startingDate,
  endingDate,
  setStartingDate,
  setEndingDate,
  defaultStartingDate,
  defaultEndingDate,
  uiLang,
}) => {

  const [documentData, setDocumentData] = useState([]);
  const [documentColumns, setDocumentColumns] = useState([]);
  const [loadingDocs, setLoadingDocs] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState({
    match_type: 'NONE',
  });

  const formatDate = useCallback((dateObj) => {
    const tzoffset = dateObj instanceof Date ? dateObj.getTimezoneOffset() * 60000 : (new Date()).getTimezoneOffset() * 60000;
    const localISOTime = (new Date(dateObj - tzoffset));
    return localISOTime?.toISOString().substring(0, 10);
  }, [])

  const fetchDocumentData = useCallback(async (category, company, startingDate, endingDate, filters, skip, limit) => {
    setLoadingDocs(true);
    setDocumentData([]);
    setDocumentColumns([]);
    try {
      const { data } = await getTestDocumentData(category?.toUpperCase(), company, startingDate, endingDate, filters, skip, limit)
      if (data.length === 0) {
        setDocumentData([]);
        setDocumentColumns([]);
      } else {
        // assume all objects have the same properties
        setDocumentData(data);
        // id is used as key to account for duplicate vat_number_key
        setDocumentColumns(Object.keys(data[0]).filter(key => key !== 'id'));
      }

    } catch (error) {
      console.log(error);
      
    } finally {
      setLoadingDocs(false);
      setOrder('asc');
      setOrderBy('');
      setAnchorEl(null);
    }
  }, [])

  useEffect(() => {
    if (company !== null && startingDate instanceof Date && endingDate instanceof Date && startingDate <= endingDate) {
      fetchDocumentData('EXPENSES', company, formatDate(startingDate), formatDate(endingDate), filters, page * 100, 100)
    }
  }, [company, startingDate, endingDate, fetchDocumentData, formatDate, filters, page]);

  const handleChangeCompany = (event) => {
    onCompanyChange(event.target.value);
  };

  const handleResetDates = () => {
    setStartingDate(defaultStartingDate);
    setEndingDate(defaultEndingDate);
  };

  const handleOpenFilterPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setAnchorEl(null);
  };

  const handleNextPage = () => {
    setPage(page => page + 1)
  }
  const handleRequestSort = useCallback((event, newOrderBy) => {
    const isAsc = orderBy === newOrderBy && order === 'asc';
    const toggledOrder = isAsc ? 'desc' : 'asc';
    setOrder(toggledOrder);
    setOrderBy(newOrderBy);
  }, [order, orderBy]);

  // process columns
  const columns = documentColumns.map((columnName) => ({ id: columnName, label: columnName, minWidth: 100, align: 'right', }))



  return (
    <>
      <div className={`${styles['reports-breadcrumb-container']}`}>
        <Breadcrump category={'EXPENSES'} status={'REPORT'} uiLang={uiLang} />
        <div className={`${styles['reports-date-reset-button-container']}`}>
          <Button onClick={handleResetDates} disabled={loadingDocs}>
            <Typography
              style={{
                color: "#243e71",
                wordWrap: "normal",
                textTransform: "none",
              }}
            >
              {uiText.ui_reset_dates[uiLang]}
            </Typography>
            <IconButton>
              <Restore />
            </IconButton>
          </Button>
        </div>

      </div>
      <div className={`${styles['reports-top-row-container']}`}>
        <SelectMDE
          value={company}
          disabled={loadingDocs}
          onChange={handleChangeCompany}
          label={uiText.ui_company[uiLang]}
          menuItems={companies.map((comp) => ({ key: comp.value, value: comp.value, label: comp.text }))}
        />
        <Grid
          item
          container
          spacing={2}
          display={"flex"}
          direction={"row"}
          flexWrap={"nowrap"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          xs={'auto'}
        >
          <DateRangePicker
            startingDate={startingDate}
            endingDate={endingDate}
            setStartingDate={setStartingDate}
            setEndingDate={setEndingDate}
            startLabel={uiText.ui_from[uiLang]}
            endLabel={uiText.ui_to[uiLang]}
            disabled={loadingDocs}
          />
        </Grid>



        <Tooltip title={`${uiText.filters[uiLang]}`}>
          <IconButton aria-describedby={anchorEl ? 'simple-popover' : undefined} variant="text" onClick={handleOpenFilterPopover}>
            <MdFilterAlt />
          </IconButton>
        </Tooltip>
        <Popover
          id={anchorEl ? 'simple-popover' : undefined}
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleCloseFilterPopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <FilterPopover
            filtersLabels={Object.fromEntries(Object.keys(filters).map(k => [k, k]))}
            filters={filters}
            setFilters={setFilters}
            disabled={loadingDocs}
            uiLang={uiLang}
          />
        </Popover>

        <Button onClick={handleNextPage}>
          Next
        </Button>

      </div>
      <TestTable
        columns={columns}
        rows={documentData}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        uiLang={uiLang}
      />
    </>
  )
}

export default TestDocuments