import { get, patch } from "../../api";

export function getAccounts(filters, organization_id) {
  filters["organization_id"] = organization_id;
  let filters_for_url = "";
  Object.keys(filters).forEach((key) => {
    filters_for_url += `&${key}=${filters[key]}`;
  });
  return get(`configuration/gl_account_configurations?${filters_for_url}`);
}

export function patchAccount(account) {
  const accountWithEmptyStringsReplacedWithNulls = {};
  const validProperties = new Set([
    "document_category",
    "aade_vat_category",
    "detail_category_code",
    "aade_invoice_type",
    "aade_income_category",
    "aade_income_type",
    "aade_expenses_category",
    "aade_expenses_type",
    "aade_expenses_vat_category",
    "aade_expenses_vat_type",
    "aade_income_type_gr",
    "aade_expenses_type_gr",
    "aade_expenses_vat_type_gr",
    "aade_income_type_eu",
    "aade_expenses_type_eu",
    "aade_expenses_vat_type_eu",
    "aade_income_type_3rd",
    "aade_expenses_type_3rd",
    "aade_expenses_vat_type_3rd",
    "vat_percent",
    "vat_gl_account",
    "aade_vat_exemption_category",
    "record_category",
    "id",
  ]);
  for (const property in account) {
    if (validProperties.has(property))
      accountWithEmptyStringsReplacedWithNulls[property] = account[property] !== "" ? account[property] : null;
  }
  return patch("configuration/edit_gl_account_configuration", accountWithEmptyStringsReplacedWithNulls);
}
