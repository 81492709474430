import { useLocation, } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { removeAccessToken, removeAzureAuth, removeCredentials, setAccessToken } from '../../auth/utils'
import { getCurrentUser, getUsersProfile } from '../../auth/authAPI'
import { get } from '../../api/index'
import { setUserData, getUserLang } from '../../auth/utils'

const exchangeCodeForAccessToken = (params) => {
  const { code, state, session_state } = params
  if (!code || !state || !session_state) {
    throw Error('Code not found in url params')
  }
  const searchParamsObj = { code, session_state }
  const searchParamsUrl = Object.keys(searchParamsObj).reduce((acc, key) => acc + `&${key}=${searchParamsObj[key]}`, "");
  return get(`azure/access_token?${searchParamsUrl}`);
}

export default function AzureRedirect() {
  const { search: searchString } = useLocation();
  const searchParams = useMemo(() => (Object.fromEntries(new URLSearchParams(searchString))), [searchString]);

  useEffect(() => {
    exchangeCodeForAccessToken(searchParams)
      .then((res) => {
        setAccessToken(res.data.access_token)

        Promise.all([
          getCurrentUser()
            .catch((error) => {
              removeAccessToken();
              removeAzureAuth();
              removeCredentials();
              window.location.href = "/login";
            }),
          getUsersProfile()
            .catch((error) => {
              removeAccessToken();
              removeAzureAuth();
              removeCredentials();
              window.location.href = "/login";
            }),
        ]).then((responseArray) => {
          const [{ data: user }, { data: groups }] = responseArray;

          user[`organization_groups`] = groups.organization_groups;
          user[`uiLang`] = getUserLang;

          setUserData(user);
          window.location.href = "/dashboard";
        })

      })
      .catch((error) => {
        removeAccessToken();
        removeAzureAuth();
        removeCredentials();
        window.location.href = "/login";
      })
  }, [searchParams])


  return <></>
}