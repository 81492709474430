import { Box, Button, Divider, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import uiText from "../../context/translation.json";

const FieldComponent = ({ fieldId, isVisible, fieldKey, data, metadata, uiLang, onFieldChange }) => {
    const isEditable = metadata[fieldKey]?.editable ?? false;
    const translation = metadata[fieldKey]["translations"]?.[uiLang] || fieldKey;
    const tooltip = metadata[fieldKey]["tooltips"]?.[uiLang] || "";
    return (
        <React.Fragment>
            <Grid item xs={6}>
                <Typography sx={{
                    display: isVisible ? "flex" : "none",
                    color: "#243e71",
                    fontSize: 16,
                    fontWeight: 500,
                    alignItems: "center",
                    height: "100%",
                }}>{translation}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Tooltip title={tooltip} placement="top" arrow disableHoverListener={!tooltip}>
                    <div>
                        <TextField
                            id={fieldId}
                            fullWidth
                            variant="filled"
                            size="small"
                            value={data[fieldKey] === undefined ? "" : data[fieldKey]}
                            onChange={e => onFieldChange(fieldKey, e.target.value === "" ? null : e.target.value)}
                            disabled={!isEditable}
                            sx={{
                                display: isVisible ? "block" : "none",
                                backgroundColor: isEditable ? "#ffffff" : "#e0e0e0",
                                "& .MuiFilledInput-input": { py: 0.2 },
                                "& .MuiFilledInput-root": {
                                    "&:before": { display: "none" },
                                    "&:after": { display: "none" },
                                },
                            }}
                            InputProps={{ disableUnderline: true }}
                        />
                    </div>
                </Tooltip>
            </Grid>
        </React.Fragment>
    );
};


export default function DocumentDetailsB2G({ data, metadata, uiLang, handleDocumentSave }) {
    const formFields = {
        "document": {
            "id": false,
            "is_b2g": false,
            "b2g_preceding_invoice_reference": true,
            "b2g_purchase_order_reference": true,
            "b2g_buyer_accounting_reference": true,
            "b2g_buyer_reference": true,
            "b2g_contract_reference": true,
            "b2g_project_reference": true,
            "erp_comments": true,
        },
        "document_details": {
            "id": false,
            "b2g_buyer_accounting_reference": true,
            "b2g_classification_identifier": true,
            "b2g_classification_identifier_scheme": true,
            "b2g_classification_identifier_scheme_version": true,
            "b2g_invoiced_quantity_units": true,
            "b2g_vat_category_code": true,
            "erp_comments": true,
        }
    };

    const initialFormData = useMemo(() => {
        const documentData = Object.keys(formFields.document).reduce((acc, fieldKey) => {
            acc[fieldKey] = data[fieldKey] === undefined ? "" : data[fieldKey];
            return acc;
        }, {});
        const documentDetailsData = data.document_details
            ? data.document_details.map(document_detail =>
                Object.keys(formFields.document_details).reduce((acc, fieldKey) => {
                    acc[fieldKey] = document_detail[fieldKey] === undefined ? "" : document_detail[fieldKey]
                    return acc;
                }, {})
            )
            : [];

        return { document: documentData, document_details: documentDetailsData };
    }, [data, formFields.document, formFields.document_details]);

    const [isEdited, setIsEdited] = useState(false);
    const [formData, setFormData] = useState(initialFormData);

    const handleSave = () => {
        let documentSaveData = {
            ...formData.document,
            ...formData
        };
        delete documentSaveData.document;
        handleDocumentSave(documentSaveData);
    };

    const handleCancel = () => {
        setIsEdited(false);
        setFormData(initialFormData);
    };

    const handleFieldChange = (fieldKey, value, index = null) => {
        const newValue = value === "" ? null : value;
        setFormData(currentFormData => {
            if (index !== null) {
                return {
                    ...currentFormData,
                    document_details: currentFormData.document_details.map((item, i) =>
                        i === index ? { ...item, [fieldKey]: newValue } : item
                    ),
                };
            }
            return {
                ...currentFormData,
                document: {
                    ...currentFormData.document,
                    [fieldKey]: newValue,
                },
            };
        });
        setIsEdited(true);
    };

    return (
        <Grid container spacing={1} alignItems="center">
            {Object.entries(formFields.document).map(([fieldKey, isVisible]) => (
                <FieldComponent
                    key={`form-b2g-document-${fieldKey}`}
                    fieldId={`form-b2g-document-${fieldKey}`}
                    isVisible={isVisible}
                    fieldKey={fieldKey}
                    data={formData.document}
                    metadata={metadata["properties"]}
                    uiLang={uiLang}
                    onFieldChange={(fieldKey, newValue) => handleFieldChange(fieldKey, newValue)} />
            ))}
            {formData.document_details.map((document_detail, index) => (
                <React.Fragment key={index}>
                    {<Divider sx={{ mt: 1, mb: 2 }} />}
                    <Stack direction="row" spacing={2} sx={{ ml: 5, mr: 5 }}>
                        <TextField
                            id={`outlined-b2g-document-details-${index}-item_erp_id`}
                            label={metadata["$defs"]["DocumentDetailOut"]["properties"]["item_erp_id"]["translations"]?.[uiLang]}
                            defaultValue={data.document_details[index].item_erp_id}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            size="small"
                            variant="standard"
                        />
                        <TextField
                            id={`outlined-b2g-document-details-${index}-item_description`}
                            label={metadata["$defs"]["DocumentDetailOut"]["properties"]["item_description"]["translations"]?.[uiLang]}
                            defaultValue={data.document_details[index].item_description}
                            sx={{ width: 600 }}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            size="small"
                            variant="standard"
                        />
                        <TextField
                            id={`outlined-b2g-document-details-${index}-quantity`}
                            label={metadata["$defs"]["DocumentDetailOut"]["properties"]["quantity"]["translations"]?.[uiLang]}
                            defaultValue={data.document_details[index].quantity}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            size="small"
                            variant="standard"
                        />
                        <TextField
                            id={`outlined-b2g-document-details-${index}-vat_amount`}
                            label={metadata["$defs"]["DocumentDetailOut"]["properties"]["vat_amount"]["translations"]?.[uiLang]}
                            defaultValue={data.document_details[index].vat_amount}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            size="small"
                            variant="standard"
                        />
                        <TextField
                            id={`outlined-b2g-document-details-${index}-net_value`}
                            label={metadata["$defs"]["DocumentDetailOut"]["properties"]["net_value"]["translations"]?.[uiLang]}
                            defaultValue={data.document_details[index].net_value}
                            InputProps={{ disableUnderline: true, readOnly: true }}
                            size="small"
                            variant="standard"
                        />
                    </Stack>
                    <Grid container key={index} spacing={1} sx={{ ml: 4, mr: 4 }} >
                        {Object.entries(formFields.document_details).map(([fieldKey, isVisible]) => (
                            <FieldComponent
                                key={`form-b2g-document-details-${index}-${fieldKey}`}
                                fieldId={`form-b2g-document-details-${index}-${fieldKey}`}
                                isVisible={isVisible}
                                fieldKey={fieldKey}
                                data={document_detail}
                                metadata={metadata["$defs"]["DocumentDetailOut"]["properties"]}
                                uiLang={uiLang}
                                onFieldChange={(fieldKey, newValue) => handleFieldChange(fieldKey, newValue, index)}
                            />
                        ))}
                    </Grid>
                </React.Fragment>
            ))}
            {isEdited && (
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button variant="text" onClick={handleCancel} sx={{ mr: 1 }}>
                            {uiText.cancel[uiLang]}
                        </Button>
                        <Button variant="text" onClick={handleSave}>
                            {uiText.save[uiLang]}
                        </Button>
                    </Box>
                </Grid>
            )}
        </Grid>
    );
}
