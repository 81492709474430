/* eslint-disable no-unused-vars */
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import CodeIcon from "@mui/icons-material/Code";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import ExpandIcon from "@mui/icons-material/Expand";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  Snackbar,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import hljs from "highlight.js";
import "highlight.js/styles/tomorrow-night-blue.css";
import QRCode from "qrcode.react";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { BsArrowLeft, BsArrowRepeat, BsFile, BsXCircle } from "react-icons/bs";
import { FaRegCommentAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdWebhook } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import { getUserData } from "../../auth/utils";
import CustomTable from "../../components/CustomTable";
import DetailsInput from "../../components/DetailsInput";
import classificationCategory from "../../context/aade_classification_category.json";
import classificationType from "../../context/aade_classification_type.json";
import invoicetype from "../../context/aade_invoice_type.json";
import vatCategory from "../../context/aade_vat_category.json";
import vatExemptionCause from "../../context/aade_vat_exemption_cause.json";
import uiSummary from "../../context/custom_summary.json";
import uiTable from "../../context/custom_table.json";
import uiTag from "../../context/document_tags.json";
import spcecialcategory from "../../context/special_invoice_category.json";
import uiText from "../../context/translation.json";
import "./DocumentDetails.css";
import {
  getDocument,
  getDocumentMetadata,
  patchDocuments,
  postAadeSelected,
  postAadeSelectedCancel,
  postDocumentUnmatch,
  postDocumentsXml,
  postManualInitializeDocument,
  postMatchIndividualDocument,
  triggerAADESynchronizationDocumentBackground,
  triggerSynchronizationDocumentBackground,
  triggerUpdateErpBackground,
  triggerUploadBackground,
} from "./documentAPI";
// import DOMPurify from 'dompurify';
import { exportXML } from "../report/ReportUtils";
import DocumentDetailsB2G from "./DocumentDetailsB2G";
import DocumentDetailsForm from "./DocumentDetailsForm";

const DocumentDetails = (props) => {
  const { uiLang } = props;
  const [document, setDocument] = useState(null);
  // const [documentStatus, setDocumentStatus] = useState(true);
  const { category, status, id } = useParams();
  const [NotificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [openInitializeDialog, setOpenInitializeDialog] = useState(false);
  const [openEditFormDialog, setOpenEditFormDialog] = useState(false);
  const [isAadeQrCodeDialogOpen, setAadeQrCodeDialogOpen] = useState(false);
  const [isShowTopButton, setShowTopButton] = useState(false);

  const [relatedDocument, setRelatedDocument] = useState(null);
  const [open, setOpen] = useState(false);
  const [xmlModalOpen, setXmlModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [xmlData, setXmlData] = useState(false);
  const [formattedXml, setformattedXml] = useState(false);
  const handleOpenInitializeDialog = (open) => {
    setOpenInitializeDialog(open);
  };

  const handleEditFormDialogOpen = (open) => {
    setOpenEditFormDialog(open);
  };

  const handleClickOpen = (id) => {
    setRelatedDocument(id);
    setOpen(true);
  };
  const user = getUserData();
  const userAuthArray = user?.job_actions ?? [];

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenAadeQrCodeDialog = (open) => {
    setAadeQrCodeDialogOpen(open);
  };
  const handleXmlModal = (open) => {
    setXmlModalOpen(open);
  };
  const [documentMetadata, setDocumentMetadata] = useState(null);

  const summaryArray = [
    "aade_category",
    // "aade_income_category",
    // "aade_expenses_category",
    "aade_type",
    // "aade_income_type",
    // "aade_expenses_type",
    "aade_expenses_vat_category",
    "aade_expenses_vat_type",
    "aade_vat_category",
    "aade_vat_exemption_category",
    "total_vat_amount",
    "total_net_value",
  ];
  const detailArray = [
    "gl_account_erp_id",
    "item_erp_id",
    "quantity",
    "item_description",
    "aade_vat_category",
    "vat_percent",
    "aade_vat_exemption_category",
    "vat_amount",
    "net_value",
    // "gross_value",
  ];
  const return_path = { link: `/${category}/${status}` };

  const fetchData = useCallback(() => {
    let id_get = props.id === null || props.id === undefined ? id : props.id;
    getDocument(id_get).then((response) => {
      let newSummaries = [];
      let resp = { ...response };
      // console.log(response);
      response.data.document_summary?.forEach((doc) => {
        // console.log(doc)
        doc.aade_vat_category = doc.aade_vat_category
          ? doc.aade_vat_category +
            " : " +
            vatCategory[doc.aade_vat_category]["vat_literal"]
          : "-";
        doc.aade_vat_exemption_category = doc.aade_vat_exemption_category
          ? doc.aade_vat_exemption_category +
            " : " +
            vatExemptionCause[doc.aade_vat_exemption_category][uiLang]
          : "-";
        if (response.data.document_category === "INCOME") {
          doc.aade_category = doc.aade_income_category
            ? doc.aade_income_category +
              " : " +
              classificationCategory[doc.aade_income_category][uiLang]
            : "-";
          doc.aade_type = doc.aade_income_type
            ? doc.aade_income_type +
              ": " +
              classificationType[doc.aade_income_type][uiLang]
            : "-";
        }
        if (response.data.document_category === "EXPENSES") {
          doc.aade_category = doc.aade_expenses_category
            ? doc.aade_expenses_category +
              " : " +
              classificationCategory[doc.aade_expenses_category][uiLang]
            : "-";
          doc.aade_type = doc.aade_expenses_type
            ? doc.aade_expenses_type +
              ": " +
              classificationType[doc.aade_expenses_type][uiLang]
            : "-";
          doc.aade_expenses_vat_category = doc.aade_expenses_vat_category
            ? doc.aade_expenses_vat_category +
              " : " +
              classificationCategory[doc.aade_expenses_vat_category][uiLang]
            : "-";
          doc.aade_expenses_vat_type = doc.aade_expenses_vat_type
            ? doc.aade_expenses_vat_type +
              ": " +
              classificationType[doc.aade_expenses_vat_type][uiLang]
            : "-";
        }
        newSummaries.push(doc);
      });
      resp.data.document_summary = newSummaries;
      setDocument(resp.data);
    });
  }, [id, props.id, uiLang]);

  useEffect(() => {
    fetchDocumentMetadata();
    fetchData();
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 180);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [uiLang, fetchData]);

  const onAadeResend = (event) => {
    postAadeSelected([document])
      .then((response) => {
        setNotificationMessage(uiText.detail_document_is_sent[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onAadeCancel = (event) => {
    postAadeSelectedCancel([document])
      .then((response) => {
        setNotificationMessage(uiText.detail_is_cancelled[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onUpdateErp = (event) => {
    triggerUpdateErpBackground(props.company, [document.id])
      .then((response) => {
        setNotificationMessage(uiText.detail_is_updated[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onUpload = (event) => {
    triggerUploadBackground(props.company, [document.id])
      .then((response) => {
        setNotificationMessage(uiText.detail_is_uploaded[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onUnmatch = (event) => {
    postDocumentUnmatch([document])
      .then((response) => {
        setNotificationMessage(uiText.detail_document_is_unmatched[uiLang]);
        setNotificationState(true);
        setDocument(null);
        fetchData();
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onMatchIndividualDocument = (event) => {
    postMatchIndividualDocument(
      props.company,
      document.document_date,
      document.erp_date_created
    )
      .then((response) => {
        setNotificationMessage(
          uiText.detail_document_match_individual_is_running[uiLang]
        );
        setNotificationState(true);
        setDocument(null);
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch(() => {
        setNotificationMessage(uiText.detail_error[uiLang]);
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onSynchronizeDocument = async (event) => {
    try {
      await triggerSynchronizationDocumentBackground(
        props.company,
        document.document_date,
        document.erp_date_created
      );
      setNotificationMessage(uiText.ui_erp_synchronization_start[uiLang]);
      setNotificationState(true);
      setTimeout(() => setNotificationState(false), 3000);
    } catch (error) {
      setNotificationMessage(uiText.detail_error[uiLang]);
      setNotificationState(true);
      setTimeout(() => setNotificationState(false), 3000);
    }
  };

  const onInitializeDocument = async (event) => {
    try {
      await postManualInitializeDocument(document?.id);
      setNotificationMessage(
        uiText.detail_initialize_document_result_msg[uiLang]
      );
      setNotificationState(true);
      handleOpenInitializeDialog(false);
      setDocument(null);
      fetchData();
      setTimeout(() => setNotificationState(false), 3000);
    } catch (error) {
      setNotificationMessage(uiText.detail_error[uiLang]);
      setNotificationState(true);
      setTimeout(() => setNotificationState(false), 3000);
    }
  };

  const onPatchDocuments = (toSave) => {
    patchDocuments(toSave)
      .then((response) => {
        handleEditFormDialogOpen(false);
        setNotificationMessage(uiText.saved_succefully[uiLang]);
        setNotificationState(true);
        setDocument(null);
        fetchData();
        setTimeout(() => setNotificationState(false), 3000);
      })
      .catch((error) => {
        setNotificationMessage(
          uiText?.[error?.response?.data]?.[uiLang] ??
            uiText.detail_error[uiLang]
        );
        setNotificationState(true);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };

  const onXmlModal = () => {
    setLoading(true);
    postDocumentsXml([document.id])
      .then((response) => {
        // const safeXml = DOMPurify.sanitize(response.data);
        // const hljsXml = hljs.highlight(safeXml, { language: 'xml' }).value;
        setXmlData(response.data);
        const hljsXml = hljs.highlight(response.data, {
          language: "xml",
        }).value;
        setformattedXml(hljsXml);
        handleXmlModal(true);
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const copyXmlToClipboard = () => {
    navigator.clipboard
      .writeText(xmlData)
      .then(() => {})
      .catch((error) => {});
  };

  const downloadXML = () => {
    exportXML(xmlData, document.aade_mark || document.id);
  };

  const onAADERetrieveDocument = async (event) => {
    try {
      await triggerAADESynchronizationDocumentBackground(
        props.company,
        document.document_date
      );
      setNotificationMessage(uiText.ui_aade_synchronization_start[uiLang]);
      setNotificationState(true);
      setTimeout(() => setNotificationState(false), 3000);
    } catch (error) {
      setNotificationMessage(uiText.detail_error[uiLang]);
      setNotificationState(true);
      setTimeout(() => setNotificationState(false), 3000);
    }
  };

  function AadeQrCodeDialog({ open, handleClose, text }) {
    return (
      <Dialog open={open} onClose={() => handleClose(false)}>
        <div
          style={{
            width: "400px",
            height: "400px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ marginBottom: "20px" }}>
            <QRCode value={text} size={300} />
          </div>
          <div
            style={{
              wordWrap: "break-word",
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              maxWidth: "300px",
            }}
          >
            <a
              href={text}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                textDecoration: "none",
                color: "#243e71",
                fontWeight: "bold",
                fontSize: "16px",
              }}
            >
              {text}
            </a>
          </div>
        </div>
      </Dialog>
    );
  }

  const fetchDocumentMetadata = () => {
    getDocumentMetadata()
      .then((response) => {
        setDocumentMetadata(response.data);
      })
      .catch((error) => {})
      .finally(() => {});
  };

  const [expandedAccordionStatus, setAccordionExpandedStatus] = useState({
    detail_general_items: true,
    detail_b2g_items: false,
    detail_summary: false,
    detail_document_details: false,
    detail_send_status: false,
  });

  const handleAccordionOnChange = (panel) => (event, isExpanded) => {
    setAccordionExpandedStatus((prevStatus) => ({
      ...prevStatus,
      [panel]: isExpanded,
    }));
  };

  const toggleAccordionExpandAll = () => {
    const allExpanded = Object.values(expandedAccordionStatus).every(
      (expanded) => expanded
    );
    setAccordionExpandedStatus((prevStatus) => ({
      detail_general_items: !allExpanded,
      detail_b2g_items: !allExpanded,
      detail_summary: !allExpanded,
      detail_document_details: !allExpanded,
      detail_send_status: !allExpanded,
    }));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  function tryCatch(p) {
    try {
      if (p !== null) {
        return p;
      } else {
        return "-";
      }
    } catch (e) {
      return "n/a";
    }
  }

  function formatDate(dateString) {
    if (!dateString) return "-";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "-";
    } else {
      return date.toLocaleString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    }
  }

  const docDetails = [
    [
      {
        label: uiText.detail_send_status[uiLang],
        value: tryCatch(
          document && uiText?.[document?.document_status]?.[uiLang]
        ),
      },
      {
        label: uiText.detail_document_category[uiLang],
        value: tryCatch(document && uiText[document.document_category][uiLang]),
      },
    ],
    [
      {
        label: uiText.MARK[uiLang],
        value: tryCatch(document && document.aade_mark),
      },
      {
        label: uiText.detail_document_type[uiLang],
        value: tryCatch(
          document &&
            document.aade_invoice_type &&
            `${document.aade_invoice_type} ${
              invoicetype[document.aade_invoice_type][uiLang]
            }`
        ),
      },
    ],
    [
      {
        label: uiText.MARK_cancellation[uiLang],
        value: tryCatch(document && document.aade_mark_cancellation),
      },
      {
        label: uiText.document_date[uiLang],
        value: tryCatch(document && document.document_date),
      },
    ],
    [
      {
        label: uiText.aade_qr_code[uiLang],
        value: tryCatch(document && document.aade_qr_code_url),
      },
      {
        label: uiText.dispatch_date[uiLang],
        value: formatDate(tryCatch(document && document.dispatch_date)),
      },
    ],

    [
      {
        label: uiText.MARK_classification[uiLang],
        value: tryCatch(document && document.aade_mark_classification),
      },
      {
        label:
          uiText &&
          `${uiText.series[uiLang]} & ${uiText.document_no[uiLang]} ERP`,
        value: tryCatch(
          document &&
            (document.erp_document_series && document.erp_document_number
              ? `${document.erp_document_series ?? ""} ${
                  document.erp_document_number ?? ""
                }`
              : document.erp_document_series ?? document.erp_document_number)
        ),
      },
    ],
    [
      {
        label: uiText.aade_uid[uiLang],
        value: tryCatch(document && document.aade_uid),
      },
      // {
      //   label: uiText && `${uiText.series[uiLang]} & ${uiText.document_no[uiLang]} ${uiText.counterpart[uiLang]}`,
      //   value: document &&
      //     `${document.series ? document.series : ''} ${document.document_no_aade}`,
      // }
      {
        label:
          uiText &&
          `${uiText.series[uiLang]} & ${uiText.document_no[uiLang]} ${uiText.ui_of_document[uiLang]}`,
        value: tryCatch(
          document &&
            (document.print_document_series && document.print_document_number
              ? `${document.print_document_series ?? ""} ${
                  document.print_document_number ?? ""
                }`
              : document.print_document_series ??
                document.print_document_number)
        ),
      },
    ],
    [
      {
        label: uiText.document_match_type[uiLang],
        value: tryCatch(
          document &&
            document.match_type &&
            `${uiText[document.match_type][uiLang]}`
        ),
      },
      {
        label: uiText.detail_issuer_branch[uiLang],
        value: tryCatch(document && document.aade_issuer_branch),
      },
    ],

    [
      {
        ///speciaaaaal!!!!!
        label: uiText.detail_document_special_category[uiLang],
        value: tryCatch(
          document &&
            document.aade_special_invoice_category &&
            `${document.aade_special_invoice_category} ${
              spcecialcategory[document.aade_special_invoice_category][uiLang]
            }`
        ),
      },
      {
        label: uiText.self_pricing[uiLang], //AUTOTIMOLOPGHSH
        value: tryCatch(
          document && document.is_self_pricing !== null
            ? document.is_self_pricing === true
              ? uiText.ui_yes[uiLang]
              : uiText.ui_no[uiLang]
            : "-"
        ),
      },
    ],

    [
      {
        label: uiText.document_erp_id[uiLang],
        value: tryCatch(document && document.erp_id),
      },
      {
        //COMMENTSSSS
        label: uiText.erp_comments[uiLang],
        value: tryCatch(
          document &&
            (document.erp_comments
              ? `${document.erp_comments ?? ""}`
              : document.erp_comments)
        ),
      },
    ],

    [
      {
        label: uiText["partner.vat_number"][uiLang],
        value: tryCatch(document && document.partner?.vat_number),
      },
      {
        label: uiText["partner.postal_code"][uiLang],
        value: tryCatch(document && document.partner?.postal_code),
      },
    ],

    [
      {
        label: uiText["partner.name"][uiLang],
        value: tryCatch(document && document.partner?.name),
      },
      {
        label: uiText["partner.city"][uiLang],
        value: tryCatch(document && document.partner?.city),
      },
    ],
    [
      {
        label: uiText["partner.erp_id"][uiLang],
        value: tryCatch(document && document.partner?.erp_id),
      },
      {
        label: uiText["partner.country"][uiLang],
        value: tryCatch(document && document.partner?.country),
      },
    ],
    [
      {
        label: uiText.fees[uiLang],
        value: tryCatch(document && document.fees_amount),
      },
      {
        label: uiText.deduction[uiLang],
        value: tryCatch(document && document.deductions_amount),
      },
    ],
    [
      {
        label: uiText.other_taxes[uiLang],
        value: tryCatch(document && document.other_taxes_amount),
      },
      {
        label: uiText.withholding_tax[uiLang],
        value: tryCatch(document && document.withheld_amount),
      },
    ],
    [
      {
        label: uiText.stamp_duty[uiLang],
        value: tryCatch(document && document.stamp_duty_amount),
      },
      {
        label: uiText.net_amount[uiLang],
        value: tryCatch(document && document.net_value),
      },
    ],
    [
      {
        label: uiText.vat_exemption_cause[uiLang],
        value: tryCatch(
          document &&
            `${
              document?.partner?.aade_vat_exemption_category
                ? vatExemptionCause[
                    document.partner?.aade_vat_exemption_category
                  ][uiLang]
                : "-"
            }`
        ),
      },
      {
        label: uiText.vat_amount[uiLang],
        value: tryCatch(document && document.vat_amount),
      },
    ],
    [
      {
        label:
          uiText &&
          `${uiText.currency[uiLang]} & (${uiText.exchange_rate[uiLang]})`,
        value: tryCatch(
          document && `${document.currency} (${document.exchange_rate})`
        ),
      },
      {
        label: uiText.total_amount[uiLang],
        value: tryCatch(document && document.gross_value),
      },
    ],
  ];

  // show button logic
  const buttonsAreActive = document !== null;
  const buttonIsAadeQrCode =
    document !== null && document.aade_qr_code_url !== null;
  const buttonIsRetrieve =
    document !== null && document?.origin_type === "AADE";
  const buttonIsSynchronize =
    document !== null && document?.origin_type === "ERP";
  const buttonIsUnmatch =
    document !== null &&
    ((document?.origin_type === "ERP" &&
      ["AUTO", "OMISSION", "MANUAL", "PARTIAL", "PARTIAL2_95"].includes(
        document?.match_type
      )) ||
      (document?.origin_type === "AADE" &&
        ["REJECTION"].includes(document?.match_type)));
  const buttonIsMatchIndividual =
    document !== null &&
    document?.origin_type === "ERP" &&
    document?.document_status === "PENDING_MARK";
  const buttonIsSend =
    document !== null &&
    ["TO_SEND", "TO_CORRECT", "TO_RESEND", "SENT"].includes(
      document?.document_status
    );
  const buttonIsUpdate =
    document !== null &&
    document?.origin_type === "ERP" &&
    (document?.document_status === "SENT" ||
      document?.document_status === "CANCELLED");
  const buttonIsSent =
    document !== null &&
    document?.origin_type === "ERP" &&
    document?.document_status === "SENT";
  const buttonIsCancel =
    document !== null &&
    document?.origin_type === "ERP" &&
    (document?.document_status === "TO_CANCEL" ||
      (document?.document_status === "SENT" &&
        document?.aade_mark_classification === null));
  const buttonIsEdit =
    document !== null &&
    document?.origin_type === "ERP" &&
    document?.document_status !== "SENT";
  const buttonIsInitialize =
    document !== null &&
    document?.origin_type === "ERP" &&
    document?.document_status === "CANCELLED";
  const userCanMatch = userAuthArray.includes("MATCH_DOCS");
  const userCanCancel =
    (document?.document_category === "EXPENSES" &&
      userAuthArray.includes("CANCEL_AADE_EXPENSES")) ||
    (document?.document_category === "INCOME" &&
      userAuthArray.includes("CANCEL_AADE_INCOME"));
  const userCanSend =
    (document?.document_category === "EXPENSES" &&
      userAuthArray.includes("TRANSMIT_AADE_EXPENSES")) ||
    (document?.document_category === "INCOME" &&
      userAuthArray.includes("TRANSMIT_AADE_INCOME"));
  const userCanInitialise = userAuthArray.includes("INITIALIZE_DOCS");
  const isB2G = document?.is_b2g === true;

  return (
    <>
      <div>
        <Dialog
          // fullScreen
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "93%",
                maxWidth: "2000px", // Set your width here
              },
            },
          }}
        >
          <div
            style={{
              position: "relative",
              top: "15px",
              left: "15px",
              display: "inline",
            }}
          >
            <IconButton
              // edge="start"
              // color="inherit"
              onClick={handleClose}
              aria-label="close"
              style={{ color: "#243e71" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div style={{ marginTop: "30px" }}>
            <DocumentDetails
              uiLang={props.uiLang}
              company={props.company}
              appLocale={props.appLocale}
              id={relatedDocument}
              disableControls={true}
            />
          </div>
        </Dialog>
      </div>
      <Grid item style={{ marginLeft: 85, overflow: "hidden" }}>
        {props.disableControls !== true && (
          <Grid
            item
            style={{ marginBottom: 15, justifyContent: "space-between" }}
            display={"flex"}
          >
            <Button
              style={{ margin: "0rem 0.3rem" }}
              component={Link}
              to={return_path && return_path.link}
              variant="contained"
            >
              <BsArrowLeft style={{ marginRight: 10 }} />
              {uiText.detail_back[uiLang]}
            </Button>
            <Grid
              item
              style={{ justifyContent: "space-between" }}
              display={"flex"}
            >
              {buttonsAreActive && buttonIsSynchronize && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onSynchronizeDocument}
                >
                  <BsArrowRepeat style={{ marginRight: 10 }} />
                  {uiText.refreshErpTooltip[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsUnmatch && userCanMatch && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onUnmatch}
                >
                  <BsArrowRepeat style={{ marginRight: 10 }} />
                  {uiText.detail_document_unmatch[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsMatchIndividual && userCanMatch && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onMatchIndividualDocument}
                >
                  <BsArrowRepeat style={{ marginRight: 10 }} />
                  {uiText.detail_document_match_individual[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsRetrieve && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onAADERetrieveDocument}
                >
                  <AiOutlineCloudDownload style={{ marginRight: 10 }} />
                  {uiText.refreshAADETooltip[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsSend && userCanSend && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onAadeResend}
                >
                  <BiMailSend style={{ marginRight: 10 }} />
                  {document?.document_status === "TO_SEND"
                    ? uiText.detail_send[uiLang]
                    : uiText.detail_resend[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsUpdate && userCanSend && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onUpdateErp}
                >
                  <MdWebhook style={{ marginRight: 10 }} />
                  {uiText.updateERPTooltip[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsSent && userCanSend && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onUpload}
                >
                  <UploadFileOutlinedIcon style={{ marginRight: 10 }} />
                  {uiText.uploadTooltip[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsCancel && userCanCancel && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={onAadeCancel}
                >
                  <BsXCircle style={{ marginRight: 10 }} />
                  {uiText.detail_cancel_document[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsInitialize && userCanInitialise && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={() => {
                    handleOpenInitializeDialog(true);
                  }}
                >
                  <BsFile style={{ marginRight: 10 }} />
                  {uiText.detail_initialize_document[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsEdit && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={() => {
                    handleEditFormDialogOpen(true);
                  }}
                >
                  <FaRegCommentAlt style={{ marginRight: 10 }} />
                  {uiText.edit_document[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsAadeQrCode && (
                <Button
                  style={{ margin: "0rem 0.3rem" }}
                  variant="contained"
                  onClick={() => {
                    handleOpenAadeQrCodeDialog(true);
                  }}
                >
                  <QrCode2Icon style={{ marginRight: 10 }} />
                  {uiText.aade_qr_code[uiLang]}
                </Button>
              )}
              {buttonsAreActive && buttonIsSend && buttonIsSynchronize && (
                <>
                  <Button
                    variant="contained"
                    disabled={loading}
                    style={{ margin: "0rem 0.3rem" }}
                    onClick={onXmlModal}
                    startIcon={<CodeIcon />}
                  >
                    XML
                  </Button>
                  <Modal
                    open={xmlModalOpen}
                    onClose={() => handleXmlModal(false)}
                    style={{ backdropFilter: "brightness(40%)" }}
                  >
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 9,
                        maxHeight: "65%",
                        outline: "none",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "#002451",
                          // border: '1px solid #fff',
                          color: "white",
                          display: "block",
                          lineHeight: 1,
                          overflow: "auto",
                          paddingLeft: 3,
                          maxWidth: "65%",
                        }}
                      >
                        <pre>
                          <code
                            dangerouslySetInnerHTML={{ __html: formattedXml }}
                          ></code>
                        </pre>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: 1,
                        }}
                      >
                        <Button
                          variant="contained"
                          style={{ margin: "0rem 0.3rem" }}
                          onClick={copyXmlToClipboard}
                        >
                          <CopyAllIcon style={{ marginRight: 10 }} />
                          {uiText.copy[uiLang]}
                        </Button>
                        <Button
                          variant="contained"
                          style={{ margin: "0rem 0.3rem" }}
                          onClick={downloadXML}
                        >
                          <FileDownloadIcon style={{ marginRight: 10 }} />
                          {uiText.download[uiLang]}
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            margin: "0rem 0.3rem",
                            backgroundColor: "#949494",
                          }}
                          onClick={() => handleXmlModal(false)}
                        >
                          <CloseIcon style={{ marginRight: 10 }} />
                          {uiText.close[uiLang]}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </>
              )}
            </Grid>
          </Grid>
        )}
        <Accordion
          elevation={0}
          disableGutters={true}
          style={{ marginBottom: "10px" }}
          expanded={expandedAccordionStatus.detail_general_items}
          onChange={handleAccordionOnChange("detail_general_items")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            style={{
              backgroundColor: "#243e71",
              color: "white",
              position: "relative",
            }}
          >
            <Typography>{uiText.detail_general_items[uiLang]}</Typography>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                toggleAccordionExpandAll();
              }}
              size="small"
              sx={{
                color: "white",
                position: "absolute",
                right: 48,
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              <ExpandIcon />
            </IconButton>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"column"} style={{ overflowX: "auto" }}>
              {docDetails.map((detail, i) => {
                return (
                  <div key={i}>
                    <Grid
                      item
                      container
                      // spacing={2}
                      direction={"row"}
                      justifyContent={"space-around"}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        display={"flex"}
                        style={{ marginTop: 5 }}
                      >
                        <Typography
                          style={{
                            minWidth: 180,
                            color: "#243e71",
                            whiteSpace: "nowrap",
                            fontSize: 16,
                            fontWeight: "bold",
                            marginRight: 20,
                          }}
                        >
                          {detail[0].label}
                        </Typography>
                        <DetailsInput value={detail[0].value} index={i} />
                      </Grid>
                      {detail[1] ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            display={"flex"}
                            style={{ marginTop: 5 }}
                          >
                            <Typography
                              style={{
                                minWidth: 180,
                                color: "#243e71",
                                whiteSpace: "nowrap",
                                fontSize: 16,
                                fontWeight: "bold",
                                marginRight: 20,
                              }}
                            >
                              {detail[1].label}
                            </Typography>

                            <DetailsInput
                              value={detail[1].value}
                              index={"detail1" + i}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            display={"flex"}
                          >
                            <Typography
                              style={{
                                color: "white",
                                whiteSpace: "nowrap",
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {}
                            </Typography>

                            <Input
                              disableUnderline
                              type="text"
                              value={""}
                              style={{ textAlign: "center" }}
                              sx={{
                                border: "none",
                                width: "auto",
                              }}
                              readonly
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {(i === 8 || i === 11) && (
                      <Divider
                        style={{ marginTop: 10, marginBottom: 10 }}
                        variant="fullWidth"
                      />
                    )}
                  </div>
                );
              })}
              {document && document.correlated_documents.length > 0 && (
                <Divider
                  style={{ marginTop: 10, marginBottom: 10 }}
                  variant="fullWidth"
                />
              )}
              {props.disableControls !== true &&
                document &&
                document.correlated_documents.length > 0 && (
                  <>
                    <Grid
                      item
                      container
                      // spacing={2}
                      direction={"row"}
                      justifyContent={"space-around"}
                      alignItems={"center"}
                    >
                      {document.correlated_documents.map((doc, i) => {
                        return (
                          <Grid
                            key={doc.id}
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            lg={6}
                            xl={6}
                            display={"flex"}
                            style={{ marginTop: 5 }}
                          >
                            <Typography
                              style={{
                                minWidth: 180,
                                color: "#243e71",
                                whiteSpace: "nowrap",
                                fontSize: 16,
                                fontWeight: "bold",
                              }}
                            >
                              {doc.origin_type === document.origin_type
                                ? uiText["CorrelatedDocument"][uiLang] +
                                  " " +
                                  (i + 1)
                                : uiText["MatchedDocument"][uiLang] +
                                  " " +
                                  (i + 1)}
                            </Typography>

                            <Grid
                              container
                              style={{
                                flexWrap: "nowrap",
                                position: "relative",
                              }}
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              display={"flex"}
                            >
                              <Typography
                                style={{
                                  maxWidth: 400,
                                  fontSize: 14,
                                  minWidth: 60,
                                  fontWeight: 500,
                                  width: "100%",
                                  backgroundColor: "rgba(100,100,100,0.2)",
                                  height: 30,
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                              >
                                {doc.document_series +
                                  " " +
                                  doc.document_number}
                              </Typography>
                              <IconButton>
                                <FiSearch
                                  size={20}
                                  onClick={() => {
                                    handleClickOpen(doc.id);
                                  }}
                                />
                              </IconButton>
                              {/* <div style={{ width: 30, paddingLeft: '20px', paddingTop: '7px' }}>
                              
                            </div> */}
                            </Grid>
                            {/* <DetailsInput value={doc.id} /> */}
                          </Grid>
                        );
                      })}
                      {document.correlated_documents.length % 2 === 1 && (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={6}
                          lg={6}
                          xl={6}
                          display={"flex"}
                          style={{ marginTop: 5 }}
                        >
                          <></>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {isB2G && (
          <Accordion
            elevation={0}
            disableGutters={true}
            style={{ marginBottom: "10px" }}
            expanded={expandedAccordionStatus.detail_b2g_items}
            onChange={handleAccordionOnChange("detail_b2g_items")}
          >
            <AccordionSummary
              style={{ backgroundColor: "#243e71", color: "white" }}
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
            >
              <Typography>{uiText.detail_b2g_items[uiLang]}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <DocumentDetailsB2G
                data={document}
                metadata={documentMetadata}
                uiLang={uiLang}
                handleDocumentSave={onPatchDocuments}
              />
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          elevation={0}
          disableGutters={true}
          style={{ marginBottom: "10px" }}
          expanded={expandedAccordionStatus.detail_summary}
          onChange={handleAccordionOnChange("detail_summary")}
        >
          <AccordionSummary
            style={{ backgroundColor: "#243e71", color: "white" }}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Typography>{uiText.detail_summary[uiLang]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomTable
              drawerDisplay="none"
              drawerTop={0}
              enable_overflow={true}
              setMaxWidth={200}
              setOverflowX="auto"
              setOverflowY="hidden"
              showSearch={false}
              uniquePrefix="summaryCustomTable"
              columns={
                summaryArray &&
                summaryArray.map((column, si) => {
                  const columnLabel =
                    uiSummary[column] && uiSummary[column][uiLang]
                      ? uiSummary[column][uiLang]
                      : column;
                  return {
                    key: `summary-${column.name}-${si}`,
                    name: column,
                    label: columnLabel,
                    options: {
                      sort: true,
                      display: true,
                      filter_display: false,
                    },
                  };
                })
              }
              data={document && document.document_summary}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          disableGutters={true}
          style={{ marginBottom: "10px" }}
          expanded={expandedAccordionStatus.detail_document_details}
          onChange={handleAccordionOnChange("detail_document_details")}
        >
          <AccordionSummary
            style={{ backgroundColor: "#243e71", color: "white" }}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Typography>{uiText.detail_document_details[uiLang]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomTable
              drawerDisplay="none"
              drawerTop={0}
              enable_overflow={true}
              setMaxWidth={200}
              setOverflowX="auto"
              setOverflowY="hidden"
              showSearch={false}
              uniquePrefix="detailCustomTable"
              columns={detailArray.map((column, di) => {
                const columnLabel =
                  uiTable[column] && uiTable[column][uiLang]
                    ? uiTable[column][uiLang]
                    : column;
                return {
                  key: `detail-${column.name}-${di}`,
                  name: column,
                  label: columnLabel,
                  options: {
                    width: "auto",
                    sort: true,
                    display: true,
                    filter_display: false,
                  },
                };
              })}
              data={document && document.document_details}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          disableGutters={true}
          style={{ marginBottom: "10px" }}
          expanded={expandedAccordionStatus.detail_send_status}
          onChange={handleAccordionOnChange("detail_send_status")}
        >
          <AccordionSummary
            style={{ backgroundColor: "#243e71", color: "white" }}
            expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
          >
            <Typography>{uiText.detail_send_status[uiLang]}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              className="StatusContainer"
              container
              direction={"column"}
              style={{
                marginTop: 10,
                paddingRight: 30,
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              {document &&
                document.audit_logs &&
                document.audit_logs.map((log, i, arr) => {
                  return (
                    <div
                      key={`auditlog-${log.id}-${i}`}
                      className="DetailsStatusOuterDiv"
                    >
                      <div className="DetailsStatusCircles"></div>
                      <div className="DetailsStatusTextDiv">
                        <h3>
                          {log.origin_type in uiText
                            ? uiText[log.origin_type][uiLang]
                            : log.origin_type}
                        </h3>
                        <p>{log.created_at}</p>
                        {log.statement && log.statement.document_status && (
                          <p>
                            {log.statement.document_status[1] in uiText
                              ? uiText[log.statement.document_status[1]][uiLang]
                              : log.statement.document_status[1]}
                          </p>
                        )}
                        {log.statement &&
                          log.statement.document_tags &&
                          log.statement.document_tags[1] &&
                          log.statement.document_tags[1]
                            .document_classifations &&
                          log.statement.document_tags[1].document_classifations.map(
                            (classification, ci) => {
                              return (
                                <p key={`classification-${i}-${ci}`}>
                                  {!("classification" in uiTag)
                                    ? uiTag[classification][uiLang]
                                    : classification}
                                </p>
                              );
                            }
                          )}
                        {log.statement &&
                          log.statement.document_tags &&
                          log.statement.document_tags[1] &&
                          log.statement.document_tags[1]
                            .document_transmittions &&
                          log.statement.document_tags[1].document_transmittions.map(
                            (transmittion, ti) => {
                              return (
                                <p key={`transmittion-${i}-${ti}`}>
                                  {!("transmittion" in uiTag)
                                    ? uiTag[transmittion][uiLang]
                                    : transmittion}
                                </p>
                              );
                            }
                          )}
                        {log.statement &&
                          log.statement.document_tags &&
                          log.statement.document_tags[1] &&
                          log.statement.document_tags[1].aade_responses &&
                          log.statement.document_tags[1].aade_responses.map(
                            (response, ri) => {
                              return (
                                <p key={`response-${i}-${ri}`}>{response}</p>
                              );
                            }
                          )}
                      </div>
                    </div>
                  );
                })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Snackbar
        open={NotificationState}
        autoHideDuration={3600}
        onClose={() => {
          setNotificationState(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotificationState(false);
          }}
          variant="filled"
          severity={
            notificationMessage.includes("error") ||
            notificationMessage.includes("σφάλμα")
              ? "warning"
              : "success"
          }
          sx={{
            fontSize: 20,
            fontFamily: "monospace",
          }}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={openInitializeDialog}
        onClose={() => {
          handleOpenInitializeDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {uiText.detail_initialize_document_question[uiLang]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {uiText.detail_initialize_document_warning[uiLang]}
            <br />
            {uiText.detail_initialize_document_warning_msg[uiLang]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleOpenInitializeDialog(false);
            }}
          >
            {uiText.ui_no[uiLang]}
          </Button>
          <Button onClick={onInitializeDocument} autoFocus>
            {uiText.ui_yes[uiLang]}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={openEditFormDialog}
        onClose={() => {
          handleEditFormDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {openEditFormDialog && (
          <DocumentDetailsForm
            document={document}
            uiLang={uiLang}
            handleDocumentSave={onPatchDocuments}
            handleEditFormDialogOpen={handleEditFormDialogOpen}
          />
        )}
      </Dialog>
      <AadeQrCodeDialog
        open={isAadeQrCodeDialogOpen}
        handleClose={handleOpenAadeQrCodeDialog}
        text={(document && document.aade_qr_code_url) || ""}
      />
      {isShowTopButton && (
        <IconButton
          onClick={scrollToTop}
          sx={{
            position: "fixed",
            bottom: "3rem",
            right: "3rem",
            zIndex: 1000,
            backgroundColor: "#243e71",
            color: "white",
            border: "0.5px solid white",
            "&:hover": {
              backgroundColor: "#1565c0",
            },
          }}
        >
          <ArrowUpwardIcon />
        </IconButton>
      )}
    </>
  );
};

export default DocumentDetails;
