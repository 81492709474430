import React, { useState, Fragment } from "react";
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import { MdContentCopy } from "react-icons/md";

export default function DetailsInput(props) {
  const { value, index } = props;



  const [showCopy, setShowCopy] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
      setTimeout(() => {
        setCopySuccess("");
      }, 2000);
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  return (
    <Fragment key={index}>
      <Tooltip title={value} placement="bottom" enterDelay={300} leaveDelay={100}>
        <Typography
          noWrap

          onClick={() => copyToClipBoard(value)}
          onMouseOver={() => {
            setShowCopy(true);
          }}
          onMouseLeave={() => setShowCopy(false)}
          sx={{
            width: '100%',
            fontSize: 14,
            fontWeight: 500,
            backgroundColor: "rgba(100,100,100,0.2)",
            height: 30,
            cursor: "pointer",
            textAlign: "center",
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: 600,
          }}
        >
          {value}
        </Typography>
      </Tooltip>
      <Icon style={{ width: 30, marginLeft: 1 }}>
        {showCopy && copySuccess === "" && (
          <MdContentCopy style={{ fontSize: 18, color: "#243e71" }} />
        )}
        {copySuccess !== "" && (
          <CheckIcon style={{ fontSize: 18, color: "green" }} />
        )}
      </Icon>
    </Fragment>
  );
}
