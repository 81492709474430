import { Pagination } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import React from 'react';
import { BsInfoCircleFill } from "react-icons/bs";
import uiText from "../context/translation.json";
import './Card.css';

const Card = ({
  allDocuments,
  selectedDocuments,
  clickFunction,
  alignment,
  filters,
  pageFunction,
  currentPage,
  detailFunction,
  disableButtons,
  loading,
  uiLang,
  title
}) => {
  const itemsPerPage = 50;

  const isUndefined = (value) => { return value === undefined || value === null }

  const decideToDisplayBasedOnFilters = (doc) => {
    if (filters.vatNumber !== '' && (isUndefined(doc?.partner?.vat_number) || (!isUndefined(doc?.partner?.vat_number) && !doc?.partner?.vat_number?.toUpperCase()?.includes(filters?.vatNumber?.toUpperCase()))))
      return false
    if (filters.partnerName !== '' && (isUndefined(doc?.partner?.name) || (!isUndefined(doc?.partner?.name) && !doc?.partner?.name?.toUpperCase()?.includes(filters?.partnerName?.toUpperCase()))))
      return false
    if (filters.documentNumber !== '' && (isUndefined(doc?.document_number) || (!isUndefined(doc?.document_number) && !doc?.document_number?.toUpperCase()?.includes(filters?.documentNumber?.toUpperCase()))))
      return false
    if (filters.netAmount !== '' && (isUndefined(doc?.net_value) || (!isUndefined(doc?.net_value) && !doc?.net_value?.replace(".", '')?.startsWith(filters?.netAmount?.replace(".", '')))))
      return false
    if (filters.vatAmount !== '' && (isUndefined(doc?.vat_amount) || (!isUndefined(doc?.vat_amount) && !doc?.vat_amount?.replace(".", '')?.startsWith(filters?.vatAmount?.replace(".", '')))))
      return false
    if (filters.totalAmount !== '' && (isUndefined(doc?.gross_value) || (!isUndefined(doc?.gross_value) && !doc?.gross_value?.replace(".", '')?.startsWith(filters?.totalAmount?.replace(".", '')))))
      return false
    return true
  }

  let documentsForDisplay
  // we display documents that are filtered OR selected
  if (alignment === 'missed_transmission' || alignment === 'non_acceptable') {
    documentsForDisplay = allDocuments.filter((doc) => decideToDisplayBasedOnFilters(doc) || doc.isSelected)
  }

  // we display documents that are selected and if none are selected then show the filtered
  if (['full_connection', 'partial_connection', 'partial_connection2_95'].includes(alignment)) {
    documentsForDisplay = selectedDocuments.length > 0 ?
      selectedDocuments :
      allDocuments.filter((doc) => decideToDisplayBasedOnFilters(doc))
  }

  // paginate
  const totalPageCount = Math.ceil(documentsForDisplay.length / itemsPerPage);
  const paginatedDocumentsToDisplay = documentsForDisplay.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  return (
    <>
      <div style={{ width: '48%', height: '750px', border: '1px solid rgba(0, 0, 0,0.5)', borderRadius: '20px', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '0rem 2rem', margin: '0rem 1rem' }}>
          {loading && <span className="spans">{`${uiText.no_match_yet_loading_documents[uiLang]}...`}</span>}
          {!!allDocuments.length &&
            <>
              <p>
                <span className="spans">{uiText.no_match_yet_result_documents[uiLang]}</span>: {`${documentsForDisplay.length}/${allDocuments.length}`}
                <br />
                <span className="spans">{uiText.no_match_yet_selected_documents[uiLang]}</span>: {selectedDocuments.length}
              </p>
            </>
          }
          {!!allDocuments.length &&
            <div style={{ width: "40%", display: "flex", alignItems: 'center', justifyContent: 'flex-end' }} >
              <Pagination size='small' siblingCount={1} boundaryCount={1} count={totalPageCount} page={currentPage} onChange={(event, value) => { pageFunction(value, title, event) }} />
            </div>
          }
        </div>
        <div style={{ position: 'absolute', width: '110px', height: '110px', borderRadius: '90px', top: '-55px', border: '2px solid rgba(0, 0, 0,0.5)', backgroundColor: 'white', zIndex: 3 }}></div>
        <div style={{ position: 'absolute', width: '100px', height: '100px', borderRadius: '90px', top: '-50px', backgroundColor: 'rgba(36, 62, 113)', display: 'flex', justifyContent: 'center', alignContent: 'center', boxShadow: '1px 2px 3px 4px rgba(12,12,12,0.2)', zIndex: 4 }}>
          <p style={{ color: 'white', fontSize: '28px', paddingRight: '2px', paddingTop: '2px' }}>{title}</p>
        </div>
        <div className="test" style={{ display: !allDocuments.length && 'flex' }} >
          {!!paginatedDocumentsToDisplay.length && paginatedDocumentsToDisplay.map((test) => {
            return (
              <div className="test2" key={test.id}>
                <div className="test3" id={test.id}>
                  <Grid container item xs={12} style={{ padding: "1px 1px 1px 10px" }}>
                    <Grid item xs={4}>
                      <p style={{}}><span className="spans">{uiText['document_date'][uiLang]}</span>: {test?.document_date}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ marginLeft: '0px' }}><span className="spans">{uiText['series'][uiLang]}</span>: {test?.document_series}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ marginLeft: '0px' }}><span className="spans">{uiText['document_no'][uiLang]}</span>: {test?.document_number}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p><span className="spans">{uiText["partner.vat_number"][uiLang]}</span>: {test?.partner?.vat_number}</p>
                    </Grid>
                    <Grid item xs={8} style={{ paddingLeft: '0px' }}>
                      <p><span className="spans">{uiText['partner.name'][uiLang]}</span>: {test?.partner?.name}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p><span className="spans">{uiText["net_amount"][uiLang]}</span>: {test?.net_value}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ marginLeft: '0px' }}><span className="spans">{uiText['vat_amount'][uiLang]}</span>: {test?.vat_amount}</p>
                    </Grid>
                    <Grid item xs={4}>
                      <p><span className="spans">{uiText["total_amount"][uiLang]}</span>: {test?.gross_value}</p>
                    </Grid>
                  </Grid>
                  <div style={{display: "flex", flexDirection: "column", maxHeight: "130px", justifyContent: "space-evenly", alignItems: "center",}}>
                    <div className="container" onClick={() => { detailFunction(test.id) }}>
                      <BsInfoCircleFill style={{ color: 'rgba(36, 62, 113, 1)', fontSize: '30px' }} />
                    </div>
                    <div className="container">
                      <Checkbox
                        checked={test['isSelected']}
                        onChange={(event) => { clickFunction(test.id, title, event) }}
                        disabled={disableButtons}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                </div>

              </div>
            )
          })}
          {(paginatedDocumentsToDisplay.length === 0 && !loading) &&
            <h3 style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }}>
              <span style={{ color: 'rgba(36, 62, 113, 1)', fontWeight: 'bolder' }}>{'0 '}</span>
              {uiText['availableDocument'][uiLang]}
            </h3>
          }
        </div>
      </div>

    </>
  )
}

export default Card