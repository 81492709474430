import { useState, useEffect } from "react";
import { MdListAlt, MdDelete } from "react-icons/md";
import { BsLayoutThreeColumns } from "react-icons/bs";
import { TiDelete } from "react-icons/ti";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
// import "./CustomTable.css";
import Autocomplete from '@mui/material/Autocomplete';
import { RiFilterOffFill } from "react-icons/ri";
import column_settings from "./CustomTableColumns.json";
import columns_names from "../context/custom_table.json"
import SearchInput from "./SearchInput";
import uiText from "../context/translation.json";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const useStylesSx = {
  customTableContainerSx: {
    overflowX: "initial",
    zIndex: 9,
    backgroundColor: "white",
  },
};
const CustomTable = ({
  showMenuLabels,
  drawerWidth,
  columns,
  data,
  options = {
    checkbox_visible: false,
    details_visible: false,
    showColumnSetting: false,
    create_new_gl_account: false
  },
  allreadySelected,
  searchTerms,
  filters,
  category,
  status,
  onFilterChange,
  onColumnSettingChange,
  onDocumentSelect,
  disabled,
  createNewGlAccount,
  setSearchTerms,
  showSearch,
  uiLang,
  aade_data,
  handleGlAccountChange,
  deleteGlAccount,
  related_table = null,
  enable_overflow = false,
  uniquePrefix = '',
  setMaxWidth = 500,
  setOverflowX,
  setOverflowY
}) => {
  const [columnFilterStatus, setColumnFilterStatus] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [reset, setReset] = useState(false);
  // let regNumber = /^[0-9,'-',',','.']+$/;
  // let regGL = /^[0-9,'.']+$/;
  // let regNumberOnly = /^[0-9]+$/;

  if (true) {
    import("./CustomTable.css")
  }
  // console.log(data)
  // const classesSx = useStylesSx();
  useEffect(() => {
    if (options.checkbox_visible) {
      let isCheckAllTrue = true;
      if (data.length > 0) {
        data.forEach((data) => {
          if (!allreadySelected.includes(data.id)) isCheckAllTrue = false;
        });
      } else {
        isCheckAllTrue = false;
      }
      setCheckAll(isCheckAllTrue);
    }
  }, [data, allreadySelected, options.checkbox_visible]);

  const filterUpdate = (event, column) => {
    setSearchTerms(() => {
      const newTerms = { ...searchTerms };
      newTerms[column.name] = event.target.value;
      onFilterChange(newTerms);
      // console.log(newTerms[column.name]);
      return newTerms;
    });

    //  filters[column["name"]] = event.target.value;
    // onFilterChange({ ...searchTerms });
  };

  const showColumnSettings = (event) =>
    setColumnFilterStatus(!columnFilterStatus);
  const onColumnSettingClick = (event, isCheckValue) =>
    onColumnSettingChange(event.target.id, isCheckValue);
  const onDocumentSelectInternal = (event, isCheckValue) =>
    onDocumentSelect([data[Number(event.target.id.split(",")[1])]], isCheckValue);
  const onCheckAll = (event, isCheckValue) => {
    setCheckAll(isCheckValue);
    const ArrayId = [];
    data.forEach((document) => {
      ArrayId.push(document);
    });
    onDocumentSelect(ArrayId, isCheckValue);
  };
  const resetFilters = () => {
    // console.log("resetFilters");
    setReset(!reset);
    onFilterChange({});
  };

  return (
    <div className="customTableContainer" style={{ width: enable_overflow ? '100%' : 'none', overflowX: !enable_overflow && 'none' }}  >
      <div
        className="customTableContainerDiv"
        style={{
          marginLeft: (!setOverflowX || !setOverflowY) && 20,
          width: "100%",
          position: "relative",
          marginTop: showSearch ? 160 : 0,
          overflowX: setOverflowX,
          overflowY: setOverflowY,
        }}
      >
        <div
          style={{
            zIndex: 14,
            position: "fixed",
            width: columnFilterStatus ? "auto" : "auto",
            backgroundColor: "white",
            left: showMenuLabels ? 200 : 80,
            top: 300,

          }}
        >
          <div
            className="CustomColumnSettings"
            style={{
              position: "relative",
              height: 70,
              display: columnFilterStatus ? "block" : "none",
            }}
          >
            <div>
              <p>Filters</p>
              <button
                className={"CustomTableButtons"}
                onClick={showColumnSettings}
              >
                <TiDelete />
              </button>
            </div>
          </div>

          <div
            className="CustomColumnOuterDiv"
            style={{ display: columnFilterStatus ? "block" : "none" }}
          >
            {columns != null &&
              columns.map((column) => {
                if (column.name === "id") return null;
                return (
                  <div key={`${uniquePrefix}-${column.name}`}>
                    <Checkbox
                      onChange={onColumnSettingClick}
                      checked={column.options.display}
                      id={column.name}
                      sx={{
                        color: "#243e71",
                        "&.Mui-checked": {
                          color: "#243e71",
                        },
                      }}
                    />
                    <p>{column.label}</p>
                  </div>
                );
              })}
          </div>
        </div>

        {/* <div
        // className="CustomColumnIcon"
        style={{
          zIndex: 9,
          position: "absolute",
          top: -30,
          right: 30,
          display: options.showColumnSetting ? "flex" : "none",
        }}
      >
        <button className={"CustomTableButtons"} onClick={showColumnSettings}>
          <BsLayoutThreeColumns fontSize="30px" />
        </button>
      </div> */}
        {/* <div className="WeConnectTableOuterDiv"> */}
        <TableContainer sx={useStylesSx.customTableContainerSx}>
          <Table stickyHeader={!enable_overflow} className="WeConnectTable">
            <TableHead
              style={{
                backgroundColor: "#fff",
                position: !enable_overflow ? "sticky" : 'normal',
                top: 50,
                zIndex: 8,
              }}
            >
              <TableRow>
                <TableCell
                  style={{
                    verticalAlign: "bottom",
                    position: !enable_overflow ? "sticky" : 'normal',
                    left: showMenuLabels ? 90 + drawerWidth : 90,
                    zIndex: 9,
                    paddingBottom: 0,
                    backgroundColor: "#fff",
                    display: !options.checkbox_visible && "none",
                  }}
                >
                  <BsLayoutThreeColumns
                    onClick={showColumnSettings}
                    fontSize="30px"
                  />
                </TableCell>
                <TableCell
                  className="CheckAllTh"
                  style={{
                    backgroundColor: "#fff",
                    display: !options.checkbox_visible && "none", //  || data.length === 0
                  }}
                ></TableCell>
                <TableCell
                  key="gl_accounts"
                  style={{
                    // position: "-webkit-sticky",


                    backgroundColor: "#fff",
                    left: showMenuLabels ? 120 + drawerWidth : 0,
                    zIndex: 8,
                    verticalAlign: "bottom",

                    padding: 2,
                    margin: 0,
                    display: !options.create_new_gl_account && "none",
                  }}
                >
                  <p
                    data-theme="dark"
                    style={{
                      flexWrap: "wrap",
                      wordWrap: "break-word",
                      hyphens: "auto",
                      textAlign: "center",
                    }}

                  >
                    <Button onClick={createNewGlAccount} variant="text">{columns_names["create_new_gl_account"][uiLang]}</Button>
                  </p>
                </TableCell>
                {columns != null &&
                  columns.map((column, index) => {
                    return (
                      <TableCell
                        key={`${uniquePrefix}-${column.label + index}`}
                        style={{
                          // position: "-webkit-sticky",

                          position: !enable_overflow ? "sticky" : 'normal',
                          backgroundColor: "#fff",
                          left: showMenuLabels ? 120 + drawerWidth : 120,
                          zIndex: 8,
                          verticalAlign: "bottom",
                          maxWidth: 105,
                          padding: 2,
                          margin: 0,
                          display: !column.options.display && "none",
                        }}
                      >
                        <p
                          style={{
                            color: showSearch ? "" : "#243e71",
                            fontWeight: showSearch ? "" : "bold",
                            flexWrap: "wrap",
                            wordWrap: "break-word",
                            hyphens: "auto",
                            textAlign: "center",
                          }}
                        >
                          {column.label}
                        </p>
                      </TableCell>
                    );
                  })}
              </TableRow>
              {showSearch && (
                <TableRow>
                  <TableCell
                    className="gl_accounts"
                    style={{
                      backgroundColor: "#fff",
                      display: !options.create_new_gl_account && "none", //  
                      padding: '10px'
                    }}
                  >
                    <div style={{ padding: '10px' }}></div>
                  </TableCell>
                  <TableCell
                    style={{
                      // position: "-webkit-sticky",
                      position: !enable_overflow ? "sticky" : 'normal',
                      backgroundColor: "#fff",
                      left: showMenuLabels ? 65 + drawerWidth : 65,
                      zIndex: 8,
                      padding: "0px 6px 0px 6px",
                      margin: 0,
                      display: !options.checkbox_visible && "none",
                    }}
                  // style={{ display: !options.checkbox_visible && "none" }}
                  >
                    <IconButton onClick={resetFilters}>
                      <RiFilterOffFill size={22} style={{ color: "#243e71" }} />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    className="CheckAllTh"
                    style={{
                      // position: "-webkit-sticky",
                      position: !enable_overflow ? "sticky" : 'normal',
                      backgroundColor: "#fff",
                      left: showMenuLabels ? 120 + drawerWidth : 120,
                      zIndex: 8,
                      padding: "0px 6px 0px 6px",
                      margin: 0,

                      display: !options.checkbox_visible && "none", // || data.length === 0
                    }}
                  // style={{
                  //   display:
                  //     (!options.checkbox_visible || data.length === 0) && "none",
                  // }}
                  >
                    <Checkbox
                      id={`${uniquePrefix}-${"checkBoxAll"}`}
                      className="CheckAllInput"
                      onChange={onCheckAll}
                      checked={checkAll}
                      sx={{
                        color: "#243e71",
                        "&.Mui-checked": {
                          color: "#243e71",
                        },
                      }}
                    />

                  </TableCell>

                  {columns != null &&
                    columns.map((column, index) => {
                      return (
                        <TableCell
                          key={`${uniquePrefix}-${column.label + index + column.label}`}
                          style={{
                            // position: "-webkit-sticky",
                            position: !enable_overflow ? "sticky" : 'normal',
                            // backgroundColor: "#fff",
                            left: showMenuLabels ? 60 + drawerWidth : 60,
                            zIndex: 4,
                            padding: "0px 6px 0px 6px",
                            margin: 0,
                            display: !column.options.display && "none",
                          }}
                        >
                          <SearchInput
                            key={`input-${uniquePrefix}-${column.label + index + column.label}`}
                            index={index}
                            reset={reset}
                            onChange={(e) => filterUpdate(e, column)}
                            // disabled={!column.options.filter && true} I don;t know why this is here
                            disabled={disabled}
                            placeholder={column.label}
                          />
                        </TableCell>
                      );
                    })}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {data != null && data.length > 0 ? (
                data.map((current_data, index) => {
                  return (
                    <TableRow key={`${uniquePrefix}-${current_data.id + "," + index}`}>
                      <TableCell
                        style={{
                          // position: "-webkit-sticky",
                          position: !enable_overflow ? "sticky" : 'normal',
                          backgroundColor: "#fff",
                          left: showMenuLabels ? 60 + drawerWidth : 60,
                          zIndex: 3,
                          padding: "0px 6px 0px 6px",
                          margin: 0,
                          display: !options.details_visible && "none",
                        }}
                      >
                        <Link
                          className={"CustomTableButtons"}
                          to={`/${category}/${status}/${current_data.id}`}
                        >
                          <MdListAlt fontSize="30px" id={`${uniquePrefix}-${current_data.id}`} />
                        </Link>
                      </TableCell>
                      <TableCell
                        className="gl_accounts"
                        style={{
                          backgroundColor: "#fff",
                          display: !options.create_new_gl_account && "none", //  
                        }}
                      >
                        <button
                          className={"CustomTableButtons"}
                          onClick={() => deleteGlAccount(current_data)}
                          style={{ marginLeft: '40%' }}
                        >
                          <MdDelete size={30} />
                        </button>

                      </TableCell>
                      <TableCell
                        style={{
                          // position: "-webkit-sticky",
                          position: !enable_overflow ? "sticky" : 'normal',
                          background: "#fff",
                          left: showMenuLabels ? 120 + drawerWidth : 120,
                          top: 60,
                          zIndex: 3,
                          padding: "0px 6px 0px 6px",
                          margin: 0,
                          display: !options.details_visible && "none",
                        }}
                      >
                        <Checkbox
                          onChange={onDocumentSelectInternal}
                          id={`${uniquePrefix}-${"checkBox," + index}`}
                          checked={
                            options.checkbox_visible &&
                            allreadySelected.includes(`${current_data.id}`) // || checkAll
                          }
                          sx={{
                            color: "#243e71",
                            "&.Mui-checked": {
                              color: "#243e71",
                            },
                          }}
                        />
                      </TableCell>
                      {columns &&
                        columns.map((current_column, index) => {

                          const cols = current_column.name.split(".");
                          let dataToPrint =
                            cols.length === 1
                              ? current_data[current_column.name]
                              : current_data[cols[0]] != null
                                ? current_data[cols[0]][cols[1]]
                                : null;
                          const error_color =
                            current_column.name === "error_message"
                              && !dataToPrint.includes("Succ")
                              ? "#d30404f7"
                              : "black";
                          if ((current_column.name === "aade_category" || current_column.name === "aade_type") && related_table !== null && related_table === 'GLACCOUNTS') {
                            return (
                              <TableCell
                                key={`${uniquePrefix}-${current_column.name + ", " + index}`}
                                style={{
                                  // border: showSearch? "none": "1px solid black",
                                  padding: "0px 6px 0px 6px",
                                  margin: 0,
                                  color: `${error_color}`,
                                  // maxWidth: 220,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  textAlign: `${column_settings[current_column.name]
                                    }`,
                                  whiteSpace: "nowrap",
                                  display:
                                    !current_column.options.display && "none",
                                }}
                              >
                                <Tooltip
                                  title={
                                    (dataToPrint === false &&
                                      typeof dataToPrint != Boolean) ||
                                      (dataToPrint === true &&
                                        typeof dataToPrint != Boolean)
                                      ? dataToPrint === true
                                        ? uiText.ui_yes[uiLang]
                                        : uiText.ui_no[uiLang]
                                      : dataToPrint === null ||
                                        dataToPrint === undefined
                                        ? ""
                                        : dataToPrint
                                  }
                                  placement="right"
                                >

                                  <Autocomplete
                                    {... {
                                      options: current_data["invoice"] != null ? aade_data[current_column.name].filter((dato) => dato.document_type.toUpperCase() === current_data["invoice"].toUpperCase() ? true : false) : [],
                                      getOptionLabel: (option) => option[uiLang] ? option[uiLang] : option,
                                    }}

                                    value={current_data[current_column.name]}
                                    id="aade_invoice_type"
                                    //isOptionEqualToValue = {(option,value) =>  true}
                                    onChange={(event, value) => { handleGlAccountChange(value, current_data, current_column.name) }}

                                    renderInput={(params) => (
                                      <TextField {...params} label={uiText[current_column.name][uiLang]} variant="standard" />
                                    )}
                                  />
                                </Tooltip>
                              </TableCell>
                            )
                          }
                          else {
                            return (
                              <TableCell
                                key={`${uniquePrefix}-${current_column.name + ", " + index}`}
                                style={{
                                  // border: showSearch? "none": "1px solid black",
                                  padding: "0px 6px 0px 6px",
                                  margin: 0,
                                  color: `${error_color}`,
                                  maxWidth: setMaxWidth,
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  textAlign: `${column_settings[current_column.name]
                                    }`,
                                  whiteSpace: "nowrap",
                                  display:
                                    !current_column.options.display && "none",
                                }}
                              >
                                <Tooltip
                                  title={
                                    (dataToPrint === false &&
                                      typeof dataToPrint != Boolean) ||
                                      (dataToPrint === true &&
                                        typeof dataToPrint != Boolean)
                                      ? dataToPrint === true
                                        ? uiText.ui_yes[uiLang]
                                        : uiText.ui_no[uiLang]
                                      : dataToPrint === null ||
                                        dataToPrint === undefined
                                        ? ""
                                        : dataToPrint
                                  }
                                  placement="right"
                                >
                                  <span>
                                    {(dataToPrint === false &&
                                      typeof dataToPrint != Boolean) ||
                                      (dataToPrint === true &&
                                        typeof dataToPrint != Boolean)
                                      ? dataToPrint === true
                                        ? uiText.ui_yes[uiLang]
                                        : uiText.ui_no[uiLang]
                                      : dataToPrint}
                                  </span>
                                </Tooltip>
                              </TableCell>
                            )
                          }
                        })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow className="NoDataRow">
                  <td colSpan={20}>{uiText.ui_table_no_records[uiLang]}</td>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CustomTable;
