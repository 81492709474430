import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { FaExclamationTriangle } from "react-icons/fa";
import styles from "./App.module.css";
import {
  getAccessToken,
  getAzureAuth,
  getUserCredentials,
  getUserLang,
  removeAccessToken,
  removeAzureAuth,
  removeCredentials,
  removeUser,
  setAccessToken,
  setUserData,
} from "./auth/utils";
import uiText from "./context/translation.json";
import Login from "./views/login/Login";

import { Send } from "@mui/icons-material";
import {
  AiOutlineCheckSquare,
  AiOutlineCloudServer,
  AiOutlineCloudUpload,
  AiOutlineDisconnect,
} from "react-icons/ai";
import { BiMailSend } from "react-icons/bi";
import { VscSyncIgnored } from "react-icons/vsc";
import TotalView, { organization_id } from "./TotalView";

import { AiOutlineCloudDownload } from "react-icons/ai";
import { BsBorderAll, BsCloudMinus, BsCloudPlus } from "react-icons/bs";
import {
  MdAccountTree,
  MdCancelScheduleSend,
  MdOutlineFreeCancellation,
  MdOutlineImageNotSupported,
} from "react-icons/md";

import CloseRounded from "@mui/icons-material/CloseRounded";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import {
  triggerAADEMatchBackground,
  triggerAADESynchronizationBackground,
  triggerSynchronizationBackground,
  triggerUpdateErpBackground,
} from "./views/documents/documentAPI";

import { loginUser } from "./auth/authAPI";
import SideList from "./components/SideList/SideList";
import TopMenu from "./components/TopMenu/TopMenu";
// const useStylesSx = {
//   sideMenuLinksSx: {
//     // texDecoration: "none",
//     color: "white",
//     fontSize: "13px",
//     paddingLeft: "5px",
//     paddingRight: "10px",
//     whiteSpace: "nowrap",
//   },
//   listaSx: {
//     "& .MuiListItem-gutters": {
//       paddingLeft: 3,
//       paddingRight: 3,
//     },
//     "& .MuiListItem-root:hover": {
//       opacity: 0.5,
//     },
//   },
//   SideMenuSubheadersSx: {
//     backgroundColor: "#243e71",
//     color: "white",
//     display: "flex",
//     // fontSize: 24,
//     // gap:"15px",
//     // margin:"0px",
//     // height:"60px",
//   },
//   subHeaderTextSx: {
//     color: "white",
//     fontSize: 32,
//   },
//   subHeaderIconSx: {
//     fontSize: 36,
//     // backgroundColor: "#243e71",
//   },
//   sideMenuDrawerSx: {
//     backgroundColor: "#243e71",
//   },
// };

function App() {
  // const[credentials, setCredentials]=useState(getUserCredentials())
  const [uiLang, setUiLang] = useState(getUserLang());
  const [token, setToken] = useState(getAccessToken());
  const [isAzureAuth, setIsAzureAuth] = useState(getAzureAuth());

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorLanguage, setAnchorLanguage] = useState(null);
  const [appLocale, setAppLocale] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [countDown, setCountDown] = useState(10);
  const [startTimer, setStartTimer] = useState(false);
  const [disableRefreshErp, setDisableRefreshErp] = useState(false);
  // const [listItemsStates, setListItemState] = useState({
  //   Income: true,
  //   Expenses: true,
  // });
  const [globalSomethingLoading, setGlobalSomethingLoading] = useState(false);

  const drawerWidth = 180;
  const [showMenuLabels, setShowMenuLabels] = useState(false);
  let logoutTimeout = "";
  const interval = useRef();
  // const classesSx = useStylesSx();

  const incomeRoute = [
    {
      label: uiText.ui_income_all[uiLang],
      link: "/income/all",
      status: [],
    },
    {
      label: uiText.ui_income_report[uiLang],
      link: "/income/report",
      status: [],
    },
    {
      label: uiText.ui_income_deficiencies[uiLang],
      link: "/income/deficiencies",
      status: ["DEFICIENCIES", "TO_CORRECT", "IN_SEND_PROCESS"],
    },
    {
      label: uiText.ui_income_no_mark_yet_is_self_pricing[uiLang],
      link: "/income/no_mark_yet_is_self_pricing",
      status: ["PENDING_MARK"], // this doesn't matter
    },
    {
      label: uiText.ui_income_to_send[uiLang],
      link: "/income/to_send",
      status: ["TO_SEND", "TO_RESEND"],
    },
    {
      label: uiText.ui_income_to_cancel[uiLang],
      link: "/income/to_cancel",
      status: ["TO_CANCEL"],
    },
    {
      label: uiText.ui_income_sent[uiLang],
      link: "/income/sent",
      status: ["SENT"],
    },
    // {
    //   label: uiText.ui_income_to_correct[uiLang],
    //   link: "/income/to_correct",
    //   status: ["TO_CORRECT"],
    // },
    {
      label: uiText.ui_income_cancelled[uiLang],
      link: "/income/cancelled",
      status: ["IN_CANCEL_PROCESS", "CANCEL_ERROR", "CANCELLED"],
    },
    {
      label: uiText.ui_income_retrieved_by_aade[uiLang],
      link: "/income/retrieved_by_aade",
      status: ["RETRIEVED_BY_AADE"],
    },
    {
      label: uiText.ui_income_issuer_deviation[uiLang],
      link: "/income/issuer_deviation",
      status: ["ISSUER_DEVIATION"],
    },
    {
      label: uiText.ui_income_issuer_omitment[uiLang],
      link: "/income/issuer_omitment",
      status: ["ISSUER_OMITMENT"],
    },
    {
      label: uiText.ui_income_counterpart_rejection[uiLang],
      link: "/income/counterpart_rejection",
      status: ["COUNTERPART_REJECTION"],
    },
    {
      label: uiText.ui_income_counterpart_deviation[uiLang],
      link: "/income/counterpart_deviation",
      status: ["COUNTERPART_DEVIATION"],
    },
    {
      label: uiText.ui_income_ignored[uiLang],
      link: "/income/ignored",
      status: ["IGNORED"],
    },
  ];

  const expensesRoute = [
    {
      label: uiText.ui_expenses_all[uiLang],
      link: "/expenses/all",
      status: [],
    },
    {
      label: uiText.ui_expenses_report[uiLang],
      link: "/expenses/report",
      status: [],
    },
    {
      label: uiText.ui_expenses_deficiencies[uiLang],
      link: "/expenses/deficiencies",
      status: ["DEFICIENCIES", "TO_CORRECT", "IN_SEND_PROCESS"],
    },
    {
      label: uiText.ui_expenses_no_mark_yet[uiLang],
      link: "/expenses/no_mark_yet",
      status: ["PENDING_MARK"], // this doesn't matter
    },
    {
      label: uiText.ui_expenses_retrieved_by_aade[uiLang],
      link: "/expenses/retrieved_by_aade",
      status: ["RETRIEVED_BY_AADE"],
    },
    {
      label: uiText.ui_expenses_no_greek_supplier[uiLang],
      link: "/expenses/no_greek_supplier",
      status: ["NON_MIRRORED"],
    },
    {
      label: uiText.ui_expenses_to_send[uiLang],
      link: "/expenses/to_send",
      status: ["TO_SEND", "TO_RESEND"],
    },
    {
      label: uiText.ui_expenses_to_cancel[uiLang],
      link: "/expenses/to_cancel",
      status: ["TO_CANCEL"],
    },
    {
      label: uiText.ui_expenses_sent[uiLang],
      link: "/expenses/sent",
      status: ["SENT"],
    },
    // {
    //   label: uiText.ui_expenses_to_correct[uiLang],
    //   link: "/expenses/to_correct",
    //   status: ["TO_CORRECT"],
    // },
    {
      label: uiText.ui_expenses_cancelled[uiLang],
      link: "/expenses/cancelled",
      status: ["IN_CANCEL_PROCESS", "CANCEL_ERROR", "CANCELLED"],
    },
    {
      label: uiText.ui_expenses_ignored[uiLang],
      link: "/expenses/ignored",
      status: ["IGNORED"],
    },
  ];

  const othersRoute = [
    {
      label: uiText.ui_other_all[uiLang],
      link: "/other/all",
      status: [],
    },
  ];

  const settingsRoutes = [
    {
      label: uiText.GL_ACCOUNTS[uiLang],
      link: "/settings/gl_accounts",
      status: ["GLAccounts"],
    },
  ];

  const menuIcon = (route) => {
    if (route.link.includes("all")) {
      return (
        <BsBorderAll style={{ color: "white", fontSize: 20, marginLeft: 15 }} />
      );
    }
    if (route.link.includes("report")) {
      return (
        <AiOutlineCheckSquare
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    // if (route.link.includes("to_correct")) {
    //   return (
    //     <RiFileEditLine
    //       style={{ color: "white", fontSize: 20, marginLeft: 15 }}
    //     />
    //   );
    // }
    if (route.link.includes("deficiencies")) {
      return (
        <FaExclamationTriangle
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("sent")) {
      return <Send style={{ color: "white", fontSize: 20, marginLeft: 15 }} />;
    }
    if (route.link.includes("to_send")) {
      return (
        <BiMailSend style={{ color: "white", fontSize: 20, marginLeft: 15 }} />
      );
    }
    if (route.link.includes("to_cancel")) {
      return (
        <MdOutlineFreeCancellation
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("no_mark_yet")) {
      return (
        <AiOutlineDisconnect
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("cancelled")) {
      return (
        <MdCancelScheduleSend
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("ignored")) {
      return (
        <VscSyncIgnored
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("no_greek_supplier")) {
      return (
        <MdOutlineImageNotSupported
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("retrieved_by_aade")) {
      return (
        <AiOutlineCloudDownload
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("issuer_deviation")) {
      return (
        <AiOutlineCloudServer
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("issuer_omitment")) {
      return (
        <AiOutlineCloudUpload
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("counterpart_rejection")) {
      return (
        <BsCloudMinus
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    if (route.link.includes("counterpart_deviation")) {
      return (
        <BsCloudPlus style={{ color: "white", fontSize: 20, marginLeft: 15 }} />
      );
    }
    if (route.link.includes("gl_accounts")) {
      return (
        <MdAccountTree
          style={{ color: "white", fontSize: 20, marginLeft: 15 }}
        />
      );
    }
    // console.log(route)
  };
  const [NotificationState, setNotificationState] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  useEffect(() => {
    let timerLeft = 90;

    if (startTimer) {
      // console.log(startTimer);
      interval.current = setInterval(() => {
        // console.log("This will run every second!");
        if (timerLeft === 0) {
          clearInterval(interval.current);
          inactiveLogout();
        }
        setCountDown(timerLeft);
        timerLeft--;
        // console.log(timerLeft);
      }, 1000);
    }
    return () => clearInterval(interval.current);
  }, [startTimer]);

  useEffect(() => {
    // console.log("isActive changed");
    // clearTimeout(logoutTimeout);

    logoutTimeout = setTimeout(() => {
      setStartTimer(true);
      setCountDown(90);
      // console.log("inactivityLogout");
      setOpenLogoutDialog(true);
    }, 1800000);

    return () => clearTimeout(logoutTimeout);
  }, [isActive]);
  const inactiveLogout = () => {
    clearInterval(interval.current);
    setOpenLogoutDialog(false);
    handleLogout();
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onCachedIcon = (event) => {
    console.log(organization_id);
    setNotificationMessage(uiText.ui_erp_synchronization_start[uiLang]);
    setNotificationState(true);
    setTimeout(() => setNotificationState(false), 4000);
    triggerSynchronizationBackground(organization_id)
      .then((response) => {
        // setNotificationMessage(uiText.ui_erp_synchronization_end[uiLang]);
        // setNotificationState(true);
        // setTimeout(() => setNotificationState(false), 5000);
        // setTimeout(function () {
        //   window.location.reload();
        // }, 5000);
        // console.log(response)
      })
      .catch(() => {
        setNotificationMessage("Error!");
        setNotificationState(true);
        setDisableRefreshErp(false);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };
  const onAiOutlineCloudDownload = (event) => {
    setNotificationMessage(uiText.ui_aade_synchronization_start[uiLang]);
    setNotificationState(true);
    setTimeout(() => setNotificationState(false), 4000);
    triggerAADESynchronizationBackground(organization_id)
      .then((response) => {
        // setNotificationMessage(uiText.ui_aade_synchronization_end[uiLang]);
        // setNotificationState(true);
        // setTimeout(() => setNotificationState(false), 5000);
        // setTimeout(function () {
        //   window.location.reload();
        // }, 5000);
        // console.log(response)
      })
      .catch(() => {
        setNotificationMessage("Error!");
        setNotificationState(true);
        setDisableRefreshErp(false);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };
  const onVscLink = (event) => {
    setNotificationMessage(uiText.ui_aade_match_start[uiLang]);
    setNotificationState(true);
    setTimeout(() => setNotificationState(false), 4000);
    triggerAADEMatchBackground(organization_id)
      .then((response) => {
        // setNotificationMessage(uiText.ui_aade_match_end[uiLang]);
        // setNotificationState(true);
        // setTimeout(() => setNotificationState(false), 5000);
        // setTimeout(function () {
        //   window.location.reload();
        // }, 5000);
        // console.log(response)
      })
      .catch(() => {
        setNotificationMessage("Error!");
        setNotificationState(true);
        setDisableRefreshErp(false);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };
  const onWebhookOutlinedIcon = (event) => {
    setNotificationMessage(uiText.ui_update_erp_start[uiLang]);
    setNotificationState(true);
    setTimeout(() => setNotificationState(false), 4000);
    triggerUpdateErpBackground(organization_id)
      .then((response) => {
        // console.log(response)
      })
      .catch(() => {
        setNotificationMessage("Error!");
        setNotificationState(true);
        setDisableRefreshErp(false);
        setTimeout(() => setNotificationState(false), 3000);
      });
  };
  const handleLanguage = (lang) => {
    setAnchorLanguage(null);
    setUiLang(lang);
    if (lang === "en") setAppLocale("en-US");
    if (lang === "el") setAppLocale("el-GR");
  };

  const openLanguage = (event) => {
    // console.log(event);
    setAnchorLanguage(event.currentTarget);
  };
  const handleCloseLanguage = () => {
    setAnchorLanguage(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    removeUser();
    removeAccessToken();
    removeAzureAuth();
    removeCredentials();
    setToken(null);
    window.location.href = "/login";
  };

  if (!token && !isAzureAuth) {
    return <Login uiLang={uiLang} setUiLang={setUiLang} setToken={setToken} />;
  }
  const toggleDrawer = (event) => {
    setShowMenuLabels(!showMenuLabels);
  };

  // const toggleDrawer = (open) => (event) => {
  //   if (
  //     event.type === 'keydown' &&
  //     (event.key === 'Tab' || event.key === 'Shift')
  //   ) {
  //     return;
  //   }
  //   setState(open);
  // };
  const handleCloseLogoutDialog = (value) => {
    setOpenLogoutDialog(false);
    // console.log(value);
    if (!value) {
      const creds = getUserCredentials();

      clearInterval(interval.current);
      const credentials = new URLSearchParams();
      credentials.append("username", creds.username);
      credentials.append("password", creds.password);
      credentials.append("grant_type", "password");

      setStartTimer(false);
      loginUser(credentials)
        .then((response) => {
          // console.log(response.data.user);

          const user = {
            ...response.data.user,
            lang: uiLang,
          };
          user[`organization_groups`] = response.data.organization_groups;
          setAccessToken(response.data.access_token);
          setIsActive(!isActive);
          setUserData(user);
          setToken(response.data.access_token);

          // history.push("/income/all");
          // window.location.href = "/income";
        })
        .catch((error) => {
          // setShowError(true);
          // console.log(error);
        });
    } else {
      inactiveLogout();
      //  setStartTimer(false);
    }
  };

  return (
    <div id={styles.appContainer}>
      <Router>
        <Dialog
          onClose={() => handleCloseLogoutDialog(true)}
          open={openLogoutDialog}
          maxWidth={"xs"}
          fullWidth
        >
          <IconButton
            aria-label="close"
            onClick={() => handleCloseLogoutDialog(true)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseRounded />
          </IconButton>
          <DialogTitle> {uiText.inactivityDialogTitle[uiLang]}</DialogTitle>
          <Box
            container
            style={{
              justifyContent: "center",
              alignContent: "center",
              padding: "30px",
              height: 120,
            }}
          >
            <Typography>
              {" "}
              {uiText.logoutTimerText[uiLang]}
              {countDown} {uiText.logoutTimerUnit[uiLang]}
            </Typography>
          </Box>
          <DialogActions>
            {/* <Grid
              item
              style={{
                justifyContent: "space-between",
                // alignContent: "center",
                // gap: "10px",
              }}
            > */}
            <Button onClick={() => handleCloseLogoutDialog(false)}>
              {uiText.login_stay_connected[uiLang]}
            </Button>
            <Button onClick={() => handleCloseLogoutDialog(true)}>
              {uiText.login_leave[uiLang]}
            </Button>
            {/* </Grid> */}
          </DialogActions>
        </Dialog>
        <Box sx={{ display: "flex" }}>
          <TopMenu
            toggleDrawer={toggleDrawer}
            handleClose={handleClose}
            handleLogout={handleLogout}
            uiText={uiText}
            anchorEl={anchorEl}
            uiLang={uiLang}
            onVscLink={onVscLink}
            onWebhookOutlinedIcon={onWebhookOutlinedIcon}
            handleMenu={handleMenu}
            handleLanguage={handleLanguage}
            onAiOutlineCloudDownload={onAiOutlineCloudDownload}
            anchorLanguage={anchorLanguage}
            handleCloseLanguage={handleCloseLanguage}
            openLanguage={openLanguage}
            onCachedIcon={onCachedIcon}
            token={token}
          />
          <Toolbar />
          <SideList
            // classesSx={useStylesSx}
            uiText={uiText}
            uiLang={uiLang}
            menuIcon={menuIcon}
            showMenuLabels={showMenuLabels}
            incomeRoute={incomeRoute}
            expensesRoute={expensesRoute}
            othersRoute={othersRoute}
            settingsRoutes={settingsRoutes}
            globalSomethingLoading={globalSomethingLoading}
          />
        </Box>
        <Box
          component="main"
          style={{
            position: "relative",
            marginLeft: showMenuLabels ? drawerWidth : 0,
            marginTop: "10px",
          }}
          sx={{ flexGrow: 1, p: 3 }}
        >
          <TotalView
            appLocale={appLocale}
            isActive={isActive}
            setIsActive={setIsActive}
            handleLogout={handleLogout}
            uiLang={uiLang}
            othersRoute={othersRoute}
            expensesRoute={expensesRoute}
            incomeRoute={incomeRoute}
            showMenuLabels={showMenuLabels}
            drawerWidth={drawerWidth}
            globalSomethingLoading={globalSomethingLoading}
            setGlobalSomethingLoading={setGlobalSomethingLoading}
          />
        </Box>
      </Router>
      <Snackbar
        open={NotificationState}
        autoHideDuration={3600}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotificationState(false);
          }}
          variant="filled"
          severity={
            notificationMessage.includes("error") ? "warning" : "success"
          }
          sx={{
            width: "100%",
            fontSize: 20,
            fontFamily: "monospace",
          }}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
